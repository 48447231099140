import React, { useEffect, useState } from "react";
import "./CostReport.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { checkID, doToast, rupiahFormat } from "../../../helper/HelperGeneral";
import HelperDate from "../../../helper/HelperDate";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../reuseableComponent/customSelectOption/CustomSelectOption";
import Tooltip from "../../reuseableComponent/Tooltip/Tooltip";
import Pagination from "../../reuseableComponent/pagination/Pagination";
import DateRangeButton from "../../reuseableComponent/dateRangeButton/DateRangeButton";
import CalendarPicker from "../../reuseableComponent/calendarPicker/CalendarPicker";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ServiceReport from "../../../services/report/ServiceReport";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import IconDownload from "../../../assets/img/icon-download.svg";
import IconDownloadGray from "../../../assets/img/icon-download-gray.svg";

const CostReport = (props) => {
  const { match, history, location } = props;
  // const [costReportsAll, setCostReportsAll] = useState([
  //   {
  //     date: "2021-01-21",
  //     totalMessagesAll: 30,
  //     totalMessagesSent: 25,
  //     totalMessagesFailed: 5,
  //     totalChargeable: 5,
  //     currency: "IDR",
  //     totalCost: 10000,
  //   },
  //   {
  //     date: "2021-01-22",
  //     totalMessagesAll: 45,
  //     totalMessagesSent: 35,
  //     totalMessagesFailed: 6,
  //     totalChargeable: 30,
  //     currency: "IDR",
  //     totalCost: 60000,
  //   },
  // ]);
  const [costReports, setCostReports] = useState([]);
  const [listPageVal, setListPageVal] = useState({
    label: "20",
    value: 20,
  });
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isFilterDateActive, setIsFilterDateActive] = useState(false);
  const [pageDisplay, setPageDisplay] = useState(1);
  const [listPage, setListPage] = useState(20);
  const [calendarPickerVisible, setCalendarPickerVisible] = useState(false);
  const [calendarOneFrom, setCalendarOneFrom] = useState();
  const [calendarOneTo, setCalendarOneTo] = useState();
  let [filterDateBefore, setFilterDateBefore] = useState({
    from: undefined,
    to: undefined,
  });
  const [getDataLoading, setGetDataLoading] = useState(false);
  const [orgID, setOrgID] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const listPageOption = [
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
    {
      label: "100",
      value: 100,
    },
  ];

  const labelWebPlatform = (
    <span className="question-circle-wrapper">
      <AiOutlineQuestionCircle />
    </span>
  );

  const onSelectListPage = (val) => {
    let data = {
      pageDisplay: 1,
      listPage: val.value,
      calendarOneFrom,
      calendarOneTo,
    };
    getCostReports(data);
    setListPageVal(val);
    setListPage(val.value);
    setPageDisplay(1);
  };

  const handleChangePage = (page) => {
    let data = {
      pageDisplay: page,
      listPage,
      calendarOneFrom,
      calendarOneTo,
    };
    getCostReports(data);
    setPageDisplay(page);
  };

  const onClearDatePicker = () => {
    let data = {
      pageDisplay,
      listPage,
      calendarOneFrom: "",
      calendarOneTo: "",
    };
    setIsFilterDateActive(false);
    getCostReports(data);
    setCalendarPickerVisible(false);
  };

  const applyDateFilter = () => {
    setPageDisplay(1);
    setFilterDateBefore({ from: calendarOneFrom, to: calendarOneTo });
    let data = {
      pageDisplay: 1,
      listPage,
      calendarOneFrom,
      calendarOneTo,
    };
    setIsFilterDateActive(true);
    getCostReports(data);
    setCalendarPickerVisible(false);
  };

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/home`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  const getFormattedStartAndEndDate = (firstDate, secondDate) => {
    let startDate = "";
    let endDate = "";
    if (firstDate)
      startDate = HelperDate.formatToString(new Date(firstDate), "yyyy-MM-dd");
    else if(secondDate)
      startDate = HelperDate.formatToString(new Date(secondDate), "yyyy-MM-dd");

    if (secondDate) {
      endDate = HelperDate.formatToString(new Date(secondDate), "yyyy-MM-dd");
    }
    return { startDate: startDate, endDate: endDate };
  };

  const downloadReport = (data, startDate, endDate) => {
    const id = orgID.split("-");
    const timestamp = +new Date();
    let filename = "";
    if (startDate && endDate) {
      filename = `Cost_Report-${id[0]}-${startDate}_${endDate}-${timestamp}.csv`;
    } else if (startDate) {
      filename = `Cost_Report-${id[0]}-${startDate}-${timestamp}.csv`;
    } else if (endDate) {
      filename = `Cost_Report-${id[0]}-${endDate}-${timestamp}.csv`;
    } else {
      filename = `Cost_Report-${id[0]}-${timestamp}.csv`;
    }

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloadingReport(false);
  };

  const exportCostReports = () => {
    setIsDownloadingReport(true);
    let formattedDate = getFormattedStartAndEndDate(
      calendarOneFrom,
      calendarOneTo
    );
    let data = {
      startDate: formattedDate.startDate,
      endDate: formattedDate.endDate,
      format: "csv",
    };
    ServiceReport.postExportCostReport(orgID, data, (response) => {
      if (!response) {
        setIsDownloadingReport(false);
        doToast("Failed to export the report", "fail");
      } else {
        downloadReport(
          response.dataResult,
          formattedDate.startDate,
          formattedDate.endDate
        );
      }
    });
  };

  const getCostReports = async (data) => {
    setGetDataLoading(true);
    let _retrieveDataError = { ...retrieveDataError };
    let formattedDate = getFormattedStartAndEndDate(
      data.calendarOneFrom,
      data.calendarOneTo
    );

    ServiceReport.postGetCostReport(
      orgID,
      formattedDate.startDate,
      formattedDate.endDate,
      data.pageDisplay,
      data.listPage,
      (response) => {
        let dataResult = response.dataResult;
        if (dataResult.error.message === "") {
          let responseData = dataResult.data;
          setCostReports(responseData.items);
        } else {
          console.log("GET COST REPORT HAS BEEN FAILED");
          _retrieveDataError.message = dataResult.error.message;
          _retrieveDataError.code = dataResult.error.code;
          setRetrieveDataError(_retrieveDataError);
        }

        setGetDataLoading(false);
      }
    );
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if (orgID)
      getCostReports({
        pageDisplay,
        listPage,
        calendarOneFrom,
        calendarOneTo,
      });
  }, [orgID]);

  return (
    <React.Fragment>
      <SectionWrap {...props} withHeader withSideMenu>
        <div className="sectionWrap cost-report-main-wrapper">
          <div className="cost-report-header">
            <div>
              <div className="costReport ">
                <div className="cost-report-header-title">
                  Daily Cost Report
                </div>
              </div>
              <div className="cost-report-header-description">
                View your messages daily cost report.
              </div>
            </div>
            {isDownloadingReport ? (
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                position="left"
                text="Download Cost Report"
                loadingColor="gray"
                isLoading
                isDisabled
              />
            ) : (
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                icon={costReports.length === 0 ? {
                  type: "img",
                  src: IconDownloadGray,
                } : {
                  type: "img",
                  src: IconDownload,
                }}
                position="left"
                text="Download Cost Report"
                isDisabled={costReports.length === 0}
                onClickAction={exportCostReports}
              />
            )}
          </div>

          <DateRangeButton
            onClick={() => setCalendarPickerVisible(true)}
            isFilterDateActive={isFilterDateActive}
            filterDate={{ from: calendarOneFrom, to: calendarOneTo }}
          />
          <CalendarPicker
            isOpen={calendarPickerVisible}
            filterDate={{ from: calendarOneFrom, to: calendarOneTo }}
            filterDateBefore={filterDateBefore}
            toggle={() => setCalendarPickerVisible(!calendarPickerVisible)}
            handleDateFilterClick={(range) => {
              setCalendarOneFrom(range.from);
              setCalendarOneTo(range.to);
            }}
            onClearSelection={onClearDatePicker}
            submitDateFilter={applyDateFilter}
            applyDateLoading={getDataLoading}
            isDisabled={getDataLoading || !calendarOneFrom || !calendarOneTo}
          />
          {getDataLoading || retrieveDataError.code ? (
            <RetrieveDataLoading
              isLoading={getDataLoading}
              errorMessage={retrieveDataError.message}
            />
          ) : (
            <div className="cost-report-table-wrapper">
              <div className="custom-table">
                <table className="custom-table-content table-cost-report-list">
                  <thead>
                    <tr>
                      <td className="col_td_left">Date</td>
                      <td className="col_td_center">Total</td>
                      <td className="col_td_center">
                        Sent
                        <Tooltip
                          content="Sent to WhatsApp Server"
                          children={labelWebPlatform}
                          interactive
                        />
                      </td>
                      <td className="col_td_center">
                        Failed
                        <Tooltip
                          content="e.g invalid phone number"
                          children={labelWebPlatform}
                          interactive
                        />
                      </td>
                      <td className="col_td_center">
                        Total Chargeable
                        <Tooltip
                          content="Total Sent"
                          children={labelWebPlatform}
                          interactive
                        />
                      </td>
                      <td className="col_td_left">Cost</td>
                      <td>
                        <CustomSelectOption
                          optionListProps={listPageOption}
                          valueDropdownProps={listPageVal}
                          onClickDropDownListOptionProps={onSelectListPage}
                        />
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {costReports.length > 0 ? (
                      costReports.map((item, idx) => {
                        return (
                          <tr key={`cost-${idx}`}>
                            <td className="">
                              <p className="">
                                {HelperDate.formatToString(
                                  new Date(item.date),
                                  "dd MMM yyyy"
                                )}
                              </p>
                            </td>
                            <td className="">
                              <p>{item.totalMessagesAll}</p>
                            </td>
                            <td className="">
                              <p>{item.totalMessagesSent}</p>
                            </td>
                            <td className="">
                              <p>{item.totalMessagesFailed}</p>
                            </td>
                            <td className="">
                              <p>{item.totalChargeable}</p>
                            </td>
                            <td className="">
                              <p>{rupiahFormat(item.totalCost)}</p>
                            </td>
                            <td></td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="no-data-row">
                        <td
                          colSpan="8"
                          className="no-data-column"
                          style={{ textAlign: "center" }}
                        >
                          <b className="no-result-found">
                            No message history found.
                          </b>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="pagination-wrapper">
            {costReports.length > 0 && (
              <Pagination
                total={costReports.length}
                page={pageDisplay}
                listPerPage={listPage}
                step={1}
                onChangePage={handleChangePage}
              ></Pagination>
            )}
          </div>
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default CostReport;
