import React from 'react';
import './PopupConfirmation.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ButtonWithLoadingOrIcon from '../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';

let PopupConfirmation = (props) => {
  let {
    isOpen,
    toggle,
    className,
    title,
    titleImage,
    description,
    submitText,
    children,
    submitAction,
    isLoading
  } = props;

  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     waitForResponseConfirmation: false,
  //   };
  // }

  // toggleModalPopupConfirmation = () => {
  //   this.props.onClickCancel();
  // };

  // onClickConfirmation = () => {
  //   this.props.onClickConfirmation();
  // };

  // componentWillReceiveProps(newProps) {
  //   const oldProps = this.props;
  //   if (oldProps.waitForResponse !== newProps.waitForResponse) {
  //     this.setState({
  //       waitForResponseConfirmation: newProps.waitForResponse,
  //     });
  //   }
  // }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={`modal-conformation ${className}`}
      >
        <ModalBody>
          <b className="confirmation-title">{titleImage ? titleImage : ""} {title}</b>
          <p className="confirmation-description">{description}</p>

          {children}
        </ModalBody>

        <ModalFooter>
          <ButtonWithLoadingOrIcon 
            text="Cancel"
            className="no-fill-button cancel-button main-button-48"
            onClickAction={toggle}
          />

          {isLoading ?
            <ButtonWithLoadingOrIcon
              isLoading
              loadingWhite
              position="left"
              text={submitText}
              className="orange-button confirmation-button main-button-48"
            />
            :
            <ButtonWithLoadingOrIcon 
              text={submitText}
              className="orange-button confirmation-button main-button-48"
              onClickAction={submitAction}
            />
          }
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default PopupConfirmation;
