var windowSearchParam = new URLSearchParams(window.location.href.split("?")[1]);

const GTAGPARAM = {
    utm_source: "utm_source",
    utm_medium: "utm_medium",
    utm_campaign: "utm_campaign",
    utm_term: "utm_term",
    utm_content: "utm_content",
}

var HelperGTag = {
    getTaptalkAnalytic: () => {
        return JSON.parse(localStorage.getItem("TapTalk.analytic"));
    },
    getSpecificAnalytic: (key) => {
        let analyticValue = HelperGTag.getTaptalkAnalytic();
        let value = null;

        if(analyticValue !== null) {
            if(analyticValue[key]) {
                value = analyticValue[key]
            }
        }

        return value;
    },
    generateQueryStringAnalytic: () => {
        let param = HelperGTag.getTaptalkAnalytic();
        let queryString = "";
        
        Object.keys(param).map((key, index) => {
            queryString += `${key}=${param[key]}${index < (Object.keys(param).length - 1) ? "&" : ""}`;
            return null;
        });

        return queryString;
    },
    basicParameter: (page) => {
        return {
            page: page,
            utm_source: HelperGTag.getSpecificAnalytic("utm_source") !== null ? HelperGTag.getSpecificAnalytic("utm_source") : "organic" ,
            utm_medium: HelperGTag.getSpecificAnalytic("utm_medium") !== null ? HelperGTag.getSpecificAnalytic("utm_medium") : "organic",
            utm_campaign: HelperGTag.getSpecificAnalytic("utm_campaign") !== null ? HelperGTag.getSpecificAnalytic("utm_campaign") : null,
            utm_content: HelperGTag.getSpecificAnalytic("utm_content") !== null ? HelperGTag.getSpecificAnalytic("utm_content") : null
        }
    },
    saveAnalyticParam: (key, value) => {
        let currentAnalytic = HelperGTag.getTaptalkAnalytic();

        let setTapTalkAnalyticValue = () => {
            currentAnalytic[key] = value;
            localStorage.setItem("TapTalk.analytic", JSON.stringify(currentAnalytic));
        }

        if(currentAnalytic === null) {
            currentAnalytic = {};
            setTapTalkAnalyticValue();
        }else {
            currentAnalytic[key] = value;
            setTapTalkAnalyticValue();
        }
    },
    checkIfUrlContainAnalyticParam: (callback) => {
        let paramObj = {};

        for(var key of windowSearchParam.keys()) {
            if(GTAGPARAM[key]) {
                HelperGTag.saveAnalyticParam(key, windowSearchParam.get(key));
                paramObj[key] = windowSearchParam.get(key);
            }
        }

        if(Object.keys(paramObj).length > 0) {
            callback.setAnalyticQueryString(windowSearchParam);
        }else {
            if(HelperGTag.getTaptalkAnalytic() !== null) {
                callback.ifAnalyticExistInLocalStorage();
            }
        }
    },
    dataLayerPush: (data) => {
        window.dataLayer.push(data);
    }
}

export default HelperGTag;