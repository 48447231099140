import React, { useState, useEffect } from 'react';
import './Login.scss';
import logoTaptalk from '../../../assets/img/auth/logo-taptalk.svg'
import { Link, useHistory } from 'react-router-dom'
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiEye, FiEyeOff, FiAlertCircle } from 'react-icons/fi'
import { FaCheckCircle } from 'react-icons/fa'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ServiceAuthAccessToken from "../../../services/auth/ServiceAuthAccessToken";
import ServiceAuthRegister from '../../../services/auth/ServiceAuthRegister';
import HelperGTag from "../../../helper/HelperGTag";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperQueryString from "../../../helper/HelperQueryString";
import { setAuthActive } from "../../../redux/actions/reduxActionAuth";
import { connect } from "react-redux";
import { doToast } from '../../../helper/HelperGeneral';

const Login = (props) => {
  const [peekPassword, setPeekPassword] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isDisabled, setIsDisabled] = useState(true)
  const [isShowModalLogin, setIsShowModalLogin] = useState(false)
  const [isResendVerificationEmail, setIsResendVerificationEmail] = useState(false)
  const [isWaitForResponseLogin, setIsWaitForResponseLogin] = useState(false)
  const [failStateActive, setFailStateActive] = useState(false)
  const [failStateMessage, setFailStateMessage] = useState("")
  const [isWaitResendVerifEmail, setIsWaitResendVerifEmail] = useState("")
  const history = useHistory()

  const peekPasswordClick = () => {
    setPeekPassword(!peekPassword)
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const onChangePassword = (e) => {
    setPassword(e.target.value)
  }

  useEffect(() => {
    if (email === "" || password === "") {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [email, password])

  // useEffect(() => {
    // document.getElementById("login-background-container").style.height = window.innerHeight
    // document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
  // }, [])

  const onSubmitLogin = (e) => {
    e.preventDefault()

    setIsWaitForResponseLogin(true)
    setFailStateActive(false)

    let param = `${email}:${password}`;

    ServiceAuthAccessToken.postRequestAccessToken(param).then(response => {
      if (response.dataResult.status !== 200) {
        setIsWaitForResponseLogin(false)

        if (response.dataResult.error.code === "40107") {
          setIsShowModalLogin(true)

        } else {
          setFailStateActive(true)
          setFailStateMessage(response.dataResult.error.message)

          HelperGTag.dataLayerPush(
            Object.assign({ ...HelperGTag.basicParameter("SendTalk login") }, {
              event: "action-login-failed",
              error_message: response.dataResult.error.message
            })
          )
        }
      } else {
        let _dataResult = { ...response.dataResult.data };
        // _dataResult.activeOrganization = null;
        HelperGTag.dataLayerPush(
          Object.assign({ ...HelperGTag.basicParameter("SendTalk login") }, {
            event: "action-login-success",
          })
        )

        let runSetAuthActive = () => {
          props.setAuthActive({
            data: HelperCustomEncryptor.doEncrypt(_dataResult)
          });
        }

        if (response.dataResult.data.memberships.length === 0) {
          runSetAuthActive();

          setTimeout(function () {
            window.location.href = window.location.origin + "/organization/new";
          }, 2500);
        } else {
          // let organizationData = response.dataResult.data.memberships;

          // _dataResult.activeOrganization = organizationData[0].organization;

          runSetAuthActive();

          let qs = HelperQueryString.parseQuerystring();

          let valRedirect = qs["redirect"];

          if (valRedirect) {
            window.location.href = window.location.origin + valRedirect;  
          }else {
            window.location.href = window.location.origin + `/home`;
          }
        }
      }
    })
  }

  const resendVerificationEmail = () => {
    setIsWaitResendVerifEmail(true)

    ServiceAuthRegister.postResendEmailVerification({ email: email }).then(response => {
      setIsWaitResendVerifEmail(false)

      if (response.dataResult.data.success) {
        setIsResendVerificationEmail(true)
      } else {
        doToast(response.dataResult.error.message, 'fail')
      }
    })
  }

  const modalLogin = () => {
    return (
      <Modal isOpen={isShowModalLogin} className="modal-login">
        {
          !isResendVerificationEmail ?
            <>
              <ModalBody>
                <p className="title not-verified">
                  <FiAlertCircle />
                  Account Not Verified
                </p>
                <p>
                  The email address you have entered <b>has not been verified</b>. If you didn’t receive any email verification please click the button below to resend the verification email.
                </p>
              </ModalBody>
              <ModalFooter>
                {
                  isWaitResendVerifEmail ?
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingWhite
                      className="orange-button main-button-48 width-100"
                      text="Resend Verification Email"
                      position="left"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                      className="orange-button main-button-48 width-100"
                      text="Resend Verification Email"
                      onClickAction={resendVerificationEmail}
                    />
                }
              </ModalFooter>
            </>
            :
            <>
              <ModalBody>
                <p className="title">
                  <FaCheckCircle />
                  Verification Email Sent
                </p>
                <p>
                  Please check your inbox with the registered email address and <b>verify your account</b>.
                </p>
              </ModalBody>
              <ModalFooter>
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-48 width-100"
                  text="Got it, thanks!"
                  onClickAction={() => history.push('/')}
                />
              </ModalFooter>
            </>
        }
      </Modal>
    )
  }

  return (
    <div className="outer-container">
      <div className="background-container" id="login-background-container">
        {modalLogin()}
        <div className="header-container">
          <img src={logoTaptalk} alt="TapTalk.io" />
          <p>SENDTALK</p>
        </div>
        <div className="form-container">
          <p className="form-title">Welcome Back!</p>
          {failStateActive &&
            <div className="fail-panel">
              <div className="icon-wrapper">
                <FiAlertCircle />
              </div>
              <b>{failStateMessage}</b>
            </div>
          }
          <form onSubmit={onSubmitLogin}>
            <div className="input-wrapper">
              <label><b>Email</b></label>
              <input type="text"
                id="email"
                placeholder="your@email.com"
                autoComplete="false"
                tabIndex="1"
                onChange={onChangeEmail}
                value={email}
              />
            </div>
            <div className="input-wrapper">
              <div className="password-title-wrapper">
                <label><b>Password</b></label>
                <Link to='/forgot-password'><label><b className='forgot-password'>Forgot password?</b></label></Link>
              </div>
              <div className="popup-input-password-wrapper">
                <input type={peekPassword ? "text" : "password"}
                  id="password"
                  onChange={onChangePassword}
                  placeholder="Enter your password"
                  autoComplete="false"
                  tabIndex="2"
                  value={password}
                // className={this.state.failStateActive ? 'fail-state-input' : ''}
                />
                <div className={`peek-password ${peekPassword ? 'active-eye' : ''}`}
                  onClick={() => peekPasswordClick()}
                >
                  {peekPassword ?
                    <FiEye />
                    :
                    <FiEyeOff />
                  }
                </div>
              </div>
            </div>
            {
              isWaitForResponseLogin ?
                <ButtonWithLoadingOrIcon
                  isLoading
                  loadingWhite
                  className="orange-button main-button-48 width-100"
                  text="Login"
                  position="left"
                  // isDisabled={true}
                />
                :
                <ButtonWithLoadingOrIcon
                  // gtmevent={JSON.stringify(Object.assign({...HelperGTag.basicParameter("SendTalk login")} ,{
                  //   event: "action-login-button-click",
                  //   source: "login"
                  // }))}
                  className="orange-button main-button-48 width-100"
                  text="Login"
                  isDisabled={isDisabled}
                />
            }
          </form>
          <div className="signup-here">
            <b>Don't have an account?
              <Link to={"/register"}
                className="signup-link"
              // gtmevent={JSON.stringify(Object.assign({...HelperGTag.basicParameter("SendTalk login")} ,{
              //   event: "button-click-register",
              //   source: "login"
              // }))}
              >
                Create an Account
              </Link>
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  setAuthActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)