import React, { useState, useEffect } from 'react';
import './Register.scss';
import logoTaptalk from '../../../assets/img/auth/logo-taptalk.svg'
import { Link, useHistory } from 'react-router-dom'
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiEye, FiEyeOff, FiCheck, FiAlertCircle } from 'react-icons/fi'
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ServiceAuthRegister from '../../../services/auth/ServiceAuthRegister';
import { doToast } from "../../../helper/HelperGeneral";
import HelperGTag from "../../../helper/HelperGTag";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const [peekPassword, setPeekPassword] = useState(false)
  const [peekRePassword, setPeekRePassword] = useState(false)
  const [fullname, setFullname] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [rePassword, setRePassword] = useState("")
  const [checkedBox, setCheckedBox] = useState(false)
  const [lowerCase, setLowerCase] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [number, setNumber] = useState(false)
  const [specialCharacter, setSpecialCharacter] = useState(false)
  const [minimumCharacter, setMinimumCharacter] = useState(false)
  // const [failStateActive, setFailStateActive] = useState(false)
  // const [failStateMessage, setFailStateMessage] = useState("")
  const [isShowPasswordNotMatch, setIsShowPasswordNotMatch] = useState(true)
  const [isShowModalRegister, setIsShowModalRegister] = useState(false)
  const [isWaitForResponseRegister, setIsWaitForResponseRegister] = useState(false)
  const history = useHistory()

  const onCheckedBox = (e) => {
    setCheckedBox(e.target.checked)
  }

  const peekPasswordClick = () => {
    setPeekPassword(!peekPassword)
  }

  const peekRePasswordClick = () => {
    if (isDisabledRePassword()) return;
    setPeekRePassword(!peekRePassword)
  }

  const onChangeFullname = (e) => {
    setFullname(e.target.value)
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const onChangeRePassword = (e) => {
    setRePassword(e.target.value)
  }

  const onSubmitRegister = (e) => {
    e.preventDefault()

    setIsWaitForResponseRegister(true)

    const form = {
      fullname: fullname,
      email: email,
      password: password,
      isTOSAccepted: checkedBox,
    }

    ServiceAuthRegister.postRegisterAccount(form).then(response => {
      setIsWaitForResponseRegister(false)

      if (response.dataResult.data.success) {
        HelperGTag.dataLayerPush(
          Object.assign({ ...HelperGTag.basicParameter("SendTalk register") }, {
            event: "action-register-success",
            email: email
          })
        )

        setIsShowModalRegister(true)
      } else {
        HelperGTag.dataLayerPush(
          Object.assign({ ...HelperGTag.basicParameter("SendTalk register") }, {
            event: "action-register-failed",
            error_message: response.dataResult.error.message
          })
        )

        // setFailStateActive(true)
        // setFailStateMessage(response.dataResult.error.message)
        doToast(response.dataResult.error.message, "fail")
      }
    })
  }

  const passwordStrengthCheck = () => {
    if (lowerCase && upperCase && number && specialCharacter && minimumCharacter && password === rePassword) {
      return true;
    } else {
      return false;
    }
  }

  const validateForm = () => {
    return fullname !== "" && email !== "" && passwordStrengthCheck() && checkedBox
  }

  const handlePassword = event => {
    let value = event.target.value;
    // this.setState({ password:  value, failStateActive: false });
    setPassword(value)

    //lowercase
    if ((/[a-z]/.test(value))) {
      setLowerCase(true)
    } else {
      setLowerCase(false)
    }

    //uppercase
    if ((/[A-Z]/.test(value))) {
      setUpperCase(true)
    } else {
      setUpperCase(false)
    }

    //number
    if ((/[0-9]/.test(value))) {
      setNumber(true)
    } else {
      setNumber(false)
    }

    //special character
    if (!(/^[a-zA-Z0-9]*$/).test(value)) {
      setSpecialCharacter(true)
    } else {
      setSpecialCharacter(false)
    }

    //8 minimum character
    if (value.length > 7) {
      setMinimumCharacter(true)
    } else {
      setMinimumCharacter(false)
    }
  }

  const isDisabledRePassword = () => {
    if (lowerCase && upperCase && number && specialCharacter && minimumCharacter) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (rePassword === "") {
      setIsShowPasswordNotMatch(false)
    } else {
      if (rePassword === password) {
        setIsShowPasswordNotMatch(false)
      } else {
        setIsShowPasswordNotMatch(true)
      }
    }
  }, [rePassword, password])

  const [isShowEmailNotValid, setIsShowEmailNotValid] = useState(false)

  useEffect(() => {
    if (email === "") {
      setIsShowEmailNotValid(false)
    } else {
      if (validateEmail()) {
        setIsShowEmailNotValid(false)
      } else {
        setIsShowEmailNotValid(true)
      }
    }
  }, [email])

  // useEffect(() => {
  //   // document.getElementById("login-background-container").style.height = window.innerHeight
  //   document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
  // }, [])

  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase())
  }

  const modalRegister = () => {
    return (
      <Modal isOpen={isShowModalRegister} className="modal-reset-password-email-sent">
        <ModalBody>
          <p>
            <FaCheckCircle />
            Verification Email Sent
          </p>
          <p>
            Please check your inbox with the registered email address and <b>verify your account</b>.
          </p>
        </ModalBody>
        <ModalFooter>
          <ButtonWithLoadingOrIcon
            className="orange-button main-button-48 width-100"
            text="Got it, thanks!"
            onClickAction={() => history.push('/')}
          />
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <div className="register-outer-container">
      <ToastContainer className="ToastContainer" />

      <div className="background-container">
        {modalRegister()}
        <div className="header-container">
          <img src={logoTaptalk} alt="TapTalk.io" />
          <p>SENDTALK</p>
        </div>

        <div className="form-container">
          <p className="form-title">Create Account</p>
          <div className="points-container display-hide-mobile">
            <div className="points-item">
              <FiCheck />
              <b>Send OTP</b>
            </div>
            <div className="points-item">
              <FiCheck />
              <b>Send Booking ID</b>
            </div>
            <div className="points-item">
              <FiCheck />
              <b>From WhatsApp</b>
            </div>
          </div>
          <div className="points-container display-flex-mobile flex-space-evenly">
            <div className="points-item">
              <FiCheck />
              <b>Send OTP</b>
            </div>
            <div className="points-item">
              <FiCheck />
              <b>Send Booking ID</b>
            </div>
          </div>
          <div className="points-container display-flex-mobile flex-center">
            <div className="points-item">
                <FiCheck />
                <b>From WhatsApp</b>
            </div>
          </div>
          {/* {failStateActive &&
            <div className="fail-panel">
              <div className="icon-wrapper">
                <FiAlertCircle />
              </div>
              <b>{failStateMessage}</b>
            </div>
          } */}
          <form onSubmit={onSubmitRegister}>
            <div className="input-wrapper">
              <label><b>Full Name</b></label>
              <input type="text"
                id="fullname"
                placeholder="Your full name"
                autoComplete="false"
                tabIndex="1"
                onChange={onChangeFullname}
                value={fullname}
              />
            </div>
            <div className="input-wrapper">
              <label><b>Email</b></label>
              <input type="text"
                id="email"
                placeholder="your@email.com"
                autoComplete="false"
                tabIndex="1"
                onChange={onChangeEmail}
                value={email}
              />
              {
                isShowEmailNotValid &&
                <div className="not-valid-email">
                  <FiAlertCircle />
                  <b>Please enter valid email address format</b>
                </div>
              }
            </div>
            <div className="input-wrapper password">
              <div className="password-title-wrapper">
                <label><b>Password</b></label>
              </div>
              <div className="popup-input-password-wrapper">
                <input type={peekPassword ? "text" : "password"}
                  id="password"
                  onChange={handlePassword}
                  placeholder="Enter your password"
                  autoComplete="false"
                  tabIndex="2"
                  value={password}
                // className={this.state.failStateActive ? 'fail-state-input' : ''}
                />
                <div className={`peek-password ${peekPassword ? 'active-eye' : ''}`}
                  onClick={() => peekPasswordClick()}
                >
                  {peekPassword ?
                    <FiEye />
                    :
                    <FiEyeOff />
                  }
                </div>
              </div>
            </div>

            <div className="input-wrapper password-strength">
              <div className="div50">
                <div className="checkLowerCase">
                  {lowerCase ?
                    <FaCheckCircle size={17} color="#7EC82A" /> :
                    <FaRegCircle size={17} color="#A4A4A4" />
                  }
                  One lowercase character
                </div>
                <div className="checkUpperCase">
                  {upperCase ?
                    <FaCheckCircle size={17} color="#7EC82A" /> :
                    <FaRegCircle size={17} color="#A4A4A4" />
                  }
                  One uppercase character
                </div>
                <div className="checkNumber">
                  {number ?
                    <FaCheckCircle size={17} color="#7EC82A" /> :
                    <FaRegCircle size={17} color="#A4A4A4" />
                  }
                    One Number
                </div>
              </div>

              <div className="div50">
                <div className="checkSpecialCharacter">
                  {specialCharacter ?
                    <FaCheckCircle size={17} color="#7EC82A" /> :
                    <FaRegCircle size={17} color="#A4A4A4" />
                  }
                    One special character
                </div>
                <div className="checkMinimumCharacter">
                  {minimumCharacter ?
                    <FaCheckCircle size={17} color="#7EC82A" /> :
                    <FaRegCircle size={17} color="#A4A4A4" />
                  }
                    8 minimum character
                </div>
              </div>
            </div>

            <div className="input-wrapper re-password">
              <div className="password-title-wrapper">
                <label><b>Re-type Password</b></label>
              </div>
              <div className="popup-input-password-wrapper">
                <input type={peekRePassword ? "text" : "password"}
                  id="password"
                  onChange={onChangeRePassword}
                  placeholder="Enter password again"
                  autoComplete="false"
                  tabIndex="2"
                  value={rePassword}
                  disabled={isDisabledRePassword()}
                // className={this.state.failStateActive ? 'fail-state-input' : ''}
                />
                <div className={`peek-password ${peekRePassword ? 'active-eye' : ''}`}
                  onClick={() => peekRePasswordClick()} style={{ cursor: isDisabledRePassword() ? 'not-allowed' : 'pointer' }}
                >
                  {peekRePassword ?
                    <FiEye />
                    :
                    <FiEyeOff />
                  }
                </div>
              </div>
              {
                isShowPasswordNotMatch &&
                <div className="error-match-password">
                  <FiAlertCircle />
                  <b>Password does not match</b>
                </div>
              }
            </div>
            
            <div className="custom-checkbox">
              <input
                type="checkbox"
                id={`checkbox-agree`}
                checked={checkedBox}
                onChange={(e) => onCheckedBox(e)}
              />

              <label htmlFor="checkbox-agree">
                I agree and understand to not send any promotional or marketing blast and by doing so, will result in account suspension.
              </label>
            </div>
            {/* <CheckOptionButton 
                text={"I agree and understand to not send any promotional or marketing blast and by doing so, will result in account suspension."} 
                value={true} 
                checked={checkedBox}
                onClickSelect={(e) => onCheckedBox(e)}
            /> */}

            {
              isWaitForResponseRegister ?
                <ButtonWithLoadingOrIcon
                  isLoading
                  loadingWhite
                  className="orange-button main-button-48 width-100"
                  text="Create Account"
                  position="left"
                  isDisabled={true}
                />
                :
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-48 width-100"
                  text="Create Account"
                  isDisabled={!validateForm()}
                />
            }
          </form>
          <div className="signup-here">
            <b>Already have an account?
              <Link to={"/login"}
                className="signup-link"
              // gtmevent={JSON.stringify(Object.assign({...HelperGTag.basicParameter("SendTalk login")} ,{
              //   event: "button-click-register",
              //   source: "login"
              // }))}
              >
                Login
              </Link>
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;