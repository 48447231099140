import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './MessageHistory.scss';
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap';
import CustomSelectOption from '../../reuseableComponent/customSelectOption/CustomSelectOption';
import { FiDownload, FiCalendar, FiEye, FiSliders } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import Pagination from '../../reuseableComponent/pagination/Pagination'
import { Modal, ModalBody } from 'reactstrap';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import CalendarPicker from '../../reuseableComponent/calendarPicker/CalendarPicker';
import FilterBy from './modalFilter/filterBy/FilterBy';
import RetrieveDataLoading from '../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading';
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import { checkID, rupiahFormat, numberWithCommas } from "../../../helper/HelperGeneral";
import MessageServices from '../../../services/messages/ServiceMessage'
import HelperDate from '../../../helper/HelperDate'
import { MESSAGE_TYPE } from '../../../constants/message';
import ImageUpload from '../../reuseableComponent/imageUpload/ImageUpload';
import { setPreviewImageOrVideo } from "../../../redux/actions/reduxActionPreviewImageOrVideo"

const MessageHistory = (props) => {
  let { match, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(false);
  let [isLoadingGenerateReport, setIsLoadingGenerateReport] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  const [messageHistory, setMessageHistory] = useState([])
  const [listPageVal, setListPageVal] = useState({
    label: '20',
    value: 20,
  })
  const [totalItems, setTotalItems] = useState(0)
  const [pageDisplay, setPageDisplay] = useState(1)
  const [listPage, setListPage] = useState(20)

  const listPageOption = [
    {
      label: '20',
      value: 20,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  const onSelectListPage = (val) => {
    let mt = []
    for (let types in messageTypes) {
      if(messageTypes[types]) mt.push(String(types).toLowerCase())
    }
    const data = {
      recipientPhone: phoneNumber,
      source: filterSourceValue.value,
      status: filterStatusValue.value,
      startDate: filterDate.from ? HelperDate.formatToString(new Date(filterDate.from), "yyyy-MM-dd") : "",
      endDate: filterDate.to ? HelperDate.formatToString(new Date(filterDate.to), "yyyy-MM-dd") : "",
      pageNumber: 1,
      pageSize: val.value,
      messageTypes: mt
    }
    getMessageHistory(data);
    setListPageVal(val)
    setListPage(val.value)
    setPageDisplay(1)
  }

  const handleChangePage = (page) => {
    let mt = []
    for (let types in messageTypes) {
      if(messageTypes[types]) mt.push(String(types).toLowerCase())
    }
    const data = {
      recipientPhone: phoneNumber,
      source: filterSourceValue.value,
      status: filterStatusValue.value,
      startDate: filterDate.from ? HelperDate.formatToString(new Date(filterDate.from), "yyyy-MM-dd") : "",
      endDate: filterDate.to ? HelperDate.formatToString(new Date(filterDate.to), "yyyy-MM-dd") : "",
      pageNumber: page,
      pageSize: listPage,
      messageTypes: mt
    }
    getMessageHistory(data);
    setPageDisplay(page)
  }

  const [isShowModalView, setIsShowModalView] = useState(false)

  const modalView = () => {
    let imageType = messageToShow.messageType === MESSAGE_TYPE.image.val.toLowerCase();
    return (
      <Modal isOpen={isShowModalView} className="modal-view-message">
        <ModalBody>
          <div className="header-modal">
            <FiEye />
            <b>
              View Message
            </b>
          </div>
          <div className="message-container">
            <label><b>Cost</b></label>
            <input type="text" value={messageToShow.currency === "IDR" ? rupiahFormat(messageToShow.cost) : `${messageToShow.currency} ${numberWithCommas(messageToShow.cost)}`} disabled />

            {
              imageType &&
              <React.Fragment>
                <label><b>Image</b></label>
                <ImageUpload
                  imageSrc={messageToShow.content}
                  onClick={toggleModalImage}
                  withoutRemove
                />
              </React.Fragment>
            }

            <div style={{marginBottom: 20}}></div>

            {imageType && !messageToShow.caption ? "" :
            <React.Fragment>
              <label><b>{imageType ? "Caption" : "Message"}</b></label>
              <textarea rows="6" type="text" placeholder="Enter message here..." value={
                imageType ?
                messageToShow.caption :
                messageToShow.content
              } disabled />
            </React.Fragment>}
          </div>
          <ButtonWithLoadingOrIcon
            className="orange-button main-button-48 width-100"
            text="Dismiss"
            onClickAction={() => setIsShowModalView(false)}
          />
        </ModalBody>
      </Modal>
    )
  }

  let [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false)
  let [filterDate, setFilterDate] = useState({
    from: undefined,
    to: undefined,
  });
  let [filterDateBefore, setFilterDateBefore] = useState({
    from: undefined,
    to: undefined,
  });
  // let [filterDateValue, setFilterDateValue] = useState("");
  let [isFilterDateActive, setIsFilterDateActive] = useState(false);

  let toggleModalCalendar = () => {
    setIsOpenModalCalendar(!isOpenModalCalendar)
  }

  let submitDateFilter = () => {
    setIsFilterDateActive(true);
    setFilterDateBefore({...filterDate});
    // setFilterDateValue(filterDate);
    toggleModalCalendar();
    setPageDisplay(1);
    let mt = []
    for (let types in messageTypes) {
      if(messageTypes[types]) mt.push(String(types).toLowerCase())
    }
    const data = {
      recipientPhone: phoneNumber,
      source: filterSourceValue.value,
      status: filterStatusValue.value,
      startDate: HelperDate.formatToString(new Date(filterDate.from), "yyyy-MM-dd"),
      endDate: HelperDate.formatToString(new Date(filterDate.to), "yyyy-MM-dd"),
      pageNumber: 1,
      pageSize: listPage,
      messageTypes: mt
    }
    getMessageHistory(data);
  }

  let handleDateFilterClick = (date) => {
    setFilterDate(date);
  }

  let onClearDatePicker = () => {
    setIsFilterDateActive(false)
    setIsOpenModalCalendar(false)
    setFilterDate({
      from: undefined,
      to: undefined
    });
    let mt = []
    for (let types in messageTypes) {
      if(messageTypes[types]) mt.push(String(types).toLowerCase())
    }
    if (isFilterDateActive) {
      const data = {
        recipientPhone: phoneNumber,
        source: filterSourceValue.value,
        status: filterStatusValue.value,
        startDate: "",
        endDate: "",
        pageNumber: 1,
        pageSize: listPage,
        messageTypes: mt
      }
      getMessageHistory(data);
    }
    setPageDisplay(1)
  }

  // filter
  const [isOpenModalFilter, setIsOpenModalFilter] = useState(false)

  const resetCurrentChanges = () => {
    setPhoneNumber(phoneNumberBefore)
    setFilterSourceValue(filterSourceValueBefore)
    setFilterStatusValue(filterStatusValueBefore)
    setMessageTypes({...messageTypesBefore})
  }

  const toggleModalFilter = () => {
    setIsOpenModalFilter(!isOpenModalFilter)
    if (!isActiveFilter) {
      setPhoneNumber("")
      setFilterStatusValue({
        label: "",
        value: ""
      })
      setFilterSourceValue({
        label: "",
        value: ""
      })
      setMessageTypes({
        [MESSAGE_TYPE.text.val]: false,
        [MESSAGE_TYPE.image.val]: false,
        [MESSAGE_TYPE.otp.val]: false,
      })
    }
  }

  const [messageTypes, setMessageTypes] = useState({
    [MESSAGE_TYPE.text.val]: false,
    [MESSAGE_TYPE.image.val]: false,
    [MESSAGE_TYPE.otp.val]: false,
  })
  const [messageTypesBefore, setMessageTypesBefore] = useState({
    [MESSAGE_TYPE.text.val]: false,
    [MESSAGE_TYPE.image.val]: false,
    [MESSAGE_TYPE.otp.val]: false,
  })

  const sourceList = [
    {
      label: "Integration API",
      value: "integration_api"
    },
    {
      label: "Customer Dashboard",
      value: "customer_dashboard"
    }
  ]

  const statusList = [
    {
      label: "Pending",
      value: "pending"
    },
    {
      label: "Failed",
      value: "failed"
    },
    {
      label: "Sent",
      value: "sent"
    }
  ]

  const [filterSourceValue, setFilterSourceValue] = useState({
    label: "",
    value: ""
  })
  const [filterSourceValueBefore, setFilterSourceValueBefore] = useState({
    label: "",
    value: ""
  })
  const [filterStatusValue, setFilterStatusValue] = useState({
    label: "",
    value: ""
  })
  const [filterStatusValueBefore, setFilterStatusValueBefore] = useState({
    label: "",
    value: ""
  })


  const onSelectSource = (val) => {
    setFilterSourceValue({
      label: val.label,
      value: val.value
    })
  }

  const onSelectStatus = (val) => {
    setFilterStatusValue({
      label: val.label,
      value: val.value
    })
  }

  const [isActiveFilter, setIsActiveFilter] = useState(false)
  const [counter, setCounter] = useState(0)

  const updateCounter = () => {
    let activeFilters = 0;
    if (filterSourceValue.value !== "" && filterStatusValue.value !== "" && phoneNumber !== "") {
      activeFilters = 3
    }
    else if (filterSourceValue.value !== "" && filterStatusValue.value !== "" && phoneNumber === "") {
      activeFilters = 2
    }
    else if (filterSourceValue.value !== "" && filterStatusValue.value === "" && phoneNumber !== "") {
      activeFilters = 2
    }
    else if (filterSourceValue.value === "" && filterStatusValue.value !== "" && phoneNumber !== "") {
      activeFilters = 2
    }
    else if (filterSourceValue.value !== "" && filterStatusValue.value === "" && phoneNumber === "") {
      activeFilters = 1
    }
    else if (filterSourceValue.value === "" && filterStatusValue.value !== "" && phoneNumber === "") {
      activeFilters = 1
    }
    else if (filterSourceValue.value === "" && filterStatusValue.value === "" && phoneNumber !== "") {
      activeFilters = 1
    }
    else if (filterSourceValue.value === "" && filterStatusValue.value === "" && phoneNumber === "") {
      activeFilters = 0
    }
    activeFilters += Object.values(messageTypes).reduce((a, item) => a + item, 0)
    setCounter(activeFilters)
  }

  const runUpdateFilter = () => {
    setIsActiveFilter(true)
    setIsOpenModalFilter(false)
    setPhoneNumberBefore(phoneNumber)
    setFilterSourceValueBefore(filterSourceValue)
    setFilterStatusValueBefore(filterStatusValue)
    setMessageTypesBefore({...messageTypes})
    let mt = []
    for (let types in messageTypes) {
      if(messageTypes[types]) mt.push(String(types).toLowerCase())
    }
    const data = {
      recipientPhone: phoneNumber,
      source: filterSourceValue.value,
      status: filterStatusValue.value,
      startDate: filterDate.from ? HelperDate.formatToString(new Date(filterDate.from), "yyyy-MM-dd") : "",
      endDate: filterDate.to ? HelperDate.formatToString(new Date(filterDate.to), "yyyy-MM-dd") : "",
      pageNumber: 1,
      pageSize: listPage,
      messageTypes: mt
    }
    getMessageHistory(data);
    setPageDisplay(1)
    updateCounter()
  }

  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberBefore, setPhoneNumberBefore] = useState("")

  const onChangePhoneNumber = (val) => {
    setPhoneNumber(val)
  }

  const onChangeMessageTypes = (val) => {
    setMessageTypes(val)
  }

  const onClearSelection = () => {
    setIsOpenModalFilter(false)
    setIsActiveFilter(false)
    setPhoneNumber("")
    setFilterStatusValue({
      label: "",
      value: ""
    })
    setFilterSourceValue({
      label: "",
      value: ""
    })
    setMessageTypes({
      [MESSAGE_TYPE.text.val]: false,
      [MESSAGE_TYPE.image.val]: false,
      [MESSAGE_TYPE.otp.val]: false,
    })
    const data = {
      recipientPhone: "",
      source: "",
      status: "",
      startDate: filterDate.from ? HelperDate.formatToString(new Date(filterDate.from), "yyyy-MM-dd") : "",
      endDate: filterDate.to ? HelperDate.formatToString(new Date(filterDate.to), "yyyy-MM-dd") : "",
      pageNumber: 1,
      pageSize: 20,
      messageTypes: []
    }
    getMessageHistory(data);
    setPageDisplay(1)
  }

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          // const { id, createdTime } = responseData.memberships[0].organization;
          // const urlOrgID = `${id}-${createdTime}`;
          // history.push(`/o/${urlOrgID}/api-key/general`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if (orgID) {
      const data = {
        recipientPhone: "",
        source: "",
        status: "",
        startDate: "",
        endDate: "",
        pageNumber: 1,
        pageSize: listPage,
        messageTypes: []
      }
      getMessageHistory(data);
      setPageDisplay(1)
    }
  }, [orgID]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  const getMessageHistory = (data) => {
    setWaitForResponseGetList(true)
    let _retriveDataError = { ...retriveDataError };

    MessageServices.getMessageHistory(orgID, data, (response) => {
      let dataResult = response.dataResult;
      if (dataResult.error.message === "") {
        setTotalItems(dataResult.data.totalItems)
        setMessageHistory(dataResult.data.messages);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  const [messageToShow, setMessageToShow] = useState({
    content: "",
    cost: 0,
    messageType: "",
    caption: "",
    currency: ""
  })

  const onShowMessage = (message) => {
    const data = {
      content: message.contentBody,
      cost: message.price,
      messageType: message.messageType,
      caption: message.contentCaption,
      currency: message.currency,
    }
    setMessageToShow(data)
    setIsShowModalView(true)
  }

  const getMessageSource = (source) => {
    switch (source) {
      case 'customer_dashboard':
        return 'Customer Dashboard'
      case 'integration_api':
        return 'Integration API'
      default:
        return 'Customer Dashboard'
    }
  }

  let toCapitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  let [isShowModalImage, setIsShowModalImage] = useState(false);

  let toggleModalImage = () => {
    setIsShowModalImage(!isShowModalImage);
  }

  let generateModalImage = () => {
    let zoomImage = (e) => {
        var zoomer = e.currentTarget;
        zoomer.style.backgroundSize = '200%';
        var offsetX = e.nativeEvent.offsetX;
        var offsetY = e.nativeEvent.offsetY;
        
        var x = offsetX/zoomer.offsetWidth*100
        var y = offsetY/zoomer.offsetHeight*100
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
    }

    let zoomImageOut = (e) => {
        var zoomer = e.currentTarget;
        zoomer.style.backgroundSize = '0%';
    }
    
    return (
        <div>
          <Modal isOpen={isShowModalImage} className={'modal-image'}>
            <ModalBody onClick={() => toggleModalImage()}>
                <IoIosClose className="close-modal-image" onClick={() => toggleModalImage()} />
                <div className="image-wrapper">
                    <figure 
                        className="zoom jum" 
                        style={{"backgroundImage": `url(${messageToShow.content})`}} 
                        onMouseMove={(e) => zoomImage(e, messageToShow.content)}
                        onMouseLeave={(e) => zoomImageOut(e)}
                    >
                        <img 
                            src={messageToShow.content} 
                            alt="" 
                            className="image-preview-val"
                        />
                    </figure>
                </div>
            </ModalBody>
          </Modal>
        </div>
    );
  }
  
  const downloadReport = (data) => {
    // const id = orgID.split('-');
    // const timestamp = + new Date();
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', !filterDate.to ? "sendtalk-message-history-data.csv" : `sendtalk-message-history-data-${HelperDate.formatToString(new Date(filterDate.from), "dd-MM-yyyy")}-to-${HelperDate.formatToString(new Date(filterDate.to), "dd-MM-yyyy")}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const generateReport = () => {
    setIsLoadingGenerateReport(true);

    let mt = []
    for (let types in messageTypes) {
      if(messageTypes[types]) mt.push(String(types).toLowerCase())
    }
    const data = {
      recipientPhone: phoneNumber,
      messageTypes: mt,
      source: filterSourceValue.value,
      status: filterStatusValue.value,
      startDate: filterDate.from ? HelperDate.formatToString(new Date(filterDate.from), "yyyy-MM-dd") : "",
      endDate: filterDate.to ? HelperDate.formatToString(new Date(filterDate.to), "yyyy-MM-dd") : "",
    }

    MessageServices.exportMessage(
      orgID,
      data,
      (response) => {
        setIsLoadingGenerateReport(false);
        if (!response) {
          // setShowModalFailed(true);
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        withHeader
        withSideMenu
      >
        {/* <ModalPreviewImageOrVideo /> */}
        {messageToShow.content && generateModalImage()}
        {modalView()}
        <div className="sectionWrap message-history-main-wrapper">
          <div className="header-container">
            <div className="header-wrapper">
              <div className="topContent ">
                <div className="message-history-header-title">Message History</div>
              </div>
              <div className="message-history-header-description">
                View your sent messages history.
              </div>
            </div>
            {/* <ButtonWithLoadingOrIcon
              className="orange-button main-button-40"
              text="Download All"
              icon={{
                type: "svg",
                src: FiDownload
              }}
              position="left"
              isDisabled={false}
            /> */}
          </div>
          <div className="button-container">
            {
              !isFilterDateActive ?
                < ButtonWithLoadingOrIcon
                  className="no-fill-button main-button-40 date-button"
                  text="Date Range"
                  icon={{
                    type: "svg",
                    src: FiCalendar
                  }}
                  position="left"
                  isDisabled={false}
                  onClickAction={() => setIsOpenModalCalendar(true)}
                />
                :
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40 date-button"
                  text={`${HelperDate.formatToString(new Date(filterDate.from), "dd MMMM yyyy")} - ${HelperDate.formatToString(new Date(filterDate.to), "dd MMMM yyyy")}`}
                  icon={{
                    type: "svg",
                    src: FiCalendar
                  }}
                  position="left"
                  isDisabled={false}
                  onClickAction={() => setIsOpenModalCalendar(true)}
                />
            }
            {
              !isActiveFilter ?
                <ButtonWithLoadingOrIcon
                  className="no-fill-button main-button-40 date-button"
                  text="Filters"
                  icon={{
                    type: "svg",
                    src: FiSliders
                  }}
                  position="left"
                  isDisabled={false}
                  onClickAction={() => setIsOpenModalFilter(true)}
                />
                :
                <button
                  className={`main-reuseable-button orange-button main-button-40 filter-button date-button`}
                  onClick={() => setIsOpenModalFilter(true)}
                >
                  <FiCalendar />

                  <div className="button-text-wrapper">
                    Filters
                  </div>

                  <div className="badge-counter">
                    {counter}
                  </div>
                </button>
            }
            {!isLoadingGenerateReport ?
              <ButtonWithLoadingOrIcon
                className="no-fill-button main-button-40"
                text="Export"
                icon={{
                  type: "svg",
                  src: FiDownload
                }}
                position="left"
                isDisabled={false}
                onClickAction={generateReport}
              />
              :
              <ButtonWithLoadingOrIcon
                className="no-fill-button main-button-40"
                text="Export"
                isLoadingnpm
                position="left"
                isDisabled={false}
              />
            }
          </div>

          {
            waitForResponseGetList || retriveDataError.code ?
              <RetrieveDataLoading
                isLoading={waitForResponseGetList}
                errorMessage={retriveDataError.message}
              />
              :
              <div className="message-history-table-wrapper">
                <div className="custom-table">
                  <table className="custom-table-content table-message-history-list">
                    <thead>
                      <tr>
                        <td className="col_name">Date and Time</td>
                        <td className="col_message_type">Recipient</td>
                        <td className="col_sender">Message</td>
                        <td className="col_message_type">Type</td>
                        <td className="col_message_type">Source</td>
                        <td className="col_sender">Status</td>
                        <td>
                          <CustomSelectOption
                            optionListProps={listPageOption}
                            valueDropdownProps={listPageVal}
                            onClickDropDownListOptionProps={onSelectListPage}
                          />
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {messageHistory.length > 0 ?
                        messageHistory.map(item => {
                          return (
                            <tr key={`topup-${item.id}`} >
                              <td className="">
                                <p className="">{HelperDate.formatToString(new Date(item.createdTime), "dd MMM yyyy HH:mm")}</p>
                              </td>
                              <td className="">
                                <p>{item.recipientPhone}</p>
                              </td>
                              <td className="col-view" onClick={() => onShowMessage(item)}>
                                <FiEye />
                              View
                            </td>
                            <td className="">
                                <p>{toCapitalize(item.messageType)}</p>
                              </td>
                              <td className="">
                                <p>{getMessageSource(item.source)}</p>
                              </td>
                              <td className="col-status">
                                <p>{item.status}</p>
                              </td>
                              <td></td>
                            </tr>
                          )
                        })
                        :
                        <tr className="no-data-row">
                          <td colSpan="7" className="no-data-column"><b className="no-result-found">
                            {isFilterDateActive || isActiveFilter ? 
                              "No results found, please try another date range or filters." 
                              : "No message history found."}
                          </b></td>
                        </tr>}
                    </tbody>

                  </table>
                </div>
              </div>
          }

          <div className="pagination-wrapper">
            {
              totalItems > 0 &&
              <Pagination
                total={totalItems}
                page={pageDisplay}
                listPerPage={listPage}
                step={1}
                onChangePage={handleChangePage}
              ></Pagination>
            }
          </div>

          <CalendarPicker
            isOpen={isOpenModalCalendar}
            toggle={toggleModalCalendar}
            filterDate={filterDate}
            filterDateBefore={filterDateBefore}
            handleDateFilterClick={handleDateFilterClick}
            isFilterDateActive={isFilterDateActive}
            submitDateFilter={submitDateFilter}
            onClearSelection={onClearDatePicker}
            isDisabled={!filterDate.from || !filterDate.to}
            {...props}
          />

          <FilterBy
            isOpenProps={isOpenModalFilter}
            toggleProps={toggleModalFilter}
            resetCurrentChanges={resetCurrentChanges}
            phoneNumberProps={phoneNumber}
            onChangePhoneNumberProps={onChangePhoneNumber}
            messageTypesProps={messageTypes}
            onChangeMessageTypesProps={onChangeMessageTypes}
            sourceListProps={sourceList}
            filterSourceValueProps={filterSourceValue}
            statusListProps={statusList}
            filterStatusValueProps={filterStatusValue}
            onSelectSourceProps={onSelectSource}
            onSelectStatusProps={onSelectStatus}
            updateFilterProps={runUpdateFilter}
            closeModal={() => setIsOpenModalFilter(false)}
            isActiveFilterProps={isActiveFilter}
            onClearSelectionProps={onClearSelection}
          />

        </div>

      </SectionWrap>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  previewImageOrVideo: state.previewImageOrVideo,
});

const mapDispatchToProps = {
  setPreviewImageOrVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageHistory);