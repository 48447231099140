import React from "react";
// import Header from "../header/Header";
// import Sidemenu from "../sidemenu/Sidemenu";
import "./SectionWrap.scss";
import { connect } from 'react-redux';
import { setActiveOrgID } from "../../../redux/actions/reduxActionActiveOrgID";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SectionWrap = ({ withHeader, withSideMenu, ...props }) => {
  const { children: content } = props;
  // const { children: content, orgID } = props;
  // const dataID = orgID || "";
  
  // const baseProps = {
  //   organizationID: dataID,
  //   projectEnvID: ""
  // };

  let isSubmenuExist = () => {
    return typeof props.sideMenu === "object" && props.sideMenu.submenuList;
  }

  // useEffect(() => {
  //   props.setActiveOrgID(dataID);
  // }, [orgID])

  return (
    <React.Fragment>
      {/* {withHeader && <Header {...baseProps} />} */}
      {/* {withSideMenu && <Sidemenu {...props} {...baseProps} />} */}
      <div id={`sectionContent`} className={`sectionContent ${!isSubmenuExist() ? "deactive-sub-menu" : ""} ${props.billingWarning ? "section-wrap-active-billing-warning" : ""}`}>
        {/* <div className="sectionWrap">{content}</div> */}
        {content}
      </div>

      <ToastContainer className="ToastContainer" />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  sideMenu: state.sideMenu,
  billingWarning: state.billingWarning
});

const mapDispatchToProps = {
  setActiveOrgID
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionWrap)

