import React, { useState, useEffect } from "react";
import "./WhatsappCreateVerification.scss";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { FiAlertTriangle, FiInfo, FiPlus, FiEye, FiExternalLink } from "react-icons/fi";
import { checkID, doToast, scrollToClass } from "../../../helper/HelperGeneral";
import HelperInput from "../../../helper/HelperInput";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CustomSelectOption from "../../reuseableComponent/customSelectOption/CustomSelectOption";
import CopyBox from "../../reuseableComponent/copyBox/CopyBox";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceWhatsapp from "../../../services/whatsapp/ServiceWhatsapp";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { setParentMenuBlockActive } from "../../../redux/actions/reduxActionSideMenu";

let WhatsappCreateVerification = (props) => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [loadingWhatsappData, setLoadingWhatsappData] = useState(false);
    let [whatsappData, setWhatsappData] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [showModalSubmit, setShowModalSubmit] = useState(false);
    let [verificationData, setVerificationData] = useState({
        "userPhone": "",
        "userPhoneError": "",
        "languageCode": "id",
        "languageCodeDropdown": {
            value: "id",
            label: "Indonesia"
        },
        "languageCodeError": "",
        "expiryMinutes": "",
        "appURL": "",
        "appURLError": ""
    });
    let [loadingCreate, setLoadingCreate] = useState(false);
    let [dataAfterCreate, setDataAfterCreate] = useState(false);

    let toogleModalSubmit = () => {
        setShowModalSubmit(!showModalSubmit);
    }
  
    let submitAction = () => {
        let _verificationData = {...verificationData};
        let errorCount = 0;
        let errorClass = "";

        if(verificationData.userPhone === "") {
            errorCount = errorCount + 1;
            errorClass = "input-user-phone";
            _verificationData.userPhoneError = "This field is required";
        }

        if(!HelperInput.validURL(verificationData.appURL) && verificationData.appURL !== "") {
            errorCount = errorCount + 1;
            errorClass = "input-app-url";
            _verificationData.appURLError = "Invalid URL format";
        }

        if(verificationData.languageCode === "") {
            errorCount = errorCount + 1;
            errorClass = "input-language";
            _verificationData.languageCodeError = "Please select language";
        }

        setVerificationData(_verificationData);

        if(errorCount > 0) {
            scrollToClass(`.${errorClass}`);
        }else {
            setLoadingCreate(true);

            let _data = {...verificationData};
            
            if(_data.expiryMinutes === "") {
                _data.expiryMinutes = 0;;
            }

            ServiceWhatsapp.postCreateHistory(orgID, _data, (res) => {
                let dataResult = res.dataResult;

                if(dataResult.error.code === "") {
                    if(dataResult.data.success) {
                        doToast(dataResult.data.message);
                        setDataAfterCreate(dataResult.data.verification);
                    }else {
                        doToast(dataResult.data.message, "fail");
                    }
                }else {
                    doToast(dataResult.error.message, "fail");
                }

                setLoadingCreate(false);
            })
        }
    }

    const doMount = async () => {
        const paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              const { id, createdTime } = responseData.memberships[0].organization;
              const urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/label`);
            } else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };

    let getOrganizationDetail = () => {
        setLoadingWhatsappData(true);

        let _retriveDataError = {...retriveDataError};

        ServiceWhatsapp.postGetConfig(orgID, (response) => {
           let dataResult = response.dataResult;
      
            if(dataResult.error.message === "") {
                setWhatsappData(dataResult.data.configs)
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setLoadingWhatsappData(false);
          });
    }

    useEffect(() => {
        if (props.history.location.state) {
          if (props.history.location.state.message) {
            doToast(props.history.location.state.message);
            window.history.pushState(null, '');
          }
        }
      }, [props.history])

    useEffect(() => {
        doMount();
    }, []);
    
    useEffect(() => {
        if (orgID) {
            getOrganizationDetail();
        }
    }, [orgID]);

    useEffect(() => {
        if(dataAfterCreate) {
            setShowModalSubmit(true);
        }
    }, [dataAfterCreate])

    let onClickLink = (id) => {
        let _sideMenu = { ...props.sideMenu };
        _sideMenu.activeSubMenu = "verification-history";

        props.setParentMenuBlockActive(_sideMenu);

        props.history.push(`/o/${orgID}/whatsapp-verification/verification-history/details/${id}`)
    } 

    let ModalSubmit = () => (
        <Modal isOpen={showModalSubmit} className="modal-submit-verification">
            <ModalBody>
                <p>
                    <b><FiEye />Response</b>
                </p>

                {dataAfterCreate &&
                    <>
                        <label>
                            <b>Verification ID</b>
                        </label>
                        <CopyBox 
                            className=""
                            id="verification-id"
                            val={dataAfterCreate.id}
                            copyText="Verification ID Copied"
                        />
                        
                        <label>
                            <b>WhatsApp Link</b>
                        </label>
                        <CopyBox 
                            className=""
                            id="whatsapp-link"
                            val={dataAfterCreate.waLink}
                            copyText="Whatsapp Link Copied"
                        />

                        <label>
                            <b>WhatsApp Message</b>
                        </label>
                        <CopyBox 
                            className=""
                            id="whatsapp-message"
                            val={dataAfterCreate.waMessage}
                            copyText="WhatsApp Message Copied"
                            isTextArea
                        />

                        <div className="a-link-href" onClick={() => onClickLink(dataAfterCreate.id)}>
                            <b>
                                Go to Details

                                <FiExternalLink />
                            </b>
                        </div>
                    </>
                }
                
                <ButtonWithLoadingOrIcon
                    className="orange-button main-button-48 submit-button"
                    text="Dismiss"
                    onClickAction={toogleModalSubmit}
                />
            </ModalBody>
        </Modal>
    )

    let onChangeInput = (e) => {
        let _verificationData = {...verificationData};

        if(e.target.id === "userPhone" && HelperInput.numberOnly(e.target.value)) {
            _verificationData[e.target.id] = e.target.value;
            _verificationData.userPhoneError = "";
        }
        
        if(e.target.id === "expiryMinutes" && HelperInput.numberOnly(e.target.value)) {
            _verificationData[e.target.id] = e.target.value === "" ? "" : parseInt(e.target.value);
        }
        
        if(e.target.id === "appURL") {
            _verificationData[e.target.id] = e.target.value;
            _verificationData.appURLError = "";
        }

        setVerificationData(_verificationData);
    }

    let onChangeLang = (e) => {
        let _verificationData = {...verificationData};
        _verificationData.languageCode = e.value;
        _verificationData.languageCodeDropdown = e;
        _verificationData.languageCodeError = "";
        setVerificationData(_verificationData);
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >    
            <div className="sectionWrap whatsapp-create-verification-container">
                <ModalSubmit />

                <div className="topContent whatsapp-create-verification-top-content">
                    <div className="title title-whatsapp-verification">
                        Create Verification

                        <span>
                            For more details, please read <a href="https://docs.taptalk.io/sendtalk-api-documentation/whatsapp-verification" target="_blank">this documentation.</a>
                        </span>
                    </div>
                </div>

                {loadingWhatsappData || retriveDataError.code ? 
                    <RetrieveDataLoading 
                        isLoading={loadingWhatsappData}
                        errorMessage={retriveDataError.message} 
                    />
                    :
                    <div className="whatsapp-create-verification-bottom-content">
                        {!whatsappData.isEnabled &&
                            <div className="alert-box">
                                <FiAlertTriangle />

                                <b>Create verification is disabled. Make sure your WhatsApp verification config is enabled to create verification.</b>
                            </div>
                        }
                        
                        {whatsappData.isEnabled &&
                            <>
                                <p className="whatsapp-create-verification-title">
                                    <b>User Phone Number</b>
                                </p>
                                <div className="input-wrapper input-phone">
                                    <FiPlus />

                                    <input 
                                        maxLength={20}
                                        type="text" 
                                        value={verificationData.userPhone}
                                        placeholder="6281234567890"
                                        id="userPhone"
                                        onChange={(e) => onChangeInput(e)}
                                        className={`input-user-phone ${verificationData.userPhoneError === "" ? "" : "border-red"}`}
                                    />
                                </div>
                                <ErrorOrInfoComp 
                                    icon={<FiInfo />}
                                    _className={`${verificationData.userPhoneError === "" ? "" : "red-text"}`}
                                    text={verificationData.userPhoneError === "" ? "Add country code first e.g +62." : verificationData.userPhoneError}
                                />
                                
                                <p className="whatsapp-create-verification-title">
                                    <b>Expire duration</b> <span> (Optional)</span>
                                </p>
                                <div className="input-wrapper input-minute">
                                    <input 
                                        type="text" 
                                        value={verificationData.expiryMinutes}
                                        placeholder="Input number here"
                                        id="expiryMinutes"
                                        onChange={(e) => onChangeInput(e)}
                                        maxLength={3}
                                    />

                                    <span>
                                        Minutes
                                    </span>
                                </div>

                                <p className="whatsapp-create-verification-title">
                                    <b>Application Link</b> <span> (Optional)</span>
                                </p>
                                <div className="input-wrapper">
                                    <input 
                                        type="text" 
                                        placeholder="https://www.example.com"
                                        id="appURL"
                                        onChange={(e) => onChangeInput(e)}
                                        value={verificationData.appURL}
                                        className={`input-app-url ${verificationData.appURLError === "" ? "" : "border-red"}`}
                                    />
                                </div>
                                {verificationData.appURLError !== "" &&
                                    <ErrorOrInfoComp 
                                        icon={<FiInfo />}
                                        _className={"red-text"}
                                        text={verificationData.appURLError}
                                    />
                                }

                                <p className="whatsapp-create-verification-title">
                                    <b>Language</b>
                                </p>
                                <CustomSelectOption
                                    optionListProps={[
                                        {
                                            value: "id",
                                            label: "Indonesia"
                                        },
                                        {
                                            value: "en",
                                            label: "English"
                                        }
                                    ]}
                                    valueDropdownProps={verificationData.languageCodeDropdown}
                                    onClickDropDownListOptionProps={onChangeLang}
                                    placeholderProps={"Please Select"}
                                    specifiClassNameProps="select-lang"
                                    dropdownError={verificationData.languageCodeError}
                                />
                                {verificationData.languageCodeError !== "" &&
                                    <ErrorOrInfoComp 
                                        icon={<FiInfo />}
                                        _className={"red-text"}
                                        text={verificationData.languageCodeError}
                                    />
                                }

                                {loadingCreate ?
                                    <ButtonWithLoadingOrIcon
                                        className="orange-button main-button-40 submit-button"
                                        text="Create"
                                        isLoading
                                        loadingWhite
                                        position="left"
                                    />
                                    :
                                    <ButtonWithLoadingOrIcon
                                        className="orange-button main-button-40 submit-button"
                                        text="Create"
                                        onClickAction={submitAction}
                                    />
                                }
                            </>
                        }
                    </div>
                }
            </div>
        </SectionWrap>
    )
}

const mapDispatchToProps = {
    setParentMenuBlockActive,
};

const mapStateToProps = state => ({
    countryList: state.countryList,
    sideMenu: state.sideMenu
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatsappCreateVerification);