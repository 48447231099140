import React, { useState, useEffect } from 'react';
import './TopUpNow.scss'
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap';
import PopupConfirmation from '../../reuseableComponent/popupConfirmation/PopupConfirmation';
import { FiInfo } from "react-icons/fi";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import Pagination from '../../reuseableComponent/pagination/Pagination';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import BillingServices from '../../../services/billing/ServiceBilling';
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import RetrieveDataLoading from '../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading';
import HelperDate from '../../../helper/HelperDate';
import { rupiahFormat } from '../../../helper/HelperGeneral';

const TOPUP_LIST = [25000, 50000, 100000, 250000, 500000, 1000000];

const TopUpNow = (props) => {
  let { match, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [topUpNowData, setTopUpNowData] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  const [topupAmount, setTopupAmount] = useState("");
  const [showPopupConfirmation, setShowPopupConfirmation] = useState(false);
  const [selectedTopupAmount, setSelectedTopupAmount] = useState("");
  const [totalItems, setTotalItems] = useState(0)
  // const [listPageVal, setListPageVal] = useState({
  //   label: '20',
  //   value: 20,
  // })
  const [pageDisplay, setPageDisplay] = useState(1)
  const [listPage, setListPage] = useState(20)
  const [isLoadingTopupNow, setIsLoadingTopupNow] = useState(false)

  // const listPageOption = [
  //   {
  //     label: '20',
  //     value: 20,
  //   },
  //   {
  //     label: '50',
  //     value: 50,
  //   },
  //   {
  //     label: '100',
  //     value: 100,
  //   },
  // ]

  // const onSelectListPage = (val) => {
  //   const data = {
  //     pageNumber: 1,
  //     pageSize: val.value
  //   }
  //   getTopUpNowData(data);
  //   setListPageVal(val)
  //   setListPage(val.value)
  //   setPageDisplay(1)
  // }

  const handleChangePage = (page) => {
    setPageDisplay(page)
    const data = {
      pageNumber: page,
      pageSize: listPage
    }
    getTopUpNowData(data);
  }

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          // const { id, createdTime } = responseData.memberships[0].organization;
          // const urlOrgID = `${id}-${createdTime}`;
          // history.push(`/o/${urlOrgID}/api-key/general`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
    setListPage(20);
  }, [location.pathname]);

  const getTopUpNowData = (data) => {
    setWaitForResponseGetList(true)
    let _retriveDataError = { ...retriveDataError };

    BillingServices.getTopupHistoryPending(orgID, data, (response) => {
      let dataResult = response.dataResult;
      if (dataResult.error.message === "") {
        setTopUpNowData(dataResult.data.requests);
        setTotalItems(dataResult.data.totalItems)
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  useEffect(() => {
    if (orgID) {
      const data = {
        pageNumber: 1,
        pageSize: 20
      }
      getTopUpNowData(data);
    }
  }, [orgID]);

  let toggleModalPopupConfirmation = () => {
    setShowPopupConfirmation(!showPopupConfirmation);
  }

  let onSubmitTopup = () => {
    setIsLoadingTopupNow(true);

    let data = {
      currency: "IDR",
      amount: parseInt(topupAmount)
    };
    
    BillingServices.postTopuNow(orgID, data, (response) => {
      let dataResult = response.dataResult;
      if (dataResult.error.message === "") {
        props.history.push({
          pathname: `/o/${orgID}/payment/topup-now/pay`,
          state: {
            paymentLink: dataResult.data.paymentLink
          }
        })
      } else {
        doToast(dataResult.error.message, "fail");
        setIsLoadingTopupNow(false);
      }
    })
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap topup-now-main-wrapper">
          <div className="topContent ">
            <div className="topup-now-header-title">Pending Top Up</div>
          </div>
          <div className="topup-now-header-description">
            Follow the instructions below to top up your balance.
          </div>

          {
            waitForResponseGetList || retriveDataError.code ?
              <RetrieveDataLoading
                isLoading={waitForResponseGetList}
                errorMessage={retriveDataError.message}
              />
              :
              <div className="topup-now-table-wrapper">
                <div className="custom-table">
                  <table className="custom-table-content table-topup-now-list">
                    <thead>
                      <tr>
                        <td className="col_created">Create Time</td>
                        <td className="col_expiry_time">Expiry Time</td>
                        <td className="col_amount">Amount</td>
                        <td></td>
                        {/* <td>
                          <CustomSelectOption
                            optionListProps={listPageOption}
                            valueDropdownProps={listPageVal}
                            onClickDropDownListOptionProps={onSelectListPage}
                          />
                        </td> */}
                      </tr>
                    </thead>

                    <tbody>
                      {
                        topUpNowData.length > 0 ?
                          topUpNowData.map(item => {
                            return (
                              <tr>
                                <td className="">
                                  <p className="">{HelperDate.formatToString(new Date(item.createdTime), "dd MMM yyyy HH:mm")}</p>
                                </td>
                                <td className="">
                                  <p className="">{HelperDate.formatToString(new Date(item.expiryTime), "dd MMM yyyy HH:mm")}</p>
                                </td>
                                <td className="col-transaction">
                                  <p>{rupiahFormat(item.amount)}</p>
                                </td>
                                <td>
                                  <button 
                                    className="button-topup-now orange-button"
                                    onClick={() => {
                                      props.history.push({
                                        pathname: `/o/${orgID}/payment/topup-now/pay`,
                                        state: {
                                          paymentLink: item.paymentLink
                                        }
                                      })
                                    }}
                                  >
                                    <b>Pay Now</b>
                                  </button>
                                </td>
                              </tr>
                            )
                          })
                          :
                          <tr className="no-data-row">
                            <td colSpan="4" className="no-data-column"><b className="no-result-found">No pending top up.</b></td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
          }

          <div className="pagination-wrapper">
            {
              totalItems > 0 &&
              <Pagination
                total={totalItems}
                page={pageDisplay}
                listPerPage={listPage}
                step={1}
                onChangePage={handleChangePage}
              ></Pagination>
            }
          </div>
        </div>

        <div className="sectionWrap topup-now-main-wrapper">
          <PopupConfirmation
             isOpen={showPopupConfirmation}
             toggle={toggleModalPopupConfirmation}
             className="topup-confirmation-modal"
             title="Confirm Top Up"
             description="Are you sure you want to top up with the amount below?"
             submitText="Continue"
             submitAction={onSubmitTopup}
             isLoading={isLoadingTopupNow}
          >
            <div className="topup-confirmation-modal-amount-wrapper">
              <b>{rupiahFormat(topupAmount)}</b>
            </div>
          </PopupConfirmation>

          <div className="topContent ">
            <div className="topup-now-header-title">Top Up Now</div>
          </div>
          <div className="topup-now-header-description">
            Follow the instructions below to top up your balance.
          </div>

          <div className="top-up-now-amount-wrapper">
            <label><b>Amount</b></label>
            <br />

            <div className="topup-amount-input-wrapper">
              <span>
                Rp
              </span>
              <input 
                type="text" 
                placeholder="Min. 10.000"
                value={topupAmount}
                maxLength="8"
                onChange={(e) => {
                  let reg = /^[0-9]+$/;

                  if(reg.test(e.target.value) || e.target.value === "") {
                    setSelectedTopupAmount("");
                    setTopupAmount(e.target.value);
                  }
                }}
              />
            </div>
            
            <p className="topup-info-text">
              <FiInfo />
              <b>Enter your preferred topup amount or choose the amount from below</b>
            </p>

            <div className="select-topup-nominal-wrapper">
              {TOPUP_LIST.map((val, index) => {
                return (
                  <div 
                    className={`select-topup-content ${selectedTopupAmount === val ? "select-topup-content-active" : ""}`} 
                    onClick={() => {
                      setTopupAmount(val);
                      setSelectedTopupAmount(val);
                    }}
                    key={`topup-${index}`}
                  >
                    {rupiahFormat(val)}
                  </div>
                )
              })}
            </div>
            
            <ButtonWithLoadingOrIcon
              text="Top Up"
              className="orange-button main-button-48 button-topUpNowData"
              onClickAction={toggleModalPopupConfirmation}
              isDisabled={topupAmount === ""} 
            />
          </div>
        </div>

      </SectionWrap>
    </React.Fragment>
  );
};

export default TopUpNow;