const INITIAL_STATE = "";

let reducerBilling = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_BILLING':
        return action.billing;
      case 'CLEAR_BILLING':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reducerBilling;