import React, { useState } from 'react';
import '../Auth.css';
import './ForgotPassword.scss'
import logoTaptalk from '../../../assets/img/auth/logo-taptalk.svg'
import ArrowLeft from '../../../assets/img/arrow-left.svg';
import { Link, useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FaCheckCircle } from 'react-icons/fa'
import { FiArrowLeft } from 'react-icons/fi'
import ServiceResetPassword from '../../../services/auth/ServiceResetPassword';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';

const style = {
  boldOrange: {
    fontWeight: "bold",
    color: "#ff7e00",
    cursor: "pointer"
  },
  chevronLeft: {
    position: "relative",
    marginRight: '2px',
    top: '-1px'
  }
};

const ForgotPassword = () => {
  const [email, setEmail] = useState("")
  const [isModalEmailSent, setIsModalEmailSent] = useState(false)
  const history = useHistory();
  const [isSendEmail, setIsSendEmail] = useState(false)

  const sendEmailClick = (e) => {
    e.preventDefault()
    setIsSendEmail(true);

    ServiceResetPassword.requestToken(email).then(response => {
      setIsSendEmail(false);

      if (response.dataResult.status === 200) {
        if (response.dataResult.data.success) {
          setIsModalEmailSent(true)
        }
      }
    })
  }

  const validateForm = () => {
    return email !== "";
  }

  const backToLogin = () => {
    history.push('/')
  }

  // const onSubmitForgotPassword = (e) => {
  //   e.preventDefault()
  //   console.log('Forgot Password')
  //   setIsModalEmailSent(true)
  // }

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const modalResetPasswordEmailSent = () => {
    return (
      <Modal isOpen={isModalEmailSent} className="modal-reset-password-email-sent">
        <ModalBody>
          <p>
            <FaCheckCircle />
            Instructions Sent to Email
          </p>
          <p>
            Please follow the instructions that have been sent to the registered account  <b>{email}</b>.
          </p>
        </ModalBody>
        <ModalFooter>
          <button className="orange-button" onClick={backToLogin}>
            <FiArrowLeft />
            <b>Back to Log In</b>
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <div className="outer-container">
      {modalResetPasswordEmailSent()}
      <div className="background-container">
        <div className="header-container">
          <img src={logoTaptalk} alt="TapTalk.io" />
          <p>SENDTALK</p>
        </div>
        <div className="form-container">
          <p className="form-title">Forgot Password</p>
          <form onSubmit={sendEmailClick}>
            <div className="input-wrapper">
              <label><b>Email</b></label>
              <input type="text"
                id="email"
                placeholder="your@email.com"
                autoComplete="false"
                tabIndex="1"
                onChange={onChangeEmail}
                value={email}
              />
            </div>

            {
              isSendEmail ?
                <ButtonWithLoadingOrIcon
                  isLoading
                  loadingWhite
                  className="orange-button main-button-48 width-100"
                  text="Send Email"
                  position="left"
                  isDisabled={true}
                />
                :
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-48 width-100"
                  text="Send Email"
                  isDisabled={!validateForm()}
                />
            }
          </form>
          <div id="signup-here">
            <Link to={'/'} style={style.boldOrange}>
              <img src={ArrowLeft} style={style.chevronLeft} alt="Chevron Left" /> Back to Log In
            </Link>
          </div>
          <div className="signup-here">
            <b>Don't have an account?
              <Link to={"/register"}
                className="signup-link"
              // gtmevent={JSON.stringify(Object.assign({...HelperGTag.basicParameter("SendTalk login")} ,{
              //   event: "button-click-register",
              //   source: "login"
              // }))}
              >
                Create an Account
              </Link>
            </b>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;