import React, { useState, useEffect } from 'react';
import './TopUpHistory.scss'
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap';
import CustomSelectOption from '../../reuseableComponent/customSelectOption/CustomSelectOption';
import { FiCopy } from "react-icons/fi";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import Pagination from '../../reuseableComponent/pagination/Pagination'
import BillingServices from '../../../services/billing/ServiceBilling';
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import RetrieveDataLoading from '../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading';
import HelperDate from '../../../helper/HelperDate';
import { rupiahFormat } from '../../../helper/HelperGeneral';

const TopUpHistory = (props) => {
  let { match, location } = props;
  let [orgID, setOrgID] = useState(false);
  const [topUpHistory, setTopUpHistory] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  const [totalItems, setTotalItems] = useState(0)
  const [listPageVal, setListPageVal] = useState({
    label: '20',
    value: 20,
  })
  const [pageDisplay, setPageDisplay] = useState(1)
  const [listPage, setListPage] = useState(20)

  const listPageOption = [
    {
      label: '20',
      value: 20,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  const onSelectListPage = (val) => {
    const data = {
      pageNumber: 1,
      pageSize: val.value
    }
    getTopupHistory(data);
    setListPageVal(val)
    setListPage(val.value)
    setPageDisplay(1)
  }

  const handleChangePage = (page) => {
    setPageDisplay(page)
    const data = {
      pageNumber: page,
      pageSize: listPage
    }
    getTopupHistory(data);
  }

  const copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          // const { id, createdTime } = responseData.memberships[0].organization;
          // const urlOrgID = `${id}-${createdTime}`;
          // history.push(`/o/${urlOrgID}/api-key/general`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  const getTopupHistory = (data) => {
    setWaitForResponseGetList(true)
    let _retriveDataError = { ...retriveDataError };

    BillingServices.getTopupHistory(orgID, data, (response) => {
      let dataResult = response.dataResult;
      if (dataResult.error.message === "") {
        setTopUpHistory(dataResult.data.transactions);
        setTotalItems(dataResult.data.totalItems)
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  useEffect(() => {
    if (orgID) {
      const data = {
        pageNumber: 1,
        pageSize: 20
      }
      getTopupHistory(data);
    }
  }, [orgID]);

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap topup-now-main-wrapper">
          <div className="topContent ">
            <div className="topup-now-header-title">Top Up History</div>
          </div>
          <div className="topup-now-header-description">
            List of your processed top up balance history.
          </div>

          {
            waitForResponseGetList || retriveDataError.code ?
              <RetrieveDataLoading
                isLoading={waitForResponseGetList}
                errorMessage={retriveDataError.message}
              />
              :
              <div className="topup-history-table-wrapper">
                <div className="custom-table">
                  <table className="custom-table-content table-topup-history-list">
                    <thead>
                      <tr>
                        <td className="col_name">Date</td>
                        <td className="col_message_type">Amount</td>
                        <td className="col_sender">Transaction ID</td>
                        <td>
                          <CustomSelectOption
                            optionListProps={listPageOption}
                            valueDropdownProps={listPageVal}
                            onClickDropDownListOptionProps={onSelectListPage}
                          />
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        topUpHistory.length > 0 ?
                          topUpHistory.map(item => {
                            return (
                              <tr key={`topup-${item.id}`} >
                                <td className="">
                                  <p className="">{HelperDate.formatToString(new Date(item.paymentTime), "dd MMM yyyy HH:mm")}</p>
                                </td>
                                <td className="">
                                  <p>{rupiahFormat(item.amount)}</p>
                                </td>
                                <td className="col-transaction">
                                  <input type="text"
                                    value={item.stringID}
                                    id={`copy-transaction-id-${item.stringID}`}
                                    readOnly
                                  />
                                  <FiCopy
                                    onClick={() => {
                                      copyToClip(`copy-transaction-id-${item.stringID}`, "Transaction ID copied")
                                    }}
                                  />
                                </td>
                                <td></td>
                              </tr>
                            )
                          })
                          :
                          <tr className="no-data-row">
                            <td colSpan="4" className="no-data-column"><b className="no-result-found">No top up history found.</b></td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
          }

          <div className="pagination-wrapper">
            {
              totalItems > 0 &&
                <Pagination
                  total={totalItems}
                  page={pageDisplay}
                  listPerPage={listPage}
                  step={1}
                  onChangePage={handleChangePage}
                />
            }
          </div>

        </div>

      </SectionWrap>
    </React.Fragment>
  );
};

export default TopUpHistory;