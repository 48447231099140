import React, { useState, useEffect } from "react";
import "./OrganizationDetails.scss";
import { connect } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import HelperChat from "../../../helper/HelperChat";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";

let OrganizationDetails = (props) => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [loadingOrganizationDetail, setLoadingOrganizationDetail] = useState(false);
    let [organizationDetailData, setOrganizationDetailData] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
  
    let goToEdit = () => {
        history.push(`/o/${orgID}/organization/details/edit`);
    }

    const doMount = async () => {
        const paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              const { id, createdTime } = responseData.memberships[0].organization;
              const urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/label`);
            } else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };

    let getOrganizationDetail = () => {
        setLoadingOrganizationDetail(true);
        let _retriveDataError = {...retriveDataError};

        ServiceOrganization.postGetOrganizationDetail(orgID, (response) => {
           let dataResult = response.dataResult;
      
            if(dataResult.error.message === "") {
                setOrganizationDetailData(dataResult.data.organization)
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setLoadingOrganizationDetail(false);
          });
    }

    let findCountry = (id) => {
        let country = false;

        if(props.countryList) {
            country = props.countryList[props.countryList.findIndex(val => val.id === id)];
        }

        return country;
    }

    useEffect(() => {
        if (props.history.location.state) {
          if (props.history.location.state.message) {
            doToast(props.history.location.state.message);
            window.history.pushState(null, '');
          }
        }
      }, [props.history])

    useEffect(() => {
        doMount();
    }, []);
    
    useEffect(() => {
        if (orgID) {
            getOrganizationDetail();
        }
    }, [orgID]);

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >    
            <div className="sectionWrap organization-details-container">
                <div className="topContent organization-details-top-content">
                    <div className="title title-organization-details">Organization Details</div>
                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40"
                        text="Edit"
                        onClickAction={goToEdit}
                        icon={{
                            type: "svg",
                            src: FiEdit
                        }}
                        position="left"
                    />
                </div>

                {loadingOrganizationDetail || retriveDataError.code ? 
                    <RetrieveDataLoading 
                        isLoading={loadingOrganizationDetail}
                        errorMessage={retriveDataError.message} 
                    />
                    :
                    <div className="organization-details-bottom-content">
                        <p className="organization-details-title">
                            <b>Organization Name</b>
                        </p>

                        <p className="organization-details-value">
                            <b>{organizationDetailData.name}</b>
                        </p>

                        <p className="organization-details-title">
                            <b>Time Zone</b>
                        </p>

                        <p className="organization-details-value">
                            <b>{organizationDetailData.timeZone}</b>
                        </p>

                        <p className="organization-details-title">
                            <b>Country</b>
                        </p>

                        <p className="organization-details-value">
                            <b>{organizationDetailData.countryID === 0 ? "Indonesia" : (findCountry(organizationDetailData.countryID) ? findCountry(organizationDetailData.countryID).commonName : "-")}</b>
                        </p>

                        <p className="organization-details-title">
                            <b>Region Name </b>
                        </p>

                        <p className="organization-details-value">
                            <b>{organizationDetailData.regionName !== "" ? organizationDetailData.regionName : "-"}</b>
                        </p>

                        <p className="organization-details-title">
                            <b>City Name</b>
                        </p>

                        <p className="organization-details-value">
                            <b>{organizationDetailData.cityName !== "" ? organizationDetailData.cityName : "-"}</b>
                        </p>

                        <p className="organization-details-title">
                            <b>Full Address</b>
                        </p>

                        <p className="organization-details-value">
                            <b dangerouslySetInnerHTML={{ __html: (organizationDetailData && organizationDetailData.fullAddress !== "") ?  HelperChat.lineBreakChatRoom(organizationDetailData.fullAddress) : "-" }} />
                        </p>

                        <p className="organization-details-title">
                            <b>Postal Code</b>
                        </p>

                        <p className="organization-details-value">
                            <b>{organizationDetailData.postalCode !== "" ? organizationDetailData.postalCode : "-"}</b>
                        </p>
                    </div>
                }
            </div>
        </SectionWrap>
    )
}

const mapDispatchToProps = {
    // setParentMenuBlockActive,
};

const mapStateToProps = state => ({
    countryList: state.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails);