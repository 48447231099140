import React from "react";
import './FilterBy.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiInfo, FiX } from 'react-icons/fi';
import IconFilterBlack from '../../../../assets/img/icon-filter-black.svg';

let FilterBy = (props) => {
    let onSelectSource = (value) => {
        props.onSelectSourceProps(value);
    }

    let onSelectStatus = (value) => {
        props.onSelectStatusProps(value);
    }

    let actionFilter = () => {
        props.updateFilterProps()
    }

    let onChangePhoneNumber = (number) => {
        props.onChangePhoneNumberProps(number)
    }

    return (
        <Modal 
            isOpen={props.isOpenProps} 
            toggle={() => {
                props.resetCurrentChanges(); 
                props.toggleProps();
            }} 
            className="case-modal-filter-option"
        >
            <ModalBody>
                <p className="modal-date-filter-title">
                    <img src={IconFilterBlack} alt="" />
                    <b>Filters</b>
                </p>

                <div className="case-filter-option-wrapper">
                    <label><b>User Phone Number</b></label>
                    <div className="input-container input-phone">
                        <p>+</p>
                        <input 
                            type="text" 
                            placeholder="628XXXXXXXXX" 
                            pattern="[0-9]*"
                            value={props.phoneNumberProps}
                            maxLength="15"
                            onChange={e => {
                                let number = (e.target.validity.valid) ? e.target.value : props.phoneNumberProps;
                                onChangePhoneNumber(number)
                            }}
                        />
                    </div>
                    <p className="input-info-wrapper">
                        <FiInfo />
                        <b>Add country code first e.g +62.</b>
                    </p>

                    <label><b>Source</b></label>
                    <CustomSelectOption
                        optionListProps={props.sourceListProps}
                        valueDropdownProps={props.filterSourceValueProps}
                        placeholderProps={"Please Select"}
                        onClickDropDownListOptionProps={onSelectSource}
                        specifiClassNameProps="custom-select-filter-option"
                    />


                    <label><b>Status</b></label>
                    <CustomSelectOption
                        optionListProps={props.statusListProps}
                        valueDropdownProps={props.filterStatusValueProps}
                        placeholderProps={"Please Select"}
                        onClickDropDownListOptionProps={onSelectStatus}
                        specifiClassNameProps="custom-select-filter-option"
                    />

                </div>
            </ModalBody>
            <ModalFooter>
                {!props.isHideClearSelection &&
                    <div className="clear-wrapper">
                        {
                            props.isActiveFilterProps &&
                            <div onClick={() => props.onClearSelectionProps()}>
                                <FiX />
                                <b>Clear Selection</b>
                            </div>
                        }
                    </div>
                }
                <div className="button-container">
                    <ButtonWithLoadingOrIcon
                        onClickAction={() => {
                            props.resetCurrentChanges(); 
                            props.toggleProps()
                        }}
                        className="no-fill-button main-button-40 cancel-filter"
                        text="Cancel"
                    />


                    {/* {props.filterSourceValueProps.value === "" && 
                    props.filterStatusValueProps.value === "" && 
                    props.phoneNumberProps === "" && 
                    !checkMessageTypesFilterIsOn() ?
                        <ButtonWithLoadingOrIcon
                            isDisabled={true}
                            className="orange-button main-button-40 apply-filter"
                            text="Apply Filters"
                        />
                        : */}
                        <ButtonWithLoadingOrIcon
                            onClickAction={actionFilter}
                            className="orange-button main-button-40 apply-filter"
                            text="Apply Filters"
                        />
                    {/* } */}
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default FilterBy;