import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import '../Auth.css';
import './ResetPassword.scss';
import logoTaptalk from '../../../assets/img/auth/logo-taptalk.svg'
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa";
import { Modal, ModalBody } from 'reactstrap';
import iconCheck from "../../../assets/img/icon-check-email.svg";
import { FiEye, FiEyeOff } from 'react-icons/fi'
import ServiceResetPassword from '../../../services/auth/ServiceResetPassword';
import taptalk from '../../../assets/img/taptalk-logo.png'
import HelperQueryString from "../../../helper/HelperQueryString";
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';

const style = {
  overflowY: {
    overflowY: "hidden"
  },
  passwordStrength: {
    fontSize: 0,
    paddingBottom: '22px'
  },
  marginBottomCustom: {
    marginBottom: "13px"
  },
  heightUnset: {
    height: "unset"
  },
  scrollStyle: {
    position: "relative",
    backgroundColor: "#dcdcdc",
    right: "-2px",
    borderRadius: "8px"
  }
};

const ResetPassword = () => {
  const [password, setPassword] = useState("")
  const [rePassword, setRePassword] = useState("")
  const [lowerCase, setLowerCase] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [number, setNumber] = useState(false)
  const [specialCharacter, setSpecialCharacter] = useState(false)
  const [minimumCharacter, setMinimumCharacter] = useState(false)
  const [isWaitForResponse, setIsWaitForResponse] = useState(false)
  const [popupModalPasswordChangeShow, setPopupModalPasswordChangeShow] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isTokenVerified, setIsTokenVerified] = useState(true)
  const [failedMessage, setFailedMessage] = useState("Failed Message")
  const history = useHistory();
  const [tokenData, setTokenData] = useState(null)

  const handleChange = event => {
    setRePassword(event.target.value)
  }

  const passwordStrengthCheck = () => {
    let passPasswordCheck;

    if (
      lowerCase && upperCase && number &&
      specialCharacter && minimumCharacter &&
      password === rePassword
    ) {
      passPasswordCheck = true;
    } else {
      passPasswordCheck = false;
    }

    return passPasswordCheck;
  }

  const handlePassword = event => {
    let value = event.target.value;
    setPassword(value)

    //lowercase
    if ((/[a-z]/.test(value))) {
      setLowerCase(true)
    } else {
      setLowerCase(false)
    }

    //uppercase
    if ((/[A-Z]/.test(value))) {
      setUpperCase(true)
    } else {
      setUpperCase(false)
    }

    //number
    if ((/[0-9]/.test(value))) {
      setNumber(true)
    } else {
      setNumber(false)
    }

    //special character
    if (!(/^[a-zA-Z0-9]*$/).test(value)) {
      setSpecialCharacter(true)
    } else {
      setSpecialCharacter(false)
    }

    //8 minimum character
    if (value.length > 7) {
      setMinimumCharacter(true)
    } else {
      setMinimumCharacter(false)
    }
  }

  const validateForm = () => {
    return passwordStrengthCheck()
  }

  const modalSuccessChangePassword = () => {
    return (
      <Modal isOpen={popupModalPasswordChangeShow} className={"popup-modal-change-password"}>
        <ModalBody>
          <p className="popup-modal-change-password-title">
            <img src={iconCheck} alt="" />
            <b>Password Changed</b>
          </p>

          <p className="popup-modal-change-password-description">
            Your password has been successfully changed.
          </p>

          <Link to="/">
            <ButtonWithLoadingOrIcon
              onClickAction={() => history.push('/')}
              className="orange-button main-button-48 width-100"
              text="Back to Login"
            />
          </Link>
        </ModalBody>
      </Modal>
    )
  }

  const [peekPassword, setPeekPassword] = useState(false)
  const [peekRetypePassword, setPeekRetypePassword] = useState(false)
  const peekPasswordClick = () => {
    setPeekPassword(!peekPassword)
  }
  const peekRetypePasswordClick = () => {
    setPeekRetypePassword(!peekRetypePassword)
  }

  const handleSetPassword = (e) => {
    e.preventDefault();
    setPopupModalPasswordChangeShow(true)
    setIsWaitForResponse(true)

    ServiceResetPassword.setPassword(password, tokenData).then(response => {
      setIsWaitForResponse(false)
      if (response.dataResult.status === 200) {
        if (response.dataResult.data.success) {
          setPopupModalPasswordChangeShow(true)
        }
      }
    })
  }

  const verifyToken = (data) => {
    setIsLoading(true)

    ServiceResetPassword.verifyToken(data).then(response => {
      setIsLoading(false)
      if (response.dataResult.status === 200) {
        if (!response.dataResult.data.isValid) {
          setIsTokenVerified(false)
          setFailedMessage(response.dataResult.data.message)
        } else {
          setIsTokenVerified(true)
        }
      } else {
        setIsTokenVerified(false)
        setFailedMessage(response.dataResult.error.message)
      }
    })
  }

  useEffect(() => {
    let qs = HelperQueryString.parseQuerystring();
    let valToken = qs["token"];

    if (valToken) {
      try {
        let tokenVerificationData = JSON.parse(
          atob(valToken)
        );

        setTokenData(tokenVerificationData)
        verifyToken(tokenVerificationData);
      } catch (e) {
        setIsLoading(false)
        setIsTokenVerified(false)
        setFailedMessage("Wrong Token Format")
      }
    } else {
      window.location.href = "/login";
    }
  }, [])

  const backToForgotPassword = () => {
    history.push('/forgot-password')
  }

  const isPasswordValid = () => lowerCase && upperCase && number && specialCharacter && minimumCharacter

  const handleSeekPassword = () => {
    if (isPasswordValid()) {
      peekRetypePasswordClick()
    } else {
      return
    }
  }

  return (
    <>
      {
        isLoading &&
        <div className="outer-verify-reset-password">
          <div className="loading-wrapper">
            <div className="lds-ring auth-lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      }
      {
        isTokenVerified ?
          <div className="outer-container">
            <div className="background-container">
              <div className="header-container">
                <img src={logoTaptalk} alt="TapTalk.io" />
                <p>SENDTALK</p>
              </div>
              <div className="form-container">
                <p className="form-title">Forgot Password</p>
                <form onSubmit={(e) => handleSetPassword(e)}>
                  <div className="input-wrapper" style={{ paddingBottom: '14px' }}>
                    <label><b>New Password</b></label>
                    <div className="popup-input-password-wrapper">
                      <input type={peekPassword ? "text" : "password"}
                        id="password"
                        onChange={handlePassword}
                        placeholder="Type new password"
                        style={style.marginBottomCustom}
                        autoComplete="false"
                        value={password}
                      />
                      <div className={`peek-password ${peekPassword ? 'active-eye' : ''}`}
                        onClick={() => peekPasswordClick()}
                      >
                        {peekPassword ?
                          <FiEye />
                          :
                          <FiEyeOff />
                        }
                      </div>
                    </div>
                  </div>

                  <div className="input-wrapper password-strength" style={style.passwordStrength}>
                    <div className="div50">
                      <div className="checkLowerCase">
                        {lowerCase ?
                          <FaCheckCircle size={17} color="#7EC82A" /> :
                          <FaRegCircle size={17} color="#A4A4A4" />
                        }
                                      One lowercase character
                                  </div>
                      <div className="checkUpperCase">
                        {upperCase ?
                          <FaCheckCircle size={17} color="#7EC82A" /> :
                          <FaRegCircle size={17} color="#A4A4A4" />
                        }
                                      One uppercase character
                                  </div>
                      <div className="checkNumber">
                        {number ?
                          <FaCheckCircle size={17} color="#7EC82A" /> :
                          <FaRegCircle size={17} color="#A4A4A4" />
                        }
                                      One Number
                                  </div>
                    </div>

                    <div className="div50">
                      <div className="checkSpecialCharacter">
                        {specialCharacter ?
                          <FaCheckCircle size={17} color="#7EC82A" /> :
                          <FaRegCircle size={17} color="#A4A4A4" />
                        }
                                      One special character
                                  </div>
                      <div className="checkMinimumCharacter">
                        {minimumCharacter ?
                          <FaCheckCircle size={17} color="#7EC82A" /> :
                          <FaRegCircle size={17} color="#A4A4A4" />
                        }
                                      8 minimum character
                                  </div>
                    </div>
                  </div>

                  <div className="input-wrapper">
                    <label><b>Re-type New Password</b></label>
                    <div className="popup-input-password-wrapper">
                      <input type={peekRetypePassword ? "text" : "password"}
                        id="rePassword"
                        onChange={handleChange}
                        placeholder="Enter password again"
                        autoComplete="false"
                        className="disabled-retype-password"
                        disabled={isPasswordValid() ? false : true}
                        value={rePassword}
                      />
                      <div className={`peek-password retype ${!isPasswordValid() ? 'disable-seek-password' : ''} ${peekRetypePassword ? 'active-eye' : ''}`}
                        onClick={handleSeekPassword}
                      >
                        {peekRetypePassword ?
                          <FiEye />
                          :
                          <FiEyeOff />
                        }
                      </div>
                    </div>
                    {(password !== rePassword && rePassword.length > 0) &&
                      <div className="input-warning">Password didn't match</div>
                    }
                  </div>
                  {/* } */}

                  {!isWaitForResponse ?
                    <ButtonWithLoadingOrIcon
                      className="orange-button main-button-48 width-100"
                      text="Change Password"
                      isDisabled={!validateForm()}
                    />
                    :
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingWhite
                      className="orange-button main-button-48 width-100"
                      text="Change Password"
                      position="left"
                      isDisabled={true}
                    />
                  }

                  {modalSuccessChangePassword()}
                </form>

                <div className="signup-here">
                  <b>Already have an account?
                  <Link to="/login" className="signup-link"
                    // gtmevent={JSON.stringify(Object.assign({...HelperGTag.basicParameter("SendTalk login")} ,{
                    //   event: "button-click-register",
                    //   source: "login"
                    // }))}
                    >
                      Login
                  </Link>
                  </b>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="outer-verify-reset-password">
            <div className="background-container">
              <div className="failed-wrapper">
                <img src={taptalk} alt="TapTalk.io" className="logo" />
                <div className="message-wrapper">
                  <b>Invalid Link</b>
                </div>
                <p className="font-root-medium">{failedMessage}</p>
                <button className="orange-button forgot-password" onClick={backToForgotPassword}>
                  <b>Go to Forgot Password</b>
                </button>
              </div>
            </div>
          </div>
      }
    </>
  )
};

export default ResetPassword;