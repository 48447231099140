import React, { useState, useEffect } from "react";
import "./OrganizationDetailsEdit.scss";
import { connect } from "react-redux";
import {    FAIL_TOAST } from "../../../../constants/general";
import { checkID, doToast } from "../../../../helper/HelperGeneral";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import CountryPicker from "../../../reuseableComponent/countryPicker/CountryPicker";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";

let OrganizationDetailsEdit = (props) => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [countryList, setCountryList] = useState(false);
    let [loadingEdit, setLoadingEdit] = useState(false);
    let [loadingOrganizationDetail, setLoadingOrganizationDetail] = useState(false);
    let [selectedCountry, setSelectedCountry] = useState({
        id: 1,
        label: "Indonesia"
    });
    let [organizationDetailData, setOrganizationDetailData] = useState(false);
    let [organizationDetailDataOrigin, setOrganizationDetailDataOrigin] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
  
    let goBack = () => {
        history.push(`/o/${orgID}/organization/details`);
    }

    const doMount = async () => {
        const paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              const { id, createdTime } = responseData.memberships[0].organization;
              const urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/label`);
            } else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };

    let getOrganizationDetail = () => {
        setLoadingOrganizationDetail(true);
        let _retriveDataError = {...retriveDataError};

        ServiceOrganization.postGetOrganizationDetail(orgID, (response) => {
           let dataResult = response.dataResult;
      
            if(dataResult.error.message === "") {
                setOrganizationDetailData(dataResult.data.organization);
                setOrganizationDetailDataOrigin(dataResult.data.organization);
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setLoadingOrganizationDetail(false);
          });
    }

    let editOrganizationDetail = () => {
        setLoadingEdit(true);
        // let _retriveDataError = {...retriveDataError};
        let _data = {...organizationDetailData};

        let data = {
            name: _data.name,
            countryID: _data.countryID,
            regionName: _data.regionName,
            cityName: _data.cityName,
            fullAddress: _data.fullAddress,
            postalCode: _data.postalCode
        };

        ServiceOrganization.postEditOrganization(orgID, data, (response) => {
            let dataResult = response.dataResult;
            if(dataResult.error.message === "") {
                if(dataResult.data.success) {
                    setOrganizationDetailDataOrigin(organizationDetailData);
                    doToast(dataResult.data.message);

                    setTimeout(() => {
                        window.location.href = `/o/${orgID}/organization/details`
                    }, 1000)
                }else {
                    doToast(dataResult.data.message, FAIL_TOAST);
                }
            }else {
                doToast(dataResult.error.message, FAIL_TOAST);
                setLoadingEdit(false);
            }

          });
    }

    let onChange = (e) => {
        let val = e.target.value;
        let id = e.target.id;
        let _data = {...organizationDetailData};
        _data[id] = val;
        setOrganizationDetailData(_data);
    }

    let isDisabled = (e) => {
        let isDisabled = false;
        let _data = {...organizationDetailData};
        let _dataOrigin = {...organizationDetailDataOrigin};

        if(JSON.stringify(_data) === JSON.stringify(_dataOrigin)) {
            isDisabled = true;
        }

        if(_data.name === "") {
            isDisabled = true;
        }

        return isDisabled;
    }

    useEffect(() => {
        doMount();
    }, []);
    
    useEffect(() => {
        if (orgID) {
            getOrganizationDetail();
        }
    }, [orgID]);

    useEffect(() => {
        if(props.countryList) {
            let newCountryList = [];
            props.countryList.map((value) => {
                let data = {
                    value: "",
                    label: "",
                    countryLabel: "",
                    countryFlag: "",
                    countryCodeNumber: ""
                }

                data.value = value.id;
                data.label = value.officialName;
                data.countryLabel = value.officialName;
                data.countryFlag = value.flagIconURL;
                data.countryCodeNumber = value.callingCode

                newCountryList.push(data);

                return null;
            })

            setCountryList(newCountryList)
        }
    }, [props.countryList])

    let onChangeSelectCountry = (title, val) => {
        let _data = {...organizationDetailData};
        let _selectedCountry = {...selectedCountry};
        _data.countryID = val.value;
        _selectedCountry = {
            id: val.value,
            label: val.label
        }
        setSelectedCountry(_selectedCountry);
        setOrganizationDetailData(_data);
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >   
            <BackButton text="Back to Organization Details" onClick={goBack} />

            <div className="sectionWrap organization-details-container">
                <div className="topContent organization-details-top-content">
                    <div className="title title-organization-details">Organization Details</div>
                    {!loadingEdit ?
                        <ButtonWithLoadingOrIcon
                            className="no-fill-button main-button-40"
                            text="Save Changes"
                            onClickAction={editOrganizationDetail}
                            position="left"
                            isDisabled={isDisabled()}
                        />
                        :
                        <ButtonWithLoadingOrIcon
                            className="no-fill-button main-button-40"
                            text="Save Changes"
                            position="left"
                            isLoading
                            loadingColor={"#ff7d00"}
                        />
                    }
                </div>
                
                {loadingOrganizationDetail || retriveDataError.code ? 
                    <RetrieveDataLoading 
                        isLoading={loadingOrganizationDetail}
                        errorMessage={retriveDataError.message} 
                    />
                    :
                    <div className="organization-details-bottom-content form-content-wrapper">
                        <p className="organization-details-title">
                            <b>Organization Name</b>
                        </p>

                        <input 
                            type="text" 
                            placeholder="Input organization name" 
                            value={organizationDetailData.name} 
                            onChange={(e) => onChange(e)}
                            id="name"
                        />

                        <p className="organization-details-title">
                            <b>Time Zone</b>
                        </p>

                        <p className="organization-details-value">
                            <b>Asia/Jakarta (UTC +07:00)</b>
                        </p>

                        <p className="organization-details-title">
                            <b>Country</b>
                        </p>

                        <CountryPicker
                            list={countryList}
                            placeholder={"Select Country"}
                            onChange={(title, val) => onChangeSelectCountry(title, val)}
                            selectedID={selectedCountry.id}
                            selectedCountryLabel={selectedCountry.label}
                        />

                        <p className="organization-details-title">
                            <b>Region Name </b> <span className="grey-font">(optional)</span>
                        </p>

                        <input 
                            type="text" 
                            placeholder="Input region name" 
                            value={organizationDetailData.regionName} 
                            id="regionName"
                            onChange={(e) => onChange(e)}
                        />

                        <p className="organization-details-title">
                            <b>City Name</b> <span className="grey-font">(optional)</span>
                        </p>

                        <input 
                            type="text" 
                            placeholder="Input city name" 
                            value={organizationDetailData.cityName}
                            id="cityName"
                            onChange={(e) => onChange(e)}
                        />

                        <p className="organization-details-title">
                            <b>Full Address</b> <span className="grey-font">(optional)</span>
                        </p>

                        <textarea 
                            placeholder="Input address" 
                            rows="4" 
                            value={organizationDetailData.fullAddress}
                            id="fullAddress"
                            onChange={(e) => onChange(e)}
                        />

                        <p className="organization-details-title">
                            <b>Postal Code</b>
                        </p>

                        <input 
                            type="text" 
                            placeholder="Input postal code" 
                            value={organizationDetailData.postalCode}
                            id="postalCode"
                            onChange={(e) => onChange(e)}
                        />
                    </div>
                }
            </div>
        </SectionWrap>
    )
}

const mapDispatchToProps = {
    // setParentMenuBlockActive,
};

const mapStateToProps = state => ({
    countryList: state.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetailsEdit);