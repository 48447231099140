import React, { Component } from "react";
import "./PopupPassword.css";
import { Modal, ModalBody } from "reactstrap";
import { FiEye, FiEyeOff, FiLock } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

class PopupPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupPasswordShow: this.props.showPopupPassword,
            valCurrentPassword: ""
        };
    }

    togglePopupPassword = () => {
        this.setState(prevState => ({
            popupPasswordShow: !prevState.popupPasswordShow,
            peekPassword: false
        }));

        this.props.onChangeShowPopupPassword();
    }

    peekPasswordClick = () => {
        this.setState(prevState => ({
            peekPassword: !prevState.peekPassword
        }))
    }

    handleChange = (e) => {
        this.setState({
            valCurrentPassword: e.target.value
        })
    }

    onClickSubmit = (e) => {
        e.preventDefault();

        if (!this.props.isWaitingResponseChangePasswordProps) {
            this.props.onClickSubmit(this.state.valCurrentPassword);
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.popupPasswordShow} toggle={this.togglePopupPassword} className="popup-password">
                    <ModalBody>
                        <form onSubmit={this.onClickSubmit}>
                            <p>
                                <FiLock /> Enter Current Password
                        </p>

                            <p>
                                In order to save these changes, please enter your current password.
                        </p>

                            <label>Password</label>
                            <div className="popup-input-password-wrapper">
                                <input type={this.state.peekPassword ? "text" : "password"}
                                    autoComplete="false"
                                    placeholder="Input your current password"
                                    onChange={(e) => this.handleChange(e)}
                                />

                                <div className="peek-password"
                                    onMouseDown={() => this.peekPasswordClick()}
                                    onMouseUp={() => this.peekPasswordClick()}
                                >
                                    {this.state.peekPassword ?
                                        <FiEye />

                                        :

                                        <FiEyeOff />
                                    }
                                </div>
                            </div>

                            <ButtonWithLoadingOrIcon
                                className="no-fill-button main-button-48 button-submit-change-password"
                                text="Cancel"
                                onClickAction={this.togglePopupPassword}
                            />

                            {!this.props.isWaitingResponseChangePasswordProps ?
                                <ButtonWithLoadingOrIcon
                                    className="orange-button main-button-48 button-submit-change-password"
                                    text="Confirm"
                                />
                                :
                                <ButtonWithLoadingOrIcon
                                    isLoading
                                    loadingWhite
                                    className="orange-button-disabled main-button-48 button-submit-change-password"
                                    text="Confirm"
                                    position="left"
                                />
                            }
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default PopupPassword;
