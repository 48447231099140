import React, { useState, useEffect } from "react";
import "./NewOrganization.scss";
import NewOrganizationHeader from "../newOrganizationHeader/NewOrganizationHeader";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceProfile from "../../../services/profile/ServiceProfile";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import HelperAuth from "../../../helper/HelperAuth";
import { doToast } from "../../../helper/HelperGeneral";
import { setParentMenuBlockActive } from '../../../redux/actions/reduxActionSideMenu';
import Select from "react-select";
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

const iconDropOrange =
  "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
const iconDropGrey =
  "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

let NewOrganization = (props) => {
  let [isWaitForResponse, setIsWaitForResponse] = useState(false);
  // let [countyDropdownShow, setCountyDropdownShow] = useState(false);
  // let [tooltipShow, setTooltipShow] = useState(false);
  let [valOrganization, setValOrganization] = useState({
    name: "",
    timeZone: "",
    countryID: "",
    regionName: "",
    cityName: "",
    fullAddress: "",
    postalCode: ""
  });
  let [isShowGoBackButton, setIsShowGoBackButton] = useState(false);
  let [finishGetProfile, setFinishGetProfile] = useState(false);
  let [timezoneList, setTimezoneList] = useState(false);
  let [countryList, setCountryList] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  let back = () => {
    props.setParentMenuBlockActive({
      activeMenuBlock: "home",
      submenuList: null
    })

    props.history.push(`/home`)
  }

  useEffect(() => {
    if (HelperAuth.userWasLoggedIn()) {
      getProfile();
    }
  }, [])

  useEffect(() => {
    if (finishGetProfile) {
      getTimezone()
    }
  }, [finishGetProfile])

  useEffect(() => {
    if (props.countryList) {
      let newCountryList = [];

      props.countryList.map((value) => {
        let data = {
          value: "",
          label: ""
        }

        data.value = value.id;
        data.label = value.officialName;

        newCountryList.push(data);

        return null;
      })

      setCountryList(newCountryList)
    }
  }, [props.countryList])

  // let toggleCountryDropdown = () => {
  //     setCountyDropdownShow(!countyDropdownShow);
  // }

  // let toggleTooltip = () => {
  //     setTooltipShow(!tooltipShow);
  // }

  let handleChange = (e) => {
    let _valOrganization = { ...valOrganization };
    _valOrganization[e.target.id] = e.target.value;
    setValOrganization(_valOrganization);
  }

  let onClickCreateOrganization = (e) => {
    e.preventDefault();

    setIsWaitForResponse(true);

    ServiceOrganization.postNewOrganization(valOrganization, (response) => {
      if (response.dataResult.error.code === "") {
        // window.location.href = window.location.origin + `/o/${response.dataResult.data.organization.id}-${response.dataResult.data.organization.createdTime}/select-tier`;
        // window.location.href = window.location.origin + `/home`

        window.location.href = window.location.origin + `/o/${response.dataResult.data.organization.id}-${response.dataResult.data.organization.createdTime}/home`;
      } else {
        setIsWaitForResponse(false);
        doToast(response.dataResult.error.message, 'fail');
      }
    })
  }

  let getProfile = () => {
    let _retriveDataError = { ...retriveDataError };

    ServiceProfile.postGetProfile((response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.error.message === "") {
        setFinishGetProfile(true);
        let data = response.dataResult.data;

        if (data.memberships.length > 0) {
          setIsShowGoBackButton(true);
        }

        if (!data.canCreateOrganization) {
          props.history.push(`/home`)
        }
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    })
  }

  let getTimezone = () => {
    let _retriveDataError = { ...retriveDataError };

    ServiceOrganization.postGetTimezone((response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.error.message === "") {
        let data = response.dataResult.data;
        let newTimezoneList = [];

        data.timeZones.map((value) => {
          let data = {
            value: "",
            label: ""
          }

          data.value = value.name;
          value.utcOffset = value.utcOffset[0] !== "-" ? `+${value.utcOffset}` : value.utcOffset;
          value.name = value.name.replace(/_/g, " ");
          data.label = `${value.utcOffset} ${value.name} (${value.abbrev})`;

          newTimezoneList.push(data);

          return null;
        })

        setTimezoneList(newTimezoneList);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    })
  }

  let onChangeSelect = (id, e) => {
    let _valOrganization = { ...valOrganization };
    _valOrganization[id] = e.value;
    setValOrganization(_valOrganization);
  }

  let validateForm = () => {
    let _valOrganization = { ...valOrganization };

    return (
      _valOrganization.name.length < 1 ||
      _valOrganization.timeZone === "" ||
      _valOrganization.countryID === ""
    )
  }

  let styleSelect = () => ({
    indicatorsContainer: provide => ({
      ...provide,
      height: "48px",
    }),
    valueContainer: provide => ({
      ...provide,
      height: "100%"
    }),
    clearIndicator: () => ({
      // display: "none"
    }),
    dropdownIndicator: (provided, { isFocused }) => ({
      ...provided,
      background: isFocused ? iconDropOrange : iconDropGrey,
      transition: "0.25s ease-in-out",
      color: "transparent !important",
      width: "24px",
      height: "24px",
      // transform: isFocused ? "rotate(-180deg)" : ""
    }),
    container: (provided) => ({
      ...provided,
      width: "450px"
    }),
    control: (provide, { isFocused }) => ({
      ...provide,
      padding: "0 5px",
      // height: "48px",
      borderRadius: "8px",
      border: isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important",
      boxShadow: "none !important",
      marginBottom: "24px",
      cursor: "pointer"
    }),
    indicatorSeparator: provide => ({ ...provide, display: "none" }),
    menu: provide => ({
      ...provide,
      borderRadius: "8px",
      boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
      overflow: "hidden"
    }),
    option: (provide, state) => {
      const { isSelected } = state;
      const checked = {
        color: "#ff7e00",
        content: '"✔"',
        position: "absolute",
        left: "0",
        top: "50%",
        transform: "translate(100%, -50%)"
      };
      return {
        ...provide,
        position: "relative",
        background: "#fff",
        padding: "12px 15px 12px 35px",
        cursor: "pointer",
        color: isSelected ? "#ff7e00" : "#121212",
        ":hover": { background: "#ffe7d0" },
        ":before": isSelected ? checked : null
      };
    }
  })

  return (
    <React.Fragment>
      <ToastContainer className="ToastContainer" />

      <NewOrganizationHeader />

      <div id="page-billing-organization">
        {retriveDataError.code ?
          <RetrieveDataLoading
            isLoading={false}
            errorMessage={retriveDataError.message}
          />
          :
          <React.Fragment>
            <form onSubmit={onClickCreateOrganization}>
              {isShowGoBackButton &&
                <BackButton text="Back to Home" onClick={back} />
              }

              <div id="organization-billing-header">
                <b>Organization</b>

                <div className="form-content-wrapper">
                  <label>Organization Name</label>
                  <input type="text"
                    placeholder="Input organization name here"
                    id="name"
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <label>Time zone</label>
                <Select
                  options={timezoneList}
                  placeholder="Select Time zone"
                  onChange={(e) => onChangeSelect("timeZone", e)}
                  styles={styleSelect()}
                />

                <label>Country</label>
                <Select
                  options={countryList}
                  placeholder="Select Country"
                  onChange={(e) => onChangeSelect("countryID", e)}
                  styles={styleSelect()}
                />
              </div>

              {/* <b>Payment Settings</b>

                            <label>Name On Card</label>
                            <input type="text" 
                                placeholder="Insert name on card"
                            />

                            <label>Card Number</label>
                            <input type="password" autoComplete="false"
                                placeholder="1234 4567 8910"
                            />

                            <div className="payment-div-50"> 
                                <label>Expiry</label>
                                <input type="password" autoComplete="false" 
                                    placeholder="mm/yyyy"
                                />
                            </div>

                            <div className="payment-div-50"> 
                                <label>
                                    CVV/CVC 
                                    <MdInfoOutline onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} />

                                    <div className="custom-tooltip" style={{display: tooltipShow ? "block" : "none"}}>
                                        CVV/CVC is a 3 digit number that is 
                                        located at the back of your card.
                                    </div>
                                </label>
                                <input type="password" autoComplete="false" 
                                    placeholder="3 digits"
                                />
                            </div>

                            <label>County</label>
                            <div className="country-dropdown-wrapper" onClick={toggleCountryDropdown}>
                                <div className="dropdown-value-wrapper">
                                    Indonesia

                                    <div className="arrow-down-wrapper">
                                        {countyDropdownShow ? 
                                            <MdExpandLess />
                                            :
                                            <MdExpandMore />
                                        }
                                    </div>
                                </div>

                                <div className="option-list-wrapper" style={{display: countyDropdownShow ? "inline-block" : "none"}}>
                                    <ul>
                                        <li>India</li>
                                        <li>Indonesia</li>
                                        <li>India</li>
                                        <li>Indonesia</li>
                                        <li>India</li>
                                        <li>Indonesia</li>
                                    </ul>
                                </div>
                            </div>

                            <label>
                                Address
                                <span className="span-optional">Optional</span>
                            </label>
                            <textarea rows="4" /> */}
              <div className="payment-setting-foot">
                {!isWaitForResponse ?
                  <ButtonWithLoadingOrIcon
                    isDisabled={validateForm()}
                    className="orange-button main-button-48"
                    text="Create Organization"
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    isLoading
                    loadingWhite
                    className="orange-button main-button-48"
                    text="Create Organization"
                    position="left"
                  />
                }
              </div>
            </form>
          </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  setParentMenuBlockActive,
};

const mapStateToProps = state => ({
  countryList: state.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOrganization)
