import React from "react";
import "./DateRangeButton.css";
import { FiCalendar } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import HelperDate from "../../../helper/HelperDate";

const formatDates = (filterDate) => {
  if(filterDate.from && filterDate.to && filterDate.from !== filterDate.to) {
    return `${HelperDate.formatToString(
      new Date(filterDate.from),
      "dd MMMM yyyy"
    )} - ${HelperDate.formatToString(
      new Date(filterDate.to),
      "dd MMMM yyyy"
    )}`
  } else if(filterDate.from || filterDate.to) {
    let date = filterDate.from || filterDate.to
    return `${HelperDate.formatToString(
      new Date(date),
      "dd MMMM yyyy"
    )}`
  } else return ""
}

const DateRangeButton = ({ onClick, isFilterDateActive, filterDate }) => {
  return (
    <div>
      {!isFilterDateActive ? (
        <ButtonWithLoadingOrIcon
          className="no-fill-button main-button-40 date-button"
          text="Date Range"
          icon={{
            type: "svg",
            src: FiCalendar,
          }}
          position="left"
          isDisabled={false}
          onClickAction={onClick}
        />
      ) : (
        <ButtonWithLoadingOrIcon
          className="orange-button main-button-40 date-button"
          text={formatDates(filterDate)}
          icon={{
            type: "svg",
            src: FiCalendar,
          }}
          position="left"
          isDisabled={false}
          onClickAction={onClick}
        />
      )}
    </div>
  );
};

export default DateRangeButton;
