import React, { useState, useEffect } from "react";
import "./WhatsappVerificationHistoryDetail.scss";
import { connect } from "react-redux";
import { checkID, doToast, rupiahFormat } from "../../../../helper/HelperGeneral";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import CopyBox from "../../../reuseableComponent/copyBox/CopyBox";
import IconDisable from "../../../../assets/img/icon-disabled.svg";
import IconEnable from "../../../../assets/img/icon-enabled.svg";
import IconWarning from "../../../../assets/img/icon-warning.svg";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ServiceWhatsapp from "../../../../services/whatsapp/ServiceWhatsapp";
import HelperDate from "../../../../helper/HelperDate";

let WhatsappVerificationHistoryDetail = (props) => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [loadingWhatsappDetail, setLoadingWhatsappDetail] = useState(false);
    let [whatsappDetailData, setWhatsappDetailData] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });

    let doMount = async () => {
        let paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              let { id, createdTime } = responseData.memberships[0].organization;
              let urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/label`);
            } else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };

    let getWhatsappDetail = () => {
        setLoadingWhatsappDetail(true);
        let _retriveDataError = {...retriveDataError};

        ServiceWhatsapp.postGetDetail(orgID, {stringID: props.match.params.id}, (response) => {
           let dataResult = response.dataResult;
      
            if(dataResult.error.message === "") {
                setWhatsappDetailData(dataResult.data.verification)
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setLoadingWhatsappDetail(false);
          });
    }

    useEffect(() => {
        if (props.history.location.state) {
          if (props.history.location.state.message) {
            doToast(props.history.location.state.message);
            window.history.pushState(null, '');
          }
        }
      }, [props.history])

    useEffect(() => {
        doMount();
    }, []);
    
    useEffect(() => {
        if (orgID) {
            getWhatsappDetail();
        }
    }, [orgID]);

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >    
            <BackButton 
                text="Back to Verification History"
                onClick={() => props.history.push(`/o/${orgID}/whatsapp-verification/verification-history`)}
            />

            <div className="sectionWrap whatsapp-verification-history-detail-container">
                <div className="topContent whatsapp-verification-history-detail-top-content">
                    <div className="title title-whatsapp-verification-history-detail">Verification History Details</div>
                </div>

                {loadingWhatsappDetail || retriveDataError.code ? 
                    <RetrieveDataLoading 
                        isLoading={loadingWhatsappDetail}
                        errorMessage={retriveDataError.message} 
                    />
                    :
                    <div className="whatsapp-verification-history-detail-bottom-content">
                        <>
                            <p className="whatsapp-verification-history-detail-title">
                                <b>Created Time</b>
                            </p>
                            <span className="history-value">
                                {HelperDate.formatToString(new Date(whatsappDetailData.createdTime), "dd MMM yyyy, HH:mm")}
                            </span>

                            <p className="whatsapp-verification-history-detail-title">
                                <b>User Phone Number</b>
                            </p>
                            <span className="history-value">
                                +{(whatsappDetailData.userPhone && whatsappDetailData.userPhone.substr(0, 2)) !== "62" ? "62" : ""}{whatsappDetailData.userPhone}
                            </span>

                            <p className="whatsapp-verification-history-detail-title">
                                <b>Source</b>
                            </p>
                            <span className="history-value">
                                {whatsappDetailData.source === "customer_dashboard" ?
                                    "Customer Dashboard"
                                    :
                                    "Integration API"
                                }
                            </span>
                            
                            <p className="whatsapp-verification-history-detail-title">
                                <b>Status</b>
                            </p>
                            <span className="history-value">
                                <p className="whatsapp-verification-history-detail-value">
                                    {whatsappDetailData.status === "verified" &&
                                        <b className="green-text"> 
                                            <img src={IconEnable} alt="" /> 
                                            Verified
                                        </b>
                                    }

                                    {whatsappDetailData.status === "failed" &&
                                        <b className="red-text"> 
                                            <img src={IconDisable} alt="" /> 
                                            Failed
                                        </b>
                                    }

                                    {whatsappDetailData.status === "unverified" &&
                                        <b className="yellow-text"> 
                                            <img src={IconWarning} alt="" /> 
                                            Unverified
                                        </b>
                                    }
                                </p>
                            </span>

                            <p className="whatsapp-verification-history-detail-title">
                                <b>Reason</b>
                            </p>
                            <span className="history-value">
                               {whatsappDetailData.reason === "" ? "-" : whatsappDetailData.reason}
                            </span>
                                
                            {whatsappDetailData.status !== "failed" &&
                                <>
                                    <p className="whatsapp-verification-history-detail-title">
                                        <b>Language</b>
                                    </p>
                                    <span className="history-value">
                                        {whatsappDetailData.languageName}
                                    </span>

                                    <p className="whatsapp-verification-history-detail-title">
                                        <b>Message</b>
                                    </p>
                                    <CopyBox 
                                        className=""
                                        id="message-box"
                                        val={whatsappDetailData.generatedMessage}
                                        copyText="Message Copied"
                                        isTextArea
                                    />  

                                    <p className="whatsapp-verification-history-detail-title">
                                        <b>Price</b>
                                    </p>
                                    <span className="history-value">
                                        {rupiahFormat(whatsappDetailData.price)}
                                    </span>

                                    <p className="whatsapp-verification-history-detail-title">
                                        <b>Verified Time</b>
                                    </p>
                                    <span className="history-value">
                                        {whatsappDetailData.verifiedTime === 0 ? "-" : HelperDate.formatToString(new Date(whatsappDetailData.verifiedTime), "dd MMM yyyy, HH:mm")}
                                    </span>

                                    <p className="whatsapp-verification-history-detail-title">
                                        <b>Webhook Sent Time</b>
                                    </p>
                                    <span className="history-value">
                                        {whatsappDetailData.webhookSentTime === 0 ? "-" : HelperDate.formatToString(new Date(whatsappDetailData.webhookSentTime), "dd MMM yyyy, HH:mm")}
                                    </span>
                                </>
                            }
                        </>
                    </div>
                }
            </div>
        </SectionWrap>
    )
}

let mapDispatchToProps = {
    // setParentMenuBlockActive,
};

let mapStateToProps = state => ({
    countryList: state.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatsappVerificationHistoryDetail);