import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const MessageServices = {
  getMessageHistory(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_MESSAGE_HISTORY, data, headers, callback);
  },
  sendMessageViaWhatsapp(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SEND_MESSAGE_WHATSAPP, data, headers, callback);
  },
  getPriceList(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_PRICE, {}, headers, callback);
  },
  exportMessage(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EXPORT_MESSAGE, data, headers, callback, 'blob');
  },
};

const API = {
  GET_MESSAGE_HISTORY: "/organization/message/get_sent_history",
  SEND_MESSAGE_WHATSAPP: "/organization/message/send_whatsapp",
  GET_PRICE: "/organization/message_type/get_price_list",
  EXPORT_MESSAGE: "/organization/message/export_sent_history"
}

export default MessageServices;
