import React, { Component } from 'react';
import './VerifyEmail.css';
import Logo from '../../../assets/img/taptalk-logo.png';
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import iconFailed from "../../../assets/img/icon-failed.png";
import iconCheck from "../../../assets/img/icon-check-email.svg";
import ServiceAuthRegister from '../../../services/auth/ServiceAuthRegister';
import HelperQueryString from "../../../helper/HelperQueryString";
import HelperGTag from "../../../helper/HelperGTag";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verifyEmailStatus: "process",
      verificationErrorMessage: "",
      emailIsVerified: false
    };
  }

  componentDidMount() {
    let qs = HelperQueryString.parseQuerystring();

    let valToken = qs["token"];

    if (valToken) {
      try {
        let accountVerificationData = JSON.parse(
          atob(valToken)
        );

        this.actionEmailVertificaton(accountVerificationData);
      } catch (e) {
        this.setState({
          verifyEmailStatus: "failed",
          verificationErrorMessage: "Wrong Token Format"
        })
      }
    } else {
      window.location.href = "/login";
    }
  }

  actionEmailVertificaton = (emailPayload) => {
    ServiceAuthRegister.postEmailVerification(emailPayload).then(response => {
      if (response.dataResult.data.success) {
        this.setState({
          verifyEmailStatus: "success"
        })

        window.dataLayer.push(
          Object.assign({ ...HelperGTag.basicParameter("SendTalk verify-email") }, {
            event: "action-email-verification-success",
            source: "verify-email"
          })
        )
      } else {
        this.setState({
          emailIsVerified: true,
          verifyEmailStatus: "failed",
          verificationErrorMessage: response.dataResult.data.message
        })
      }

      if (response.dataResult.error.code !== "") {
        this.setState({
          verifyEmailStatus: "failed",
          verificationErrorMessage: response.dataResult.error.message
        })
      }
    })
  }

  render() {
    return (
      <div className="outer-container">
        <div className="background-container">
          <div id="verify-email-wrapper">
            {this.state.verifyEmailStatus === "process" &&
              <Fade>
                <img src={Logo} alt="Tap Talk Logo" />
                <div className="process-loading">
                  <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                  <b>Verifying Email</b>
                </div>
                <p>
                  Please give it a few moment for us to verify your email address
              </p>
              </Fade>
            }

            {this.state.verifyEmailStatus === "failed" &&
              <Fade>
                <img src={Logo} alt="Tap Talk Logo" />
                <div className="verification-failed">
                  <img src={iconFailed} alt="" />
                  <b>Verification Failed</b>

                  {!this.state.emailIsVerified ?
                    <p>
                      {this.state.verificationErrorMessage}
                      <br />
                    Unfortunately we cannot verify your email right now.
                    <br />
                      <b>Please contact us at help@taptalk.io</b>
                    </p>
                    :
                    <p>
                      {this.state.verificationErrorMessage}
                    </p>
                  }

                  {this.state.emailIsVerified &&
                    <Link to="">
                      <ButtonWithLoadingOrIcon
                        className="orange-button main-button-48 width-100"
                        text="Login"
                      />
                    </Link>
                  }
                </div>
              </Fade>
            }

            {this.state.verifyEmailStatus === "success" &&
              <Fade>
                <img src={Logo} alt="Tap Talk Logo" />
                <div className="verification-success">
                  <div className="verification-success-label">
                    <img src={iconCheck} alt="" />
                    <b> Verification Success</b>
                  </div>

                  <p>
                    Congratulations! Your email has been verified. Login now with your verified email address.
                </p>

                  <Link to="">
                    <ButtonWithLoadingOrIcon
                      className="orange-button main-button-48 width-100"
                      text="Login"
                    />
                  </Link>
                </div>
              </Fade>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyEmail;
