import { combineReducers } from "redux";
import sideMenu from "./reduxReducerSideMenu";
import user from "./reduxReducerAuth";
import countryList from "./reduxReducerCountryList";
import activeOrgID from "./reduxReducerActiveOrgID";
import allOrganizationDetail from "./reduxReducerAllOrganizationDetail";
import previewImageOrVideo from "./reduxReducerPreviewImageOrVideo";
import isTrial from "./reduxReducerIsTrial";
import billing from "./reduxReducerBilling";

const appReducer = combineReducers({
  sideMenu,
  user,
  countryList,
  activeOrgID,
  allOrganizationDetail,
  previewImageOrVideo,
  isTrial,
  billing
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;