import React, { useEffect, useState } from "react";
import "./PreviewMessage.scss";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import { FiAlertCircle, FiEye, FiInfo } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { checkID, doToast, rupiahFormat } from "../../../helper/HelperGeneral";
import IconSend from "../../../assets/img/icon-send.svg";
import IconSendGray from "../../../assets/img/icon-send-gray.svg";
import iconDeliv from "../../../assets/img/icon-deliv.svg";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Loading from "../../reuseableComponent/loading/Loading";
import MessageServices from "../../../services/messages/ServiceMessage";
import bgWa from "../../../assets/img/whatsapp-bg.png";
import { MESSAGE_TYPE, REASON_SEND_MESSAGE_FAILED } from "../../../constants/message";
import { SUCCESS_TOAST } from "../../../constants/general";
import Helper from "../../../helper/HelperChat";
import { connect } from 'react-redux';

const PreviewMessage = (props) => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  const [isShowModalSending, setIsShowModalSending] = useState(false);
  const [isShowModalConfirmation, setIsShowModalConfirmation] = useState(false);
  const [isCheckAgree, setIsCheckAgree] = useState(false);
  const [message, setMessage] = useState("");
  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          // const { id, createdTime } = responseData.memberships[0].organization;
          // const urlOrgID = `${id}-${createdTime}`;
          // history.push(`/o/${urlOrgID}/api-key/general`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if(props.history.location.state) {
      doMount();
      
      let _loc = {...location};

      if(_loc.state && _loc.state.message) {
        setMessage(_loc.state.message);
      }
    }else {
      props.history.push(`/o/${props.match.params.orgID}/message/send-message`)
    }
  }, []);

  const onCheckAgree = (e) => {
    setIsCheckAgree(e.target.checked);
  };

  const getMessage = () => {
    if (location.state && location.state.message) {
      return Helper.convertFormatTextWhatsapp(message);
    } else {
      return "";
    }
  };

  const getPhoneNumber = () => {
    if (location.state && location.state.phoneNumber) {
      return location.state.phoneNumber;
    } else {
      return "";
    }
  };
  
  const getImage = () => {
    if (location.state && location.state.image) {
      return location.state.image;
    } else {
      return "";
    }
  };

  const getMessageType = () => {
    if (location.state && location.state.messageType) {
      return location.state.messageType;
    } else {
      return "";
    }
  };

  const getFileName = () => {
    if (location.state && location.state.fileName) {
      return location.state.fileName;
    } else {
      return "";
    }
  };

  const isSendDisabled = () => {
    switch (getMessageType()) {
      case MESSAGE_TYPE.text.val:
        return !getMessage() || !getPhoneNumber() || !isCheckAgree;
      case MESSAGE_TYPE.image.val:
        return !getImage() || !getPhoneNumber() || !isCheckAgree;
      default:
        return false;
    }
  };

  const toggleSending = () => {
    setIsShowModalSending(!isShowModalSending);
  };

  const toggleConfirmation = () => {
    setIsShowModalConfirmation(!isShowModalConfirmation);
  };

  const back = (error = "") => {
    props.history.push({
      pathname: `/o/${orgID}/message/send-message`,
      state: {
        message: location.state.message,
        phoneNumber: getPhoneNumber(),
        image: getImage(),
        messageType: getMessageType(),
        fileName: getFileName(),
        error: error
      },
    });
  };

  const getPrice = (type) => {
    let idx = "";
    let priceList = props.location.state.priceList;
    
    idx = priceList.findIndex(val => val.messageType === type.toLowerCase());

    return idx !== -1 ? priceList[idx].price - priceList[idx].discountAmount : 0;
  }

  const modalSending = () => {
    return (
      <Modal
        isOpen={isShowModalSending}
        toggle={toggleSending}
        className="modal-sending-message"
      >
        <ModalBody>
          <Loading />
          <p>Sending... Please wait.</p>
        </ModalBody>
      </Modal>
    );
  };

  const modalConfirmation = () => {
    let amount = 0;
    
    amount = getPrice(getMessageType());
    
    return (
      <Modal
        isOpen={isShowModalConfirmation}
        className="modal-send-message-confirmation"
      >
        <ModalBody>
          <p className="confirmation-title">
            <FiInfo />
            Send Message?
          </p>
          <p className="confirmation-description">
            {`You will be charged ${rupiahFormat(amount)} for this message`}
          </p>
        </ModalBody>
        <ModalFooter>
          <button className="no-fill-button" onClick={toggleConfirmation}>
            Cancel
          </button>
          <button
            className="orange-button"
            onClick={() => {
              toggleConfirmation();
              sendMessage();
            }}
          >
            Continue
          </button>
        </ModalFooter>
      </Modal>
    );
  };

  const sendMessage = () => {
    setIsShowModalSending(true);

    let data = {};

    switch (getMessageType()) {
      case MESSAGE_TYPE.text.val:
        data = {
          phone: getPhoneNumber(),
          messageType: MESSAGE_TYPE.text.val.toLowerCase(),
          body: message,
        };
        break;
      case MESSAGE_TYPE.image.val:
        data = {
          phone: getPhoneNumber(),
          messageType: MESSAGE_TYPE.image.val.toLowerCase(),
          body: getImage(),
          caption: message,
          filename: getFileName(),
        };
        break;
      default:
        data = {
          phone: getPhoneNumber(),
          messageType: MESSAGE_TYPE.text.val.toLowerCase(),
          body: message,
        };
        break;
    }

    MessageServices.sendMessageViaWhatsapp(orgID, data, (response) => {
      setIsShowModalSending(false);

      if (response.dataResult.status === 200) {
        if (response.dataResult.data.success) {
          setTimeout(() => {
            doToast(response.dataResult.data.message, SUCCESS_TOAST)
          }, 0);
          history.push(`/o/${orgID}/message/send-message`);
        } else {
          switch (response.dataResult.data.reason) {
            case REASON_SEND_MESSAGE_FAILED.insufficientBalance:
            case REASON_SEND_MESSAGE_FAILED.invalidRecipient:
              back(response.dataResult.data.message)
              break;
          
            default:
              doToast(response.dataResult.data.message, "fail");
              break;
          }
        }
      } else {
        doToast(
          response.dataResult.error.message
        , "fail");
      }
    });
  };

  return (
    <React.Fragment>
      {props.location.state &&
        <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
          {modalSending()}
          {modalConfirmation()}
          <BackButton text="Back to Send Message" onClick={() => back()} />
          <div className="sectionWrap preview-message-main-wrapper">
            <div className="topContent header-container">
              <div className="preview-message-header-title">
                <FiEye />
                Preview Message
              </div>

              {isSendDisabled() ? (
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40"
                  text="Send Message"
                  icon={{
                    type: "img",
                    src: IconSendGray,
                  }}
                  position="left"
                  isDisabled
                />
              ) : isShowModalSending ? (
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40"
                  text="Send Message"
                  position="left"
                  isDisabled
                  isLoading
                  loadingColor="gray"
                />
              ) : (
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40"
                  text="Send Message"
                  icon={{
                    type: "img",
                    src: IconSend,
                  }}
                  position="left"
                  onClickAction={toggleConfirmation}
                />
              )}
            </div>

            {props.isTrial &&
              <div className="alert-component">
                <FiAlertCircle />
                <b>
                  You're still on trial. The message you sent will include watermark to prevent misuse.
                </b>
              </div>
            }

            <div className="custom-checkbox">
              <input
                type="checkbox"
                id={`checkbox-agree`}
                checked={isCheckAgree}
                onChange={(e) => onCheckAgree(e)}
              />

              <label htmlFor="checkbox-agree">
                I agree and understand to not send any promotional or
                marketing blast and by doing so, will result in account
                suspension.
              </label>
            </div>

            <div className="preview-message-content-wrapper">
              <div
                className="preview-container"
                style={{ background: `url(${bgWa})` }}
              >
                <div className="block-header-container">
                  <div className="block-inner"></div>
                </div>
                {getMessageType() === MESSAGE_TYPE.text.val && (
                  <div className="block-chat-container">
                    <div 
                      className="chat-wrapper"
                      dangerouslySetInnerHTML={{
                        __html:(getMessage())
                      }}
                    />

                    {props.isTrial &&
                      <div className="chat-wrapper">
                        <br />
                        <br />
                        ---
                        <br />
                        <i>
                          This test message is sent from [SendTalk by TapTalk.io]
                        </i>
                      </div>
                    }

                    <div className="send-info-wrapper">
                      <p>17:06</p>
                      <img src={iconDeliv} alt="" />
                    </div>
                  </div>
                )}
                {getMessageType() === MESSAGE_TYPE.image.val && (
                  <div className="block-chat-container">
                    <div className="chat-image-wrapper">
                      <img src={getImage()} alt="" />
                    </div>
                    <div 
                      className="chat-wrapper"
                      dangerouslySetInnerHTML={{
                        __html:(getMessage())
                      }}
                    />

                    {props.isTrial &&
                      <div className="chat-wrapper">
                        {(getMessageType() === MESSAGE_TYPE.image.val && location.state.message !== "") &&
                          <>
                            <br />
                            <br />
                          </>
                        }
                        ---
                        <br />
                        <i>
                          This test message is sent from [SendTalk by TapTalk.io]
                        </i>
                      </div>
                    }
                    
                    <div className="send-info-wrapper">
                      <p>17:06</p>
                      <img src={iconDeliv} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </SectionWrap>
      }
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isTrial: state.isTrial,
});

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewMessage);
