import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const BillingServices = {
  getTopupHistory(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_TOPUP_HISTORY, data, headers, callback);
  },

  getTopupHistoryPending(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_TOPUP_PENDING, data, headers, callback);
  },

  postTopuNow(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.CREATE_TOPUP, data, headers, callback);
  },

  getCurrentBalance(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CURRENT_BALANCE, {}, headers, callback);
  },

  postGetLowBalance(idOrTime, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_LOW_BALANCE, {}, headers, callback);
  },

  postDeleteLowBalance(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.DELETE_LOW_BALANCE, data, headers, callback);
  },

  postAddLowBalance(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.ADD_LOW_BALANCE, data, headers, callback);
  },

  postEditLowBalance(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_LOW_BALANCE, data, headers, callback);
  }
};

const API = {
  GET_TOPUP_HISTORY: "/organization/billing/balance/get_topup_history",
  GET_CURRENT_BALANCE: "/organization/billing/balance/get_current",
  GET_LOW_BALANCE: '/organization/billing/balance/get_low_balance_reminders',
  ADD_LOW_BALANCE: '/organization/billing/balance/add_low_balance_reminder',
  EDIT_LOW_BALANCE: '/organization/billing/balance/edit_low_balance_reminder',
  DELETE_LOW_BALANCE: '/organization/billing/balance/delete_low_balance_reminder',
  GET_TOPUP_PENDING: "/organization/billing/balance/get_pending_topup_requests",
  CREATE_TOPUP: "/organization/billing/balance/create_topup_request"
}

export default BillingServices;
