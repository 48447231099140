export class HelperInput {
    emailFormatValid(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email); 
    }

    hourMinuteFormat(string) {
        let re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        return re.test(string);
    }

    numberOnly(value) {
        let re = /^[0-9]*$/;
        return re.test(value);
    }

    validURL(value) {
        let regExp = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  
        return regExp.test(value);
    }

    alphanumericAndUnderscoreOnly(value) {
        let regExp = /^\w*$/;
        return regExp.test(value.toLowerCase());
    }

    alphanumericOnly(value) {
        let regExp = /^[a-zA-Z0-9]*$/;
        return regExp.test(value.toLowerCase());
    }

    alphanumericAndSpaceOnly(value) {
        let regExp = /^[\w\-\s]+$/;
        return regExp.test(value);
    }

    noSpace(value) {
        var regexp = /^[a-z\d\-_\s]+$/i;
        return regexp.test(value);
    }
}

export default new HelperInput();

