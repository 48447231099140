import React, { useEffect, useState } from 'react';
import './LowBalance.scss'
import { FiAlertCircle } from "react-icons/fi";
import { numberWithCommas, doToast, checkID } from "../../../helper/HelperGeneral";
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap';
import BillingServices from '../../../services/billing/ServiceBilling';
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import RetrieveDataLoading from '../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading';
import { SUCCESS_TOAST } from '../../../constants/general';

const LowBalance = (props) => {
  let [orgID, setOrgID] = useState(false);
  let [isFirst, setIsFirst] = useState(false);
  let [lowBalanceOrigin, setLowBalanceOrigin] = useState({
    isEnabled: false,
    balanceThreshold: "",
    id: 0
  });
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [waitForResponseSubmit, setWaitForResponseSubmit] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  let [val, setVal] = useState({
    isEnabled: false,
    balanceThreshold: "",
    id: 0
  })

  const doMount = async () => {
    const paramID = checkID(props.match);
    if(paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if(responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/home`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if(orgID) {
      getLowBalanceData();
    }
  }, [orgID])

  let onToggle = (e) => {
    let _val = {...val};
    _val.isEnabled = e.target.checked;
    setVal(_val);
  }

  let onClickCancel = () => {
    setVal(lowBalanceOrigin)
  }

  let isDisabled = () => {
    let dis = false;
    let _lowBalanceOrigin = {...lowBalanceOrigin};
    let _val = {...val};
    // let _nom = _val.balanceThreshold;
    
    // _lowBalanceOrigin.balanceThreshold = _lowBalanceOrigin.balanceThreshold === "" ? "" : parseInt(_lowBalanceOrigin.balanceThreshold);
    // _val.balanceThreshold = _val.balanceThreshold === "" ? "" : _val.balanceThreshold;

    delete _val.createdTime;
    delete _val.updatedTime;
    delete _lowBalanceOrigin.createdTime;
    delete _lowBalanceOrigin.updatedTime;
    
    if(JSON.stringify(_lowBalanceOrigin) === JSON.stringify(_val) || _val.balanceThreshold === "") {
      dis = true;
    }

    return dis;
  }

  let onChangeValue = (e) => {
    let _val = {...val};

    let reg = /^\d+$/;
    let _nom = e.target.value;
    _nom = _nom.split(".").join("");

    if((reg.test(_nom) && _nom[0] !== 0) || _nom === "") {
      _val.balanceThreshold = _nom === "" ? _nom : numberWithCommas(parseInt(_nom));
      setVal(_val);
    }
  }

  let getLowBalanceData = () => {
    setWaitForResponseGetList(true);
    let _retriveDataError = { ...retriveDataError };

    BillingServices.postGetLowBalance(orgID, response => {
      let dataResult = response.dataResult;

      if(dataResult.error.message === "") {
        setIsFirst(dataResult.data.reminders.length === 0);
        
        if(dataResult.data.reminders.length > 0) {
          dataResult.data.reminders[0].balanceThreshold = dataResult.data.reminders[0].balanceThreshold === "" ? "" : numberWithCommas(dataResult.data.reminders[0].balanceThreshold);
          setLowBalanceOrigin(dataResult.data.reminders[0])
          setVal(dataResult.data.reminders[0])
        }else {
          setLowBalanceOrigin({
            currency: "IDR",
            balanceThreshold: "",
            isEnabled: false
          })
          setVal({
            currency: "IDR",
          balanceThreshold: "",
          isEnabled: false
          })
        }
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  let addLowBalance = () => {
    let _val = {...val};
    let nom = _val.balanceThreshold;
    nom = nom.split(".").join("");

    let data = {
      currency: "IDR",
      balanceThreshold: parseInt(nom),
      isEnabled: val.isEnabled
    }

    setWaitForResponseSubmit(true);

    BillingServices.postAddLowBalance(orgID, data, response => {
      let dataResult = response.dataResult;

      if(dataResult.error.message === "") {
        if(dataResult.data.success) {
          dataResult.data.reminder.balanceThreshold = dataResult.data.reminder.balanceThreshold === "" ? "" : numberWithCommas(dataResult.data.reminder.balanceThreshold);
        
          setVal(dataResult.data.reminder);
          setLowBalanceOrigin(dataResult.data.reminder);
          setIsFirst(false);
          doToast(dataResult.data.message, SUCCESS_TOAST);
        }else {
          doToast(dataResult.data.message, "fail");
        }

      } else {
        doToast(dataResult.error.message, "fail");
      }

      setWaitForResponseSubmit(false);
    })
  }

  let editLowBalance = () => {
    setWaitForResponseSubmit(true);
    let _val = {...val};
    let nom = _val.balanceThreshold;
    nom = nom.split(".").join("");

    let data = {
      id: val.id,
      balanceThreshold: parseInt(nom),
      isEnabled: val.isEnabled
    }


    BillingServices.postEditLowBalance(orgID, data, response => {
      let dataResult = response.dataResult;

      if(dataResult.error.message === "") {
        if(dataResult.data.success) {
          dataResult.data.reminder.balanceThreshold = dataResult.data.reminder.balanceThreshold === "" ? "" : numberWithCommas(dataResult.data.reminder.balanceThreshold);
        
          setLowBalanceOrigin(dataResult.data.reminder);
          doToast(dataResult.data.message, SUCCESS_TOAST);
        }else {
          doToast(dataResult.data.message, "fail");
        }
      } else {
        doToast(dataResult.error.message, "fail");
      }

      setWaitForResponseSubmit(false);
    })
  }

  let confirmLowBalance = () => {
    if(isFirst) {
      addLowBalance();
    }else {
      editLowBalance();
    }
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap low-balance-main-wrapper">
          <div className="topContent ">
            <div className="low-balance-header-title">Low Balance Alert</div>
          </div>
          
          {waitForResponseGetList || retriveDataError.code ?
              <RetrieveDataLoading
                isLoading={waitForResponseGetList}
                errorMessage={retriveDataError.message}
              />
              :
            <div className="form-content-wrapper">
              <label>
                <b>Enable Low Balance Alert</b>
              </label>
              <div className="value-wrapper"> 
                <p>Receive an email when your balance drops below threshold</p>

                <label className="custom-switch-wrapper">
                    <input type="checkbox" checked={val.isEnabled} onChange={(e) => onToggle(e)} placeholder="0" />
                    <span className="custom-switch round"></span>
                </label>
              </div>
              
              <label>
                <b>When my balance below</b>
              </label>
              <div className="value-wrapper">
                <span className="span-rp">Rp</span>
                <input type="text" value={val.balanceThreshold} onChange={(e) => onChangeValue(e)} />
              </div>

              <div className="alert-low-balance">
                <FiAlertCircle />
                <b>The low balance alert will be sent to your organization's owner and register billing notification recipients</b>
              </div>

              <br />
              
              <ButtonWithLoadingOrIcon 
                text="Cancel"
                className="no-fill-button main-button-40"
                onClickAction={onClickCancel}
              />
              
              {waitForResponseSubmit ?
                <ButtonWithLoadingOrIcon 
                  text="Save Changes"
                  className="orange-button main-button-40"
                  isLoading
                  loadingWhite
                  position="left"
                />
                :
                <ButtonWithLoadingOrIcon 
                  text="Save Changes"
                  className="orange-button main-button-40"
                  onClickAction={confirmLowBalance}
                  isDisabled={isDisabled()}
                />  
              }
            </div>
          }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default LowBalance;