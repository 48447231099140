import React, { useState, useEffect } from "react";
import "./WhatsappVerificationHistory.scss";
import { connect } from "react-redux";
import { FiDownload, FiCalendar, FiEye, FiX } from "react-icons/fi";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import HelperDate from "../../../helper/HelperDate";
import HelperInput from "../../../helper/HelperInput";
import { NavLink } from "react-router-dom";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import Pagination from "../../reuseableComponent/pagination/Pagination";
import FilterBy from "./filter/FilterBy";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CustomSelectOption from "../../reuseableComponent/customSelectOption/CustomSelectOption";
import CalendarPicker from '../../reuseableComponent/calendarPicker/CalendarPicker';
import IconCheck from "../../../assets/circle-check.svg";
import IconFailed from "../../../assets/circle-failed.svg";
import IconInfo from "../../../assets/circle-info.svg";
import IconFilterOrange from "../../../assets/img/icon-filter-orange.svg";
import IconFilterWhite from "../../../assets/img/icon-filter-white.svg";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceWhatsapp from "../../../services/whatsapp/ServiceWhatsapp";

let WhatsappVerificationHistory = (props) => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [loadingWhatsappHistory, setLoadingWhatsappHistory] = useState(true);
    let [whatsappHistory, setWhatsappHistory] = useState(false);
    let [totalData, setTotalData] = useState(0);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [listPageVal, setListPageVal] = useState({
        label: '20',
        value: 20,
    });
    let [page, setPage] = useState(1);
    let [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false);
    let [filterDate, setFilterDate] = useState({
        from: undefined,
        to: undefined,
    });
    let [filterDateBefore, setFilterDateBefore] = useState({
        from: undefined,
        to: undefined,
    });
    let [isFilterDateActive, setIsFilterDateActive] = useState(false);
    let [isOpenModalFilter, setIsOpenModalFilter] = useState(false);
    let [filterData, setFilterData] = useState({
        phone: "",
        source: {
            label: "All",
            value: ""
        },
        status: {
            label: "All",
            value: ""
        }
    })
    let [filterDataBefore, setFilterDataBefore] = useState({
        phone: "",
        source: {
            label: "All",
            value: ""
        },
        status: {
            label: "All",
            value: ""
        }
    })
    let [isActiveFilter, setIsActiveFilter] = useState(false);
    let [isLoadingGenerateReport, setIsLoadingGenerateReport] = useState(false);
    let [filterCount, setFilterCount] = useState(0);

    let toggleModalCalendar = () => {
        setIsOpenModalCalendar(!isOpenModalCalendar);
    };

    let toggleModalFilter = () => {
        setIsOpenModalFilter(!isOpenModalFilter);
    }

    let listPageOption = [
        {
            label: '20',
            value: 20,
        },
        {
            label: '50',
            value: 50,
        },
        {
            label: '100',
            value: 100,
        },
    ]

    let doMount = async () => {
        let paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              let { id, createdTime } = responseData.memberships[0].organization;
              let urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/label`);
            } else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };

    let getWhatsappHistory = (_page = false, _listPage = false, _date = false) => {
        setLoadingWhatsappHistory(true);

        let _retriveDataError = {...retriveDataError};

        let data = {
            "userPhone": filterData.phone,
            "status": filterData.status.value,
            "source": filterData.source.value,
            "startDate": _date ? _date.from : (filterDate.from ? HelperDate.formatToString(new Date(filterDate.from), "yyyy-MM-dd") : ""),
            "endDate": _date ? _date.to : (filterDate.to ? HelperDate.formatToString(new Date(filterDate.to), "yyyy-MM-dd") : ""),
            "pageNumber": _page ? _page : page,
            "pageSize": _listPage ? _listPage : listPageVal.value
        }

        ServiceWhatsapp.postGetHistory(orgID, data, (response) => {
           let dataResult = response.dataResult;
      
            if(dataResult.error.message === "") {
                setWhatsappHistory(dataResult.data.verifications);
                setTotalData(dataResult.data.totalItems);
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setLoadingWhatsappHistory(false);
        });
    }

    useEffect(() => {
        if (props.history.location.state) {
          if (props.history.location.state.message) {
            doToast(props.history.location.state.message);
            window.history.pushState(null, '');
          }
        }
      }, [props.history])

    useEffect(() => {
        doMount();
    }, []);
    
    useEffect(() => {
        if (orgID) {
            getWhatsappHistory();
        }
    }, [orgID]);

    let onSelectListPage = (val) => {
        setListPageVal(val);
        setPage(1);
        getWhatsappHistory(1, val.value);
    }
    
    let submitDateFilter = () => {
        if(filterDate.from) {
            setIsFilterDateActive(true);
        }

        setFilterDateBefore({...filterDate});
        // setFilterDateValue(filterDate);
        toggleModalCalendar();
        setPage(1);
        getWhatsappHistory(1);
    }
    
    let handleDateFilterClick = (date) => {
        setFilterDate(date);
    }
    
    let onClearDatePicker = () => {
        setIsFilterDateActive(false)
        setIsOpenModalCalendar(false)
        setFilterDate({
            from: undefined,
            to: undefined
        });
       
        if (isFilterDateActive) {
          getWhatsappHistory(1, false, {from: "", to: ""});
        }
        setPage(1)
    }

    let resetCurrentChanges = () => {
        setFilterData(filterDataBefore);
    }

    let onChangePhoneNumber = (val) => {
        let _filterData = {...filterData};
        if(HelperInput.numberOnly(val)) {
            _filterData.phone = val;
        }

        setFilterData(_filterData);
    }

    let onChangeSource = (e) => {
        let _filterData = {...filterData};
        _filterData.source = e;
        setFilterData(_filterData);
    }

    let onChangeStatus = (e) => {
        let _filterData = {...filterData};
        _filterData.status = e;
        setFilterData(_filterData);
    }

    let runUpdateFilter = () => {
        let ct = 0;
        setPage(1);
        toggleModalFilter();
        setFilterDataBefore(filterData);
        getWhatsappHistory(1);
        setIsActiveFilter(true);

        if(filterData.phone !== "") {
            ct = ct + 1;
        }

        if(filterData.source.value !== "") {
            ct = ct + 1;
        }

        if(filterData.status.value !== "") {
            ct = ct + 1;
        }

        setFilterCount(ct);
    }

    let onClearSelection = () => {
        toggleModalFilter();
        setFilterCount(0);
        setFilterData({
            phone: "",
            source: {
                label: "",
                value: ""
            },
            status: {
                label: "",
                value: ""
            }
        })
        setPage(1);
        setIsActiveFilter(false);

        setLoadingWhatsappHistory(true);

        let _retriveDataError = {...retriveDataError};

        let data = {
            "recipientPhone": "",
            "status": "",
            "source": "",
            "startDate": filterDate.from ? HelperDate.formatToString(new Date(filterDate.from), "yyyy-MM-dd") : "",
            "endDate": filterDate.to ? HelperDate.formatToString(new Date(filterDate.to), "yyyy-MM-dd") : "",
            "pageNumber": 1,
            "pageSize": listPageVal.value
        }

        ServiceWhatsapp.postGetHistory(orgID, data, (response) => {
           let dataResult = response.dataResult;
      
            if(dataResult.error.message === "") {
                setWhatsappHistory(dataResult.data.verifications);
                setTotalData(dataResult.data.totalItems);
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setLoadingWhatsappHistory(false);
        });
    }

    let downloadReport = (data) => {
        // let id = orgID.split('-');
        // let timestamp = + new Date();
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `whatsapp-verification-history.csv`);
        document.body.appendChild(link);
        link.click();
      }

    let onClickExport = () => {
        setIsLoadingGenerateReport(true);

        let data = {
            "userPhone": filterData.phone,
            "status": filterData.status.value,
            "source": filterData.source.value,
            "startDate": filterDate.from ? HelperDate.formatToString(filterDate.from, "yyyy-MM-dd") : "",
            "endDate": filterDate.to ? HelperDate.formatToString(filterDate.to, "yyyy-MM-dd") : "",
        };
        
        ServiceWhatsapp.postExport(
            orgID,
            data,
            (response) => {
                setIsLoadingGenerateReport(false);
        
                if (!response) {
                    // setShowModalFailed(true);
                } else {
                    downloadReport(response.dataResult)
                }
            }
        )
    }

    let handleChangePage = (v) => {
        setPage(v);

        getWhatsappHistory(v);
    }

    let clickClearAllSelection = () => {
        setLoadingWhatsappHistory(true);

        let _retriveDataError = {...retriveDataError};

        setPage(1);
        setFilterData({
            phone: "",
            source: {
                label: "All",
                value: ""
            },
            status: {
                label: "All",
                value: ""
            }
        });
        setFilterDataBefore({
            phone: "",
            source: {
                label: "All",
                value: ""
            },
            status: {
                label: "All",
                value: ""
            }
        });
        setFilterCount(0);
        setFilterDate({
            from: undefined,
            to: undefined
        });
        setFilterDateBefore({
            from: undefined,
            to: undefined
        });
        setIsActiveFilter(false);
        setIsFilterDateActive(false);

        let data = {
            "userPhone": "",
            "status": "",
            "source": "",
            "startDate": "",
            "endDate": "",
            "pageNumber": 1,
            "pageSize": listPageVal.value
        }

        ServiceWhatsapp.postGetHistory(orgID, data, (response) => {
           let dataResult = response.dataResult;
      
            if(dataResult.error.message === "") {
                setWhatsappHistory(dataResult.data.verifications);
                setTotalData(dataResult.data.totalItems);
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setLoadingWhatsappHistory(false);
        });
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >      
            <CalendarPicker
                isOpen={isOpenModalCalendar}
                toggle={toggleModalCalendar}
                filterDate={filterDate}
                filterDateBefore={filterDateBefore}
                handleDateFilterClick={handleDateFilterClick}
                isFilterDateActive={isFilterDateActive}
                submitDateFilter={submitDateFilter}
                onClearSelection={onClearDatePicker}
                // isDisabled={!filterDate.from || !filterDate.to}
                isDisabled={false}
                {...props}
            />

            <FilterBy
                isHideClearSelection
                isOpenProps={isOpenModalFilter}
                toggleProps={toggleModalFilter}
                resetCurrentChanges={resetCurrentChanges}
                phoneNumberProps={filterData.phone}
                onChangePhoneNumberProps={onChangePhoneNumber}
                sourceListProps={[
                    {
                        label: "All",
                        value: ""
                    },
                    {
                        label: "Customer Dashboard",
                        value: "customer_dashboard"
                    },
                    {
                        value: "integration_api",
                        label: "Integration API"
                    }
                ]}
                filterSourceValueProps={filterData.source}
                statusListProps={[
                    {
                        label: "All",
                        value: ""  
                    },
                    {
                        label: "Verified",
                        value: "verified"
                    },
                    {
                        label: "Unverified",
                        value: "unverified"
                    },
                    {
                        label: "Failed",
                        value: "failed"
                    }
                ]}
                filterStatusValueProps={filterData.status}
                onSelectSourceProps={onChangeSource}
                onSelectStatusProps={onChangeStatus}
                updateFilterProps={runUpdateFilter}
                closeModal={() => setIsOpenModalFilter(false)}
                isActiveFilterProps={isActiveFilter}
                onClearSelectionProps={onClearSelection}
            />
            
            <div className="sectionWrap whatsapp-verification-history-container">
                <div className="topContent whatsapp-verification-history-top-content">
                    <div className="title title-whatsapp-verification">Verification History</div>
                    
                    {isLoadingGenerateReport ?
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-40"
                            text="Export"
                            isLoading
                            loadingWhite
                            position="left"
                        />
                        :
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-40"
                            text="Export"
                            onClickAction={onClickExport}
                            icon={{
                                type: "svg",
                                src: FiDownload
                            }}
                            position="left"
                        />
                    }
                </div>

                <div className="whatsapp-verification-history-bottom-content">
                    {!isFilterDateActive ?
                        <ButtonWithLoadingOrIcon
                            className="no-fill-button main-button-40"
                            text="Date Range"
                            onClickAction={toggleModalCalendar}
                            icon={{
                                type: "svg",
                                src: FiCalendar
                            }}
                            position="left"
                        />
                        :
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-40"
                            text={
                                !filterDate.to ?  
                                    HelperDate.formatToString(new Date(filterDate.from), "dd MMMM yyyy") 
                                    : 
                                    `${HelperDate.formatToString(new Date(filterDate.from), "dd MMMM yyyy")} - ${HelperDate.formatToString(new Date(filterDate.to), "dd MMMM yyyy")}`
                            }
                            onClickAction={toggleModalCalendar}
                            icon={{
                                type: "svg",
                                src: FiCalendar
                            }}
                            position="left"
                        />
                    }
                    
                    {isActiveFilter ?
                        <ButtonWithLoadingOrIcon
                            className={"orange-button main-button-40"}
                            text="Filters"
                            onClickAction={toggleModalFilter}
                            icon={{
                                type: "img",
                                src: IconFilterWhite
                            }}
                            position="left"
                            counter={filterCount}
                        />
                        :
                        <ButtonWithLoadingOrIcon
                            className={"no-fill-button main-button-40"}
                            text="Filters"
                            onClickAction={toggleModalFilter}
                            icon={{
                                type: "img",
                                src: IconFilterOrange
                            }}
                            position="left"
                            counter={filterCount}
                        />
                    }

                    {(isActiveFilter || isFilterDateActive) &&
                        <ButtonWithLoadingOrIcon 
                            className="main-button-40 no-fill-button button-clear-selection"
                            icon={{
                                type: "svg",
                                src: FiX
                            }}
                            position="left"
                            text="Clear Selection"
                            onClickAction={clickClearAllSelection}
                        />
                    }

                    <table>
                        <thead>
                            <tr>
                                <td><b>Verification ID</b></td>
                                <td><b>Created Time</b></td>
                                <td><b>User Phone Number</b></td>
                                <td><b>Source</b></td>
                                <td><b>Status</b></td>
                                <td>
                                    <CustomSelectOption
                                        optionListProps={listPageOption}
                                        valueDropdownProps={listPageVal}
                                        onClickDropDownListOptionProps={onSelectListPage}
                                    />
                                </td>
                            </tr>
                        </thead>

                        <tbody>
                            {loadingWhatsappHistory || retriveDataError.code ? 
                                <tr>
                                    <td colSpan={6} className="td-loadings">
                                        <RetrieveDataLoading 
                                            isLoading={loadingWhatsappHistory}
                                            errorMessage={retriveDataError.message} 
                                        />
                                    </td>
                                </tr>
                                :
                                <>
                                    {whatsappHistory.length > 0 ?
                                        (whatsappHistory.map((v, i) => {
                                            return (
                                                <tr key={`tr-history-${i}`}>
                                                    <td>{v.stringID}</td>
                                                    <td>{HelperDate.formatToString(new Date(v.createdTime), "dd MMM yyyy HH:mm")}</td>
                                                    <td>+{v.userPhone.substr(0, 2) !== "62" ? "62" : ""}{v.userPhone}</td>
                                                    <td>{v.source === "integration_api" ? "Integration API" : "Customer Dashboard"}</td>
                                                    <td className="td-status">
                                                        {v.status === "verified" &&
                                                            <>
                                                                <img src={IconCheck} alt="" />Verified
                                                            </>
                                                        }

                                                        {v.status === "failed" &&
                                                            <>
                                                                <img src={IconFailed} alt="" />Failed
                                                            </>
                                                        }

                                                        {v.status === "unverified" &&
                                                            <>
                                                                <img src={IconInfo} alt="" />Unverified
                                                            </>
                                                        }
                                                    </td>
                                                    <td>
                                                        <NavLink to={`verification-history/details/${v.stringID}`}>
                                                            <b className="button-details"><FiEye />Details</b>
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )
                                        }))
                                        :
                                        <tr>
                                            <td colSpan={6} className="no-verification-history-found">
                                                <b>
                                                    No verification history found.
                                                </b>
                                            </td>
                                        </tr>
                                    }
                                </>
                            }
                        </tbody>
                    </table>
                    
                    {totalData > 0 &&
                        <Pagination
                            total={totalData}
                            page={page}
                            listPerPage={listPageVal.value}
                            step={1}
                            onChangePage={handleChangePage}
                        />
                    }
                </div>
            </div>
        </SectionWrap>
    )
}

let mapDispatchToProps = {
    // setParentMenuBlockActive,
};

let mapStateToProps = state => ({
    countryList: state.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatsappVerificationHistory);