import React, { useState, useEffect } from 'react';
import './ChangePassword.scss'
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdLockOutline } from "react-icons/md";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupPassword from "../../reuseableComponent/popupPassword/PopupPassword";
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap';
import ServiceChangePassword from "../../../services/auth/ServiceChangePassword";
import { doToast } from '../../../helper/HelperGeneral';
import { SUCCESS_TOAST } from '../../../constants/general';

const style = {
  lockStyle: {
    position: "relative",
    top: "-2px"
  },
  marginBottom: {
    marginBottom: 0
  },
  divTwoFactor: {
    position: "relative",
    width: "50%",
    display: "inline-block"
  },
  lastWrapper: {
    borderBottom: 0,
    paddingBottom: 0
  },
  toastPosition: {
    top: 0,
    right: 0
  }
};

const ChangePassword = (props) => {
  const [popupPasswordShow, setPopupPasswordShow] = useState(false)
  const [peekPassword, setPeekPassword] = useState(false)
  const [peekRePassword, setPeekRePassword] = useState(false)
  const [lowerCase, setLowerCase] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [number, setNumber] = useState(false)
  const [specialCharacter, setSpecialCharacter] = useState(false)
  const [minimumCharacter, setMinimumCharacter] = useState(false)
  const [valPassword, setValPassword] = useState("")
  const [valRePassword, setValRePassword] = useState("")
  const [isWaitingResponseChangePassword, setIsWaitingResponseChangePassword] = useState("")

  const togglePopupPassword = (e) => {
    e.preventDefault();
    setPopupPasswordShow(true)
  }

  const onChangeShowPopup = () => {
    setPopupPasswordShow(false)
  }

  const peekPasswordClick = () => {
    setPeekPassword(!peekPassword)
  }

  const peekRePasswordClick = () => {
    if (isDisabledChangePassword) return;
    setPeekRePassword(!peekRePassword)
  }

  const handlePassword = event => {
    let value = event.target.value;
    setValPassword(value)

    //lowercase
    if ((/[a-z]/.test(value))) {
      setLowerCase(true)
    } else {
      setLowerCase(false)
    }

    //uppercase
    if ((/[A-Z]/.test(value))) {
      setUpperCase(true)
    } else {
      setUpperCase(false)
    }

    //number
    if ((/[0-9]/.test(value))) {
      setNumber(true)
    } else {
      setNumber(false)
    }

    //special character
    if (!(/^[a-zA-Z0-9]*$/).test(value)) {
      setSpecialCharacter(true)
    } else {
      setSpecialCharacter(false)
    }

    //8 minimum character
    if (value.length > 7) {
      setMinimumCharacter(true)
    } else {
      setMinimumCharacter(false)
    }
  }

  const onChangeRePassword = e => {
    setValRePassword(e.target.value)
  }

  const doChangePassword = (currentPassword) => {
    let param = {
      password: currentPassword,
      newPassword: valPassword
    };

    setIsWaitingResponseChangePassword(true)

    ServiceChangePassword.postChangePassword(param, (response) => {
      setIsWaitingResponseChangePassword(false)

      if (response.dataResult.error.code === "") {
        doToast(response.dataResult.data.message, SUCCESS_TOAST)

        // reset form
        setPopupPasswordShow(false)
        setValPassword("")
        setValRePassword("")
        setLowerCase(false)
        setUpperCase(false)
        setNumber(false)
        setSpecialCharacter(false)
        setMinimumCharacter(false)
      } else {
        doToast(response.dataResult.error.message, "fail")
      }
    })
  }

  const [isDisabledChangePassword, setIsDisabledChangePassword] = useState(false)

  useEffect(() => {
    if (lowerCase && upperCase && number && specialCharacter && minimumCharacter && valPassword) {
      setIsDisabledChangePassword(false)
    } else {
      setIsDisabledChangePassword(true)
    }
  }, [lowerCase, upperCase, number, specialCharacter, minimumCharacter, valPassword, valRePassword])

  const [disabledButton, setDisabledButton] = useState(true)

  useEffect(() => {
    if (isDisabledChangePassword) {
      setDisabledButton(true)
    } else {
      if (valPassword !== valRePassword) {
        setDisabledButton(true)
      } else {
        setDisabledButton(false)
      }
    }
  }, [valPassword, valRePassword, isDisabledChangePassword])

  return (
    <React.Fragment>
      <div id="page-account-security">
        <SectionWrap
          {...props}
          withHeader
          withSideMenu
        >
          <div className="sectionWrap">
            Change Password
            <p id="instruction-text">
              Follow the instructions below.
            </p>

            <form onSubmit={togglePopupPassword}>
              <div className="security-input">
                <label>New Password</label>
                <div className="popup-input-password-wrapper">
                  <input type={peekPassword ? "text" : "password"}
                    autoComplete="false"
                    placeholder="Input your new password"
                    id="valPassword"
                    onChange={(e) => handlePassword(e)}
                    value={valPassword}
                  />

                  <div className="peek-password"
                    onMouseDown={() => peekPasswordClick()}
                    onMouseUp={() => peekPasswordClick()}
                  >
                    {peekPassword ?
                      <FiEye />
                      :
                      <FiEyeOff />
                    }
                  </div>
                </div>

                <div className="input-wrapper password-strength" style={style.fontSize0}>
                  <div className="div50">
                    <div className="checkLowerCase">
                      {lowerCase ?
                        <FaCheckCircle size={17} color="#7EC82A" /> :
                        <FaRegCircle size={17} color="#A4A4A4" />
                      }
                            One lowercase character
                        </div>
                    <div className="checkUpperCase">
                      {upperCase ?
                        <FaCheckCircle size={17} color="#7EC82A" /> :
                        <FaRegCircle size={17} color="#A4A4A4" />
                      }
                            One uppercase character
                        </div>
                    <div className="checkNumber">
                      {number ?
                        <FaCheckCircle size={17} color="#7EC82A" /> :
                        <FaRegCircle size={17} color="#A4A4A4" />
                      }
                            One Number
                        </div>
                  </div>

                  <div className="div50">
                    <div className="checkSpecialCharacter">
                      {specialCharacter ?
                        <FaCheckCircle size={17} color="#7EC82A" /> :
                        <FaRegCircle size={17} color="#A4A4A4" />
                      }
                            One special character
                        </div>
                    <div className="checkMinimumCharacter">
                      {minimumCharacter ?
                        <FaCheckCircle size={17} color="#7EC82A" /> :
                        <FaRegCircle size={17} color="#A4A4A4" />
                      }
                            8 minimum character
                        </div>
                  </div>
                </div>

                <label>Re-type New Password</label>
                <div className="popup-input-password-wrapper popup-reinput-password-wrapper">
                  <input type={peekRePassword ? "text" : "password"}
                    autoComplete="false"
                    placeholder="Input your new password"
                    id="valRePassword"
                    onChange={(e) => onChangeRePassword(e)}
                    disabled={isDisabledChangePassword}
                    value={valRePassword}
                  />

                  <div className={`peek-password ${isDisabledChangePassword ? "disabled-active" : ""}`}
                    onMouseDown={() => peekRePasswordClick()}
                    onMouseUp={() => peekRePasswordClick()}
                  >
                    {peekRePassword ?
                      <FiEye />
                      :
                      <FiEyeOff />
                    }
                  </div>

                  {/* {(this.state.valPassword !== this.state.valRePassword && this.state.valRePassword.length > 0) &&
                    <div className="input-warning">Password didn't match</div>
                  } */}
                </div>

                <ButtonWithLoadingOrIcon
                  className="no-fill-button main-button-48"
                  text="Change Password"
                  position="right"
                  icon={{
                    type: "svg",
                    src: MdLockOutline
                  }}
                  isDisabled={disabledButton}
                />
              </div>
            </form>

            {popupPasswordShow &&
              <PopupPassword showPopupPassword={true}
                onChangeShowPopupPassword={onChangeShowPopup}
                onClickSubmit={doChangePassword}
                isWaitingResponseChangePasswordProps={isWaitingResponseChangePassword}
              />
            }
          </div>
        </SectionWrap>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;