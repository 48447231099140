import BaseService from "../BaseService";
import { API_KEY } from "../../config";

class ServiceWhatsapp {
    postGetConfig(orgID, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.GET_CONFIG, {}, headers, callback);
    }

    postSetConfig(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.SET_CONFIG, data, headers, callback);
    }

    postCreateHistory(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.CREATE_VERIF, data, headers, callback);
    }

    postGetHistory(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.GET_HISTORY, data, headers, callback);
    }

    postGetDetail(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.GET_DETAILS, data, headers, callback);
    }
    
    postExport(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.EXPORT, data, headers, callback, 'blob');
    }
}

const API = {
    GET_CONFIG: "/organization/whatsapp_verification/get_configs",
    SET_CONFIG: "/organization/whatsapp_verification/set_configs",
    CREATE_VERIF: "/organization/whatsapp_verification/create",
    GET_HISTORY: "/organization/whatsapp_verification/get_history",
    GET_DETAILS: "/organization/whatsapp_verification/get_details",
    EXPORT: "/organization/whatsapp_verification/export_history"
}

export default new ServiceWhatsapp()