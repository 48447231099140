import React from "react";
import "./CopyBox.scss";
import { FiCopy } from "react-icons/fi";
import { doToast } from "../../../helper/HelperGeneral";

const CopyBox = props => {
    let copyToClip = (selector, text) => {
        document.getElementById(selector).select();
        document.execCommand("copy");
        doToast(text);
    };

    return (
        <div className={`copy-box-wrapper ${props.className}`}>
            {props.isTextArea ?
                <textarea 
                    value={props.val}
                    id={props.id}
                    readOnly
                />
                :
                <input 
                    type="text" 
                    value={props.val}
                    id={props.id}
                    readOnly
                />
            }

            <FiCopy onClick={() => copyToClip(props.id, props.copyText)} />
        </div>
    );
};

export default CopyBox;