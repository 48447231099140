import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./CustomSelectOption.scss";
import { FiChevronDown } from "react-icons/fi";

const CustomSelectOption = props => {
    let [isDropdownActive, setIsDropdownActive] = useState(false);

    let toggleDropdownOption = () => {
        setIsDropdownActive(!isDropdownActive);
    }

    let onClickOptionList = (value) => {
        toggleDropdownOption();
        props.onClickDropDownListOptionProps(value);
    }
    
    return (
        <div className={`${props.specifiClassNameProps} main-custom-select-option-wrapper ${isDropdownActive ? "active-dropdown" : ""}`}>
            <Dropdown isOpen={isDropdownActive} toggle={toggleDropdownOption} >
                <DropdownToggle className={`custom-select-option-value-wrapper ${props.dropdownError && props.dropdownError !== "" ? "border-red" : ""} `} disabled={props.isDisabled || props.isLoading}>
                    {props.valueDropdownProps.label === "" ?
                        <p className="custom-select-option-placeholder">
                            {props.placeholderProps}
                        </p>
                        :
                        <p className="custom-select-option-value">
                            {props.valueDropdownProps.label}
                        </p>
                    }

                    <FiChevronDown className={`icon-arrow`} />
                </DropdownToggle>
                <DropdownMenu className="dropdown-option-wrapper">
                    {props.optionListProps.length > 0 ?
                        props.optionListProps.map((val, key) => {
                            return (
                                <DropdownItem className={`${props.valueDropdownProps.value === val.value ? "active-value" : ""}`}
                                    onClick={() => onClickOptionList(val)}
                                    key={`option-list-${props.placeholderProps}-${key}`}
                                >
                                    {val.label}
                                </DropdownItem>
                            )
                        })
                        :
                        <DropdownItem className="no-data-option">
                            No Option
                        </DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default CustomSelectOption;
