import { validEmail } from "./HelperGeneral";

var Helper = {
    renderUserAvatarWord: (text, isGroup) => {
        let userAvatarWord = "";
        let nameSplit = text.split(" ");
        let secondName = nameSplit.length > 1 ? nameSplit[1] : '';

        if (isGroup) {
        userAvatarWord = nameSplit[0].substr(0, 1);
        } else {
        userAvatarWord = nameSplit[0].substr(0, 1);
        userAvatarWord += secondName.substr(0, 1);
        }

        return userAvatarWord.toUpperCase();
    },

    is_aplhanumeric(c) {
        var x = c.charCodeAt();
        return ((x>=65&&x<=90)||(x>=97&&x<=122)||(x>=48&&x<=57))?true:false;
    },

    whatsappStyles(format,wildcard, opTag, clTag) {
        var indices = [];
        let a = "";
        for(var i = 0; i < format.length; i++) {
            if (format[i] === wildcard) {
                if(indices.length%2) {
                    if(format[i-1] === " ") {
                        a = null;
                    }else if(typeof(format[i+1])=="undefined") {
                        indices.push(i);
                    } else if(this.is_aplhanumeric(format[i+1])) {
                        a = null;
                    } else {
                        indices.push(i);
                    }
                } else {
                    if(typeof(format[i+1]) === "undefined") {
                        a = null;
                    } else if(format[i+1] === " ") {
                        a = null;
                    } else if(typeof(format[i-1]) === "undefined") {
                        indices.push(i);
                    } else if(this.is_aplhanumeric(format[i-1])) {
                        a = null;
                    } else {
                        indices.push(i);
                    }
                }
            }
            else{
                if(format[i].charCodeAt()===10 && indices.length % 2) {
                    indices.pop()
                }
            }
        }

        if(indices.length % 2) {
            indices.pop()
        }
            
        var e=0;
        
        indices.forEach(function(v,i){
            var t=(i%2)?clTag:opTag;
            v+=e;
            format=format.substr(0,v)+t+format.substr(v+1);
            e+=(t.length-1);
        });
        return format;
    },

    convertFormatTextWhatsapp(message) {
        var html = this.replaceTagHTML(message);
        html = this.whatsappStyles(html,'_', '<i>', '</i>');
        html = this.whatsappStyles(html,'*', '<b>', '</b>');
        html = this.whatsappStyles(html,'~', '<s>', '</s>');
        html = this.whatsappStyles(html,'```', '<mono>', '</mono>');
        html = html.replace(/\n/gi,"<br>");
        // var bold = /\*(.*?)\*/gm;
        // var italic = /\_(.*?)\_/gm;
        // var strikethrough = /\~(.*?)\~/gm;
        var monospace = /\```(.*?)\```/gm;
        html = html.replace(monospace, '<mono>$1</mono>');            
        return html;
    },

    replaceTagHTML(str) {
        var tagsToReplace = {
            '<': '&lt;',
            '>': '&gt;'
        };
        
        let replaceTag = (tag) => {
            return tagsToReplace[tag] || tag;
        }
        
        return str.replace(/[&<>]/g, replaceTag); 
    },

    removeHTMLTag(str) {
        let _str = str;
        
        var div = document.createElement("div");
        div.innerHTML = _str;
        
        return div.innerText;
    },

    convertUrl(inputText) {
        let regQuestingMark = /(\?)+$/;
        // let takeOutQuestionMark = "";
        // takeOutQuestionMark = inputText.match(regQuestingMark) !== null ? inputText.match(regQuestingMark) : "";
        // inputText = inputText.replace(regQuestingMark, "");

        let isHttp = (text) => {
            return text.substring(0, 7) === "http://";
        }

        let isHttps = (text) => {
            return text.substring(0, 8) === "https://";
        }

        let isW3 = (text) => {
            return text.substring(0, 4) === "www.";
        }

        let urlify = (text) => {
            let urlRegex = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
            let startingWithoutSpecialChar = /^[A-Za-z0-9]/;
            
            return text.replace(urlRegex, function(url) {
                let returnValue = url;
                let _url = url;
                let rep = "";
                let c = (t) => {
                    if(startingWithoutSpecialChar.test(t) && !t.includes("{{contact.")) {
                        runConvert();
                    }
                }

                let runConvert = () => {
                    if(!url.includes("contact.")) {
                        if(!isHttps(url)) {
                            if(!isHttp(url)) {
                                _url = "http://"+url;
                            }
                        }

                        let _urlQuestionMark = _url.match(regQuestingMark) !== null ? url.match(regQuestingMark) : "";

                        url = url.replace(regQuestingMark, "");
                        _url = _url.replace(regQuestingMark, "");
                        // returnValue = `<a class="tap-hyperlink" target="_blank" href="${_url}">${url}</a>${takeOutQuestionMark !== "" ? takeOutQuestionMark[0] : ""}`;
                        returnValue = `<a class="tap-hyperlink" target="_blank" href="${_url}">${url}</a>${_urlQuestionMark === "" ? "" : _urlQuestionMark[0] }`;
                    }
                }

                if(startingWithoutSpecialChar.test(url) && !validEmail(url)) {
                    if(isW3(url)) {
                        rep = url.replace("www.", "");
                        c(rep);
                    }else if(isHttp(url)) {
                        rep = url.replace("http://", "");
                        c(rep);
                    }else if (isHttps(url)) {
                        rep = url.replace("https://", "");
                        c(rep);
                    }else {
                        runConvert();
                    }
                }

                return returnValue;
            })
        }

        return urlify(inputText)
    },

    lineBreakChatRoom(message) {
        let _message = this.replaceTagHTML(message);
        return _message !== null ? this.convertUrl(_message.trim().replace(new RegExp("\n", "g"), "<br />")) : _message;
    },
}

export default Helper;
