import React, { useState, useEffect } from 'react';
import './ApiKeys.scss';
import { Modal, ModalBody } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import { checkID, doToast } from "../../helper/HelperGeneral";
// import Pagination from "../reuseableComponent/pagination/Pagination";
import { FiPlus, FiEye, FiCopy, FiTrash2 } from "react-icons/fi";
import PopupRemove from "../reuseableComponent/popupRemove/PopupRemove";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import ApiKeysServices from "../../services/apiKeys/ApiKeysServices";
import HelperDate from "../../helper/HelperDate";
import { SUCCESS_TOAST } from '../../constants/general';

const ApiKeys = (props) => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  let [apiKeysList, setApiKeyList] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [showPopupSingleRemove, setShowPopupSingleRemove] = useState(false);
  let [singleRemoveApiKey, setSingleRemoveApiKey] = useState({
    id: "",
    createdTime: "",
  });
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [apiKeyCopy, setApiKeyCopy] = useState(false);
  let [isShowModalViewKey, setIsShowModalViewKey] = useState(false);


  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/api-key/general`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let fetchApiKeyList = () => {
    let _retriveDataError = { ...retriveDataError };

    ApiKeysServices.getApiKey(orgID, (response) => {
      let dataResult = response.dataResult;
      if (dataResult.error.message === "") {
        setApiKeyList(dataResult.data.apiKeys);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  useEffect(() => {
    if (orgID) {
      fetchApiKeyList();
    }
  }, [orgID]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.message) {
        doToast(props.history.location.state.message);
        window.history.pushState(null, '');
      }
    }
  }, [props.history])

  useEffect(() => {
    setIsShowModalViewKey(apiKeyCopy ? true : false)
  }, [apiKeyCopy])

  // let onChangePage = () => {
  //   console.log('change page')
  // }

  let toggleModalRemoveApiKey = () => {
    setShowPopupSingleRemove(!showPopupSingleRemove);
  }

  let deleteApiKey = () => {
    setWaitForResponseDelete(true);
    let _singleRemoveApiKey = { ...singleRemoveApiKey };

    const data = {
      id: _singleRemoveApiKey.id,
      createdTime: _singleRemoveApiKey.createdTime
    }

    ApiKeysServices.deleteApiKey(orgID, data, (response) => {
      let dataResult = response.dataResult;
      setWaitForResponseDelete(false);
      toggleModalRemoveApiKey();

      if (dataResult.error.message === "") {
        if (!response.dataResult.data.success) {
          doToast(response.dataResult.data.message, "fail");
        } else {
          doToast(response.dataResult.data.message, SUCCESS_TOAST);
          fetchApiKeyList();
        }
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }

    })
  }

  let onClickSingleDelete = (data) => {
    setShowPopupSingleRemove(true);

    let _data = {
      id: data.id,
      createdTime: data.createdTime
    }

    setSingleRemoveApiKey(_data);
  }

  const copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  let modalViewKey = () => {
    return (
      <Modal isOpen={isShowModalViewKey} className="modal-view-key">
        <ModalBody>
          <p className="modal-title">
            <FiEye />
            <b>View API Key</b>
          </p>

          <div className="form-content-wrapper">
            <div className="disabled-form">
              <input type="text"
                value={apiKeyCopy}
                // onClick={copyToClip}
                id="copy-api-key"
                readOnly
              />

              <p>
                {apiKeyCopy}
              </p>

              <FiCopy
                onClick={() => {
                  copyToClip("copy-api-key", "API Key copied")
                }}
              />
            </div>
          </div>

          <ButtonWithLoadingOrIcon
            className="orange-button main-button-48"
            text="Dismiss"
            onClickAction={() => setApiKeyCopy(false)}
          />
        </ModalBody>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap api-key-main-wrapper">
          {modalViewKey()}

          <div className="topContent ">
            <div className="title">API Keys</div>
            <ButtonWithLoadingOrIcon
              icon={{
                type: "svg",
                src: FiPlus
              }}
              className="orange-button main-button-40"
              text="Create API Key"
              position="left"
              onClickAction={() => history.push(`/o/${orgID}/api-keys/create`)}
            />
          </div>

          <div className="api-key-list-info">
            Create your own API Keys and gain access to SendTalk APIs to send messages.
          </div>

          {waitForResponseGetList || retriveDataError.code ?
            <RetrieveDataLoading
              isLoading={waitForResponseGetList}
              errorMessage={retriveDataError.message}
            />
            :
            <React.Fragment>
              {apiKeysList.length < 1 ?
                <div className="no-data-wrapper">
                  <b className="no-result-found">No API Keys found. Start by ‘Create API Key’.</b>
                </div>
                :
                <div className="api-key-list-table-wrapper">
                  <div className="custom-table">
                    <table className="custom-table-content table-api-key-list">
                      <thead>
                        <tr>
                          <td className="col_desc">Description</td>
                          <td className="col_key">API Key</td>
                          <td className="col_expiry">Expiry</td>
                          <td className="col_action"></td>
                        </tr>
                      </thead>

                      <tbody>
                        {apiKeysList.map((value, index) => {
                          return (
                            <tr key={`api-key-${index}`}>
                              <td className="col_desc">{value.description}</td>
                              <td className="col_key">
                                <span onClick={() => setApiKeyCopy(value.key)}>
                                  <FiEye />
                                  <b>View</b>
                                </span>
                              </td>
                              <td className="col_expiry">
                                {value.expiryTime === 0 ? "No Expiry" : HelperDate.formatToString(new Date(value.expiryTime), "dd MMM yyyy")}
                              </td>
                              <td className={`col_action`}>
                                <div className="col-action-button-wrapper">
                                  <button
                                    className="single-remove-button no-background-and-border-button"
                                    onClick={() => onClickSingleDelete(value)}>
                                    {/* <img className="view-agent" src={IconRemoveRed} alt="" />ssss */}
                                    <FiTrash2 />
                                    <b>Delete</b>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>

                    </table>
                  </div>


                  {/* <Pagination total={100} 
                              page={1} 
                              listPerPage={8} 
                              step={3} 
                              onChangePage={onChangePage} 
                  /> */}

                  <PopupRemove removeTitle={`Delete API Keys?`}
                    removeDescription={`This api key will be removed from the list`}
                    onClickCancel={() => toggleModalRemoveApiKey()}
                    onClickRemove={deleteApiKey}
                    waitForResponse={waitForResponseDelete}
                    showModalProps={showPopupSingleRemove}
                    submitText="Delete API Keys"
                    colorButton="red-button"
                  />
                </div>
              }
            </React.Fragment>
          }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default ApiKeys;