import { FiAlertCircle, FiCheck } from "react-icons/fi";
import { toast } from "react-toastify";
import { SUCCESS_TOAST } from "../constants/general";
// import ServiceOrganization from "../services/organization/ServiceOrganization";

const checkID = match => {
  const { orgID: param } = match.params;
  // if (!param) getOrganization();
  if (!param) return false;
  else return param;
};

// const getOrganization = async () => {
//   const { dataResult } = await ServiceOrganization.postGetOrganization();
//   const { status, data } = dataResult;
//   const error = status !== 200;
//   if (data.memberships.length < 1) window.location.href = "/organization/new";
//   if (error) return false;
//   const { id, createdTime } = data.memberships[0].organization;
//   const urlOrgID = `${id}-${createdTime}`;
//   return urlOrgID;
//   // history.push(`topics/${urlOrgID}`);
// };

const getSplitID = (type, id) => {
  let idSplit = id.split("-");
  if (type === "id") return idSplit[0];
  return idSplit[1];
};

const doToast = (text = "YOUR TOAST", _className = null) => {
  const config = {
    autoClose: 3000,
    position: "bottom-left",
    className: _className === null || _className === SUCCESS_TOAST ? "ToastContent" : "ToastContent-" + _className,
    hideProgressBar: true
  };
  if(_className === SUCCESS_TOAST || _className === null) {
    toast(<span className="toast-content-wrapper">
            <FiCheck />
            {" " + text}
          </span>, config);
  } else if(_className === "fail") {
    toast(
      <span className="toast-content-wrapper fail-toast">
        <FiAlertCircle />
        {" " + text}
      </span>
    , config);
  }
  else toast(text, config);
};

const CSVToArray = (strData, strDelimiter) => {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  var strMatchedValue;
  strDelimiter = (strDelimiter || ",");

  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
    (
      // Delimiters.
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

      // Standard fields.
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
  );


  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches = null;


  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while (arrMatches === objPattern.exec(strData)) {

    // Get the delimiter that was found.
    var strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      (strMatchedDelimiter !== strDelimiter)
    ) {

      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);

    }


    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {

      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(
        new RegExp("\"\"", "g"),
        "\""
      );

    } else {

      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];

    }


    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  // Return the parsed data.
  return (arrData);
}

const convertFileToCsv = (fileData, callback) => {
  var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/;

  if (regex.test(fileData.name.toLowerCase())) {
    if (typeof (FileReader) !== "undefined") {
      var reader = new FileReader();
      reader.onload = function (e) {
        let result = e.target.result;
        let newCSVArray = CSVToArray(result, ",");
        callback.onSuccessConvertCsv(newCSVArray);
      }

      reader.readAsText(fileData);
    } else {
      doToast("This browser does not support HTML5.", "fail");
    }
  } else {
    doToast("Invalid CSV file", "fail");
  }
};

const validEmail = (text) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(text)
}

const rupiahFormat = (amount) => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(amount);
};

const numberWithCommas = (amount) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const scrollToClass = (selector) => {
  let _sel = document.querySelectorAll(selector);

  if(_sel.length > 0) {
    _sel[0].scrollIntoView();
  }
}

export { checkID, getSplitID, doToast, convertFileToCsv, rupiahFormat, numberWithCommas, validEmail, scrollToClass };
