import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import HelperAuth from "../../../helper/HelperAuth";
import Sidemenu from "../sidemenu/Sidemenu";
import { setActiveOrgID } from "../../../redux/actions/reduxActionActiveOrgID";
import { setAllOrganizationDetail } from "../../../redux/actions/reduxActionAllOrganizationDetail";
import { Modal, ModalBody } from 'reactstrap';
import ChatRed from '../../../assets/img/icon-fail-circle.svg';
import { connect } from 'react-redux';
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceBilling from "../../../services/billing/ServiceBilling";
import { MdRefresh } from 'react-icons/md';
import ServiceProfile from "../../../services/profile/ServiceProfile";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import { setAuthActive } from "../../../redux/actions/reduxActionAuth";
import { setIsTrial } from "../../../redux/actions/reduxActionIsTrial";
import { setBilling } from "../../../redux/actions/reduxActionBilling";
import { doToast } from "../../../helper/HelperGeneral";

const MustLogin = ({ component: Component, withoutTier, ...rest }) => {
    // let history = useHistory();
    let [isFinishFethcingCurrentTierDetail, setIsFinishFethcingCurrentTierDetail] = useState(false);
    // let [currentTierDetail, setCurrentTierDetail] = useState({
    //     code: "",
    //     id: 0,
    //     isActive: false,
    //     isFreeTrial: false,
    //     name: "",
    //     statusText: ""
    // });
    let [errorMessageTier, setErrorMessageTier] = useState(null);
    let [currentMembership, setCurrentMembership] = useState(null);
    let [organizationError, setOrganizationError] = useState({
        code: 0,
        message: ""
    });
    let [showModalSettingWorkspace, setShowModalSettingWorkspace] = useState(true);
    let history = useHistory();

    let runGetProfile = () => {
        ServiceProfile.postGetProfile((response) => {
            if (response.dataResult.error.message === "") {
                let resultData = response.dataResult.data;

                let _user = { ...HelperCustomEncryptor.doDecrypt(rest.user.data) };
                _user.account = resultData.account;
                _user.memberships = resultData.memberships;
                _user.canCreateOrganization = resultData.canCreateOrganization;

                rest.setAuthActive({
                    data: HelperCustomEncryptor.doEncrypt(_user)
                });
            } else {
                console.log(response.dataResult.error.message);
            }
        });
    }

    useEffect(() => {
        if (HelperAuth.userWasLoggedIn()) {
            runGetProfile();
        }
    }, [])

    useEffect(() => {
        if(currentMembership) {
            runGetBilling(rest.computedMatch.params.orgID);
        }
    }, [currentMembership])

    let runGetOrganizationDetail = (orgID) => {
        ServiceOrganization.postGetOrganizationDetail(orgID, (response) => {
            if (response.dataResult.status === 200) {
                setTimeout(() => {
                    rest.setAllOrganizationDetail(response.dataResult.data);
                    setCurrentMembership(response.dataResult.data.membership);
                    rest.setIsTrial(response.dataResult.data.organization.isTrial);
                }, 2000)
            } else {
                let data = {
                    code: response.dataResult.error.code,
                    message: response.dataResult.error.message
                }

                setTimeout(() => {
                    setOrganizationError(data);
                }, 2000);
            }
        })
    }

    let runGetBilling = (orgID) => {
        ServiceBilling.getCurrentBalance(orgID, (response) => {
            if (response.dataResult.error.message === "") {
                rest.setBilling(response.dataResult.data.balances[0].amount);
            } else {
                doToast(response.dataResult.error.message, "fail");
            }
        })
    }

    let runGetOganization = () => {
        ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error.message === "") {
                let responseData = response.dataResult.data;

                if (responseData.memberships.length < 1) {
                    window.location.href = "/organization/new";
                } else {
                    history.push(`/o/${responseData.memberships[0].organization.id}-${responseData.memberships[0].organization.createdTime}/home`);
                }
            } else {
                setErrorMessageTier(response.dataResult.error.message);
            }
        })
    }

    useEffect(() => {
        if (isFinishFethcingCurrentTierDetail) {
            setShowModalSettingWorkspace(false);
        }
    }, [isFinishFethcingCurrentTierDetail]);

    useEffect(() => {
        if (organizationError.code !== 0 && currentMembership !== null) {
            setIsFinishFethcingCurrentTierDetail(true);
        }
    }, [organizationError, currentMembership])

    useEffect(() => {
        if (currentMembership !== null) {
            setIsFinishFethcingCurrentTierDetail(true);
        }
    }, [currentMembership])

    useEffect(() => {
        if (HelperAuth.userWasLoggedIn() && rest.computedMatch.params.orgID) {
            rest.setActiveOrgID(rest.computedMatch.params.orgID);
            runGetOrganizationDetail(rest.computedMatch.params.orgID);
        } else {
            runGetOganization();
        }
    }, [rest.computedMatch.params.orgID]);

    let renderSetupWorkspace = () => {
        let setupOnProgressModal = () => {
            return (
                <div className="setup-main-content">
                    <div className="setup-image-content">
                        <div className="lds-ring">
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                    <br />
                    <b>
                        Setting up your dashboard
                    </b>
                </div>
            )
        }

        let setupFailModal = () => {
            let tryAgain = () => {
                window.location.href = "/";
            }

            return (
                <div className="setup-main-content">
                    <div className="setup-image-content setup-fail">
                        <img src={ChatRed} alt="" />
                    </div>
                    <br />
                    <b className="setup-fail">
                        {errorMessageTier}
                    </b>
                    <p className="retry-setup" onClick={tryAgain}>
                        <b><MdRefresh />Try Again</b>
                    </p>
                </div>
            )
        }

        return (
            <Modal isOpen={showModalSettingWorkspace} className="setup-room-list-modal">
                <ModalBody>
                    {errorMessageTier !== null ?
                        setupFailModal()
                        :
                        setupOnProgressModal()
                    }
                </ModalBody>
            </Modal>
        )
    }

    let printRedirect = () => {
        let r = "";

        if(window.location.pathname === "") {
            r = "/";
        }
        else {
            r = `/login?redirect=${encodeURIComponent(window.location.pathname)}`;
        }

        return r;
    }


    return (
        <Route
            {...rest}
            render={props =>
                !HelperAuth.userWasLoggedIn() ?
                    (
                        <Redirect to={printRedirect()} />
                    )
                    :
                    (
                        !isFinishFethcingCurrentTierDetail && organizationError.code === 0 ?
                            renderSetupWorkspace()
                            :
                            <React.Fragment>
                                <Sidemenu {...props} />

                                <Component {...props} />
                            </React.Fragment>
                    )
            }
        />
    )
};

const mapStateToProps = state => ({
    user: state.user,
    allOrganizationDetail: state.allOrganizationDetail,
});

const mapDispatchToProps = {
    setActiveOrgID,
    setAllOrganizationDetail,
    setAuthActive,
    setIsTrial,
    setBilling
}

export default connect(mapStateToProps, mapDispatchToProps)(MustLogin);