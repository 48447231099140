import React, { Component } from 'react';
import './PopupRemove.css';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

class PopupRemove extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waitForResponseDelete: false,
    };
  }

  toggleModalRemoveMember = () => {
    this.props.onClickCancel();
  };

  onClickRemove = () => {
    this.props.onClickRemove();
  };

  componentWillReceiveProps(newProps) {
    const oldProps = this.props;
    if (oldProps.waitForResponse !== newProps.waitForResponse) {
      this.setState({
        waitForResponseDelete: newProps.waitForResponse,
      });
    }
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.showModalProps}
          toggle={this.toggleModalRemoveMember}
          className="modal-confirmation-delete"
        >
          <ModalBody>
            <b>{this.props.removeTitle}</b>
            <p style={{ marginTop: '10px' }}>{this.props.removeDescription}</p>
          </ModalBody>

          <ModalFooter>
            <ButtonWithLoadingOrIcon 
                className="no-fill-button main-button-48"
                text="Cancel"
                onClickAction={this.toggleModalRemoveMember}
            />

            {this.state.waitForResponseDelete ? (
              <ButtonWithLoadingOrIcon 
                className={`
                  ${this.props.colorButton ? 
                    this.props.colorButton
                    : 
                    `orange-button`
                  } 
                  main-button-48`
                }
                isLoading
                loadingWhite
                position="left"
                text={this.props.submitText ? this.props.submitText : 'Remove'}
              />
            ) : (
              <ButtonWithLoadingOrIcon 
                className={`
                  ${this.props.colorButton ? 
                    this.props.colorButton
                    : 
                    `orange-button`
                  } 
                  main-button-48`
                }
                text={this.props.submitText ? this.props.submitText : 'Remove'}
                onClickAction={this.onClickRemove}
              />
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default PopupRemove;
