import React, { Component } from 'react';
// import { Scrollbars } from 'react-custom-scrollbars'; 
import { NavLink } from 'react-router-dom';
import "./Sidemenu.scss";
import { setParentMenuBlockActive } from '../../../redux/actions/reduxActionSideMenu';
import { setLogout } from "../../../redux/actions/reduxLogout";
import { connect } from 'react-redux';
import ServiceAuthAccessToken from "../../../services/auth/ServiceAuthAccessToken";
import Helper from '../../../helper/HelperChat';
import { rupiahFormat } from '../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
// import { taptalk } from '@taptalk.io/web-sdk';
import { IoIosCheckmark } from 'react-icons/io'; //IoIosArrowForward
import iconHomeNew from '../../../assets/img/sidemenu-icon/new/home.svg';
import iconMessage from '../../../assets/img/sidemenu-icon/new/message.svg';
import iconApiKey from '../../../assets/img/sidemenu-icon/new/api-key.svg';
import iconPayment from '../../../assets/img/sidemenu-icon/new/payment.svg';
import iconReport from '../../../assets/img/sidemenu-icon/new/report.svg';
import iconWhatsapp from '../../../assets/img/sidemenu-icon/new/whatsapp.svg';
import iconOrganizationNew from '../../../assets/img/sidemenu-icon/new/organization.svg';
import { FiChevronLeft, FiChevronRight, FiExternalLink, FiLogOut, FiSettings } from 'react-icons/fi';
// import iconSwitchOrganization from '../../../assets/img/sidemenu-icon/new/switch-organization.svg';

const ROLE_CODE = {
  owner: 'owner',
  member: 'member',
  admin: 'admin',
  all: 'all'
}

class Sidemenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaitForResponseLogout: false,
      showPopupOrganization: false,
      userData: null,
      submenuList: null,
      isShowOrgList: false
    }

    // this.orgRef = React.createRef();
    this.wrapperRef = React.createRef();
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    let initTaplive = () => {  
      var elTapliveScript = document.createElement('script')
      elTapliveScript.id="script-taplive"
      elTapliveScript.type = "text/javascript"
      elTapliveScript.onload = () => {
          window.TapTalkLive.init(process.env.REACT_APP_TAPLIVE_KEY)
      }
      elTapliveScript.src= process.env.REACT_APP_TAPLIVE_URL
      elTapliveScript.async = true
      elTapliveScript.defer = true
      var elTapliveScriptPosition = document.getElementsByTagName('script')[0]
      elTapliveScriptPosition.parentNode.insertBefore(elTapliveScript, elTapliveScriptPosition)
    }

    initTaplive();

    document.addEventListener('click', this.handleClickOutside);
    document.querySelector("[name='viewport']").remove();
    this.setState({
      userData: { ...HelperCustomEncryptor.doDecrypt(this.props.user.data) }
    })
  }

  handleClickOutside(event) {
    if (this.wrapperRef) {
      if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
        let _this = this;
        _this.setState({
          showPopupOrganization: false
        })
      }
    }
  }

  isActiveOrganizationCheck = (orgID) => {
    if (this.props.activeOrgID) {
      let matchParam = this.props.activeOrgID;

      return matchParam.split("-")[0] === orgID.toString();
    }
  }

  initiateAccordionMenu(menu) {
    this.props.setParentMenuBlockActive({
      activeMenuBlock: this.props.sideMenu.activeMenuBlock === menu ? "" : menu
    });
  }

  runLogoutAction = () => {
    // mixpanel.reset();
    let clearTaplive = () => {
      localStorage.removeItem("TapTalk.UserData");
      localStorage.removeItem("taplive.data");
      localStorage.removeItem("taplive.auth");
    }

    this.setState({
      isWaitForResponseLogout: true
    });

    let userData = HelperGetLocalStorage.getLocalStorageData("user");

    if (userData) {
      // if (taptalk.isAuthenticated()) {
      //   taptalk.logoutAndClearAllTapTalkData({
      //     onSuccess: (response) => {
      //       console.log(response)
      //     }
      //   });
      // }

      let accessToken = HelperCustomEncryptor.doDecrypt(userData.data).accessToken;
      ServiceAuthAccessToken.postLogout(accessToken).then(response => {
        if (response.dataResult.error.code !== "") {
          this.setState({
            isWaitForResponseLogout: false
          });
        } else {
          console.log(response.dataResult.error.message);
        }
      });

      this.props.setLogout();

      setTimeout(() => {
        clearTaplive();
        window.location.href = window.location.origin + "/";
      }, 250);
    } else {
      clearTaplive();
      window.location.href = window.location.origin + "/";
    }
  }

  togglePopupOrganization = (e) => {
    e.stopPropagation()
    this.setState({
      showPopupOrganization: this.state.showPopupOrganization ? false : true
    })
  }

  onClickOrganizationList = (organization) => {
    setTimeout(() => {
      window.location.href = `/o/${organization.id}-${organization.createdTime}/home`;
    }, 100);
  }

  findMemberRole = () => {
    let user = this.state.userData;
    if (this.props.activeOrgID) {
      let organizationID = this.props.activeOrgID.split("-")[0];

      let findOrganization = () => {
        let _findIndex = user.memberships.findIndex(value => value.organization.id === parseInt(organizationID));

        return _findIndex;
      }

      if (user.memberships.length === 0) {
        return ROLE_CODE.all;
      } else {
        return findOrganization() !== -1 ? user.memberships[findOrganization()].roleCode : ROLE_CODE.all
      }
    }
  }

  render() {
    let sideMenuList = {
      home: {
        label: "Home",
        icon: iconHomeNew,
        newTab: false,
        pathName: `/o/${this.props.activeOrgID}/home`,
        activeValue: "home",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
        dontPrintOnLoop: false,
        isWaitTierDetail: false
      },
      message: {
        label: "Messages",
        newTab: false,
        activeValue: "message",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
        icon: iconMessage,
        subMenu: {
          // sendMessage: {
          //   name: 'Send Message',
          //   activeValue: 'send-message',
          //   pathName: `/o/${this.props.activeOrgID}/message/send-message`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner],
          // },
          messageHistory: {
            name: 'Message History',
            activeValue: 'message-history',
            pathName: `/o/${this.props.activeOrgID}/message/message-history`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
          },
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      whatsapp: {
        label: "WhatsApp Verification",
        newTab: false,
        activeValue: "whatsapp_verification",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
        icon: iconWhatsapp,
        subMenu: {
          verification_config: {
            name: 'Verification Config',
            activeValue: 'verification-config',
            pathName: `/o/${this.props.activeOrgID}/whatsapp-verification/verification-config`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
          },
          create_verification: {
            name: 'Create Verification',
            activeValue: 'create-verification',
            pathName: `/o/${this.props.activeOrgID}/whatsapp-verification/create-verification`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
          },
          verification_history: {
            name: 'Verification History',
            activeValue: 'verification-history',
            pathName: `/o/${this.props.activeOrgID}/whatsapp-verification/verification-history`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
          },
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      apiKey: {
        label: "API",
        newTab: false,
        activeValue: "api-key",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
        icon: iconApiKey,
        subMenu: {
          apiKeys: {
            name: "API Keys",
            activeValue: "api-keys",
            pathName: `/o/${this.props.activeOrgID}/api-keys`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
          },
          apiDocs: {
            name: "API Docs",
            activeValue: "api-docs",
            isExternalLink: true,
            linkTab: 'https://docs.taptalk.io/sendtalk-api-documentation/messages-api',
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
          }
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      payment: {
        label: 'Payment',
        newTab: false,
        activeValue: 'payment',
        role: [ROLE_CODE.admin, ROLE_CODE.owner],
        icon: iconPayment,
        subMenu: {
          topUpNow: {
            name: 'Top Up Now',
            activeValue: 'topup-now',
            pathName: `/o/${this.props.activeOrgID}/payment/topup-now`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
          },
          topUpHistory: {
            name: 'Top Up History',
            activeValue: 'topup-history',
            pathName: `/o/${this.props.activeOrgID}/payment/topup-history`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
          },
          lowBalanceAlert: {
            name: 'Low Balance Alert',
            activeValue: 'low-balance-alert',
            pathName: `/o/${this.props.activeOrgID}/payment/low-balance-alert`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
          },
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      report: {
        label: "Report",
        icon: iconReport,
        newTab: false,
        activeValue: "report",
        role: [ROLE_CODE.admin, ROLE_CODE.owner],
        subMenu: {
          members: {
            name: "Daily Cost Report",
            activeValue: "cost-report",
            pathName: `/o/${this.props.activeOrgID}/report/cost-report`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner]
          },
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      organization: {
        label: "Organization",
        icon: iconOrganizationNew,
        newTab: false,
        activeValue: "organization",
        role: [ROLE_CODE.admin, ROLE_CODE.owner],
        subMenu: {
          organizationDetails: {
            name: "Organization Details",
            activeValue: "organizationDetails",
            pathName: `/o/${this.props.activeOrgID}/organization/details`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            ignoreTier: true
          }
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      account: {
        label: "Account",
        newTab: false,
        activeValue: "account",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
        icon: "",
        subMenu: {
          profile: {
            name: "Profile",
            activeValue: "Profile",
            pathName: `/o/${this.props.activeOrgID}/account/profile`,
            role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner]
          },
          changePassword: {
            name: "Change Password",
            activeValue: "change-password",
            pathName: `/o/${this.props.activeOrgID}/account/change-password`,
            role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner]
          },
        },
        dontPrintOnLoop: true,
        isWaitTierDetail: true
      }
    };

    // let activeMenuCheck = (pathString) => {
    //   console.log(pathString)
    //   return window.location.href.includes(pathString);
    // }

    let isSubmenuExist = () => {
      return typeof this.props.sideMenu === "object" && this.props.sideMenu.submenuList;
    }

    // let resetSubMenu = () => {
    //   this.props.setParentMenuBlockActive({
    //     activeMenuBlock: "",
    //     submenuList: null,
    //     activeSubMenu: false
    //   })
    // }

    let mainMenuClick = (activeParent, submenuList) => {
      let _submenuList = submenuList ? submenuList : [];
      let activeSubMenu = "";

      let isRoleMatch = (role) => {
        return role.findIndex(val => val === this.findMemberRole()) !== -1;
      }

      let i = 0;
      while (i < Object.keys(_submenuList).length) {
        if (isRoleMatch(_submenuList[Object.keys(_submenuList)[i]].role)) {
          this.props.history.push(submenuList[Object.keys(_submenuList)[i]].pathName);
          activeSubMenu = _submenuList[Object.keys(_submenuList)[i]].activeValue;
          break;
        } else {
          i++;
        }
      }

      this.props.setParentMenuBlockActive({
        activeMenuBlock: activeParent,
        submenuList: _submenuList,
        activeSubMenu: activeSubMenu
      })
    }

    // let findMediumCounter = (medium) => {
    //   let mediumCounter = 0;

    //   if (this.props.mediumData) {
    //     let findIndex = this.props.mediumData.mediums.findIndex(val => val.code === medium);

    //     if (findIndex !== -1) {
    //       mediumCounter = this.props.mediumData.mediums[findIndex].count;
    //     }
    //   }

    //   return mediumCounter;
    // }

    let onClickSubMenu = (activeValue) => {
      let _sideMenu = { ...this.props.sideMenu };
      _sideMenu.activeSubMenu = activeValue;

      this.props.setParentMenuBlockActive(_sideMenu);
    }

    const openOrgList = () => {
      this.setState({
        isShowOrgList: true
      })
    }

    const closeOrgList = () => {
      this.setState({
        isShowOrgList: false
      })
    }

    return (
      <React.Fragment>
        {/* new side menu */}
        <div className={`new-side-menu-wrapper ${this.props.billingWarning ? "side-menu-active-billing-warning" : ""}`}>
          {this.state.userData !== null &&
            Object.keys(sideMenuList).map((value, key) => {
              if (!sideMenuList[value].dontPrintOnLoop) {
                if (sideMenuList[value].pathName) {
                  return (
                    <NavLink to={sideMenuList[value].pathName} target={sideMenuList[value].newTab ? '_blank' : '_self'}
                      key={`main-menu-${key}`}
                      onClick={() => {
                        this.props.setParentMenuBlockActive({
                          activeMenuBlock: sideMenuList[value].activeValue,
                          submenuList: null
                        })
                      }}
                    >
                      <div className={`new-main-side-menu-wrapper
                        ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}`}
                        onClick={() => {
                          mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);
                        }}
                      >
                        <div className="active-white-line" />
                        <img src={sideMenuList[value].icon} alt="" />
                        <br />
                        <b>{sideMenuList[value].label}</b>
                      </div>
                    </NavLink>
                  )
                } else {
                  return (
                    <div className={`new-main-side-menu-wrapper
                      ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}`}
                      key={`main-menu-${key}`}
                      onClick={() => {
                        mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);
                      }}
                    >
                      <div className="active-white-line" />
                      <img src={sideMenuList[value].icon} alt="" />
                      <br />
                      <b>{sideMenuList[value].label}</b>
                    </div>
                  )
                }
              }

              return null;
            })
          }


          <div className="side-menu-main-bottom-wrapper">
            {this.state.userData !== null &&
              <div className={`new-main-side-menu-wrapper
                ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList.account.activeValue ? 'active-side-menu' : ''}`}
                // onClick={() => {
                //   mainMenuClick(sideMenuList.account.activeValue, sideMenuList.account.subMenu);
                // }}
                onClick={(e) => this.togglePopupOrganization(e)}
              >
                <div className="active-white-line" />
                {this.state.userData.account.imageURL.thumbnail !== "" ?
                  <React.Fragment>
                    <img src={this.state.userData.account.imageURL.thumbnail} alt="" className="my-account-avatar image" />
                    <br />
                    <b>Account</b>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <div className="my-account-avatar avatar-name" >
                      <b>{Helper.renderUserAvatarWord(this.state.userData.account.fullName)}</b>
                    </div>
                    <br />
                    <b>Account</b>
                  </React.Fragment>
                }
              </div>
            }

            {/* <div className="new-main-side-menu-wrapper" onClick={(e) => this.togglePopupOrganization(e)}>
              <img src={iconSwitchOrganization} alt="" />
              <br />
              <b>Switch Organization</b>
            </div> */}
          </div>
        </div>

        {this.state.userData !== null &&
          <div className={`new-sub-side-menu-wrapper 
                          ${!isSubmenuExist() ? "deactive-submenu-wrapper" : ""}
                          ${this.props.billingWarning ? "new-sub-side-menu-active-billing-warning" : ""}
          `}>
            <div className='new-sub-side-menu-balance'>
              <b className='title-balance'>
                Balance
              </b>

              <b className='balance'>
                {this.props.billing === "" ? "-" : rupiahFormat(this.props.billing)}
              </b> 
            </div>

            {isSubmenuExist() &&
              Object.keys(this.props.sideMenu.submenuList).map((value, key) => {
                if (this.props.sideMenu.submenuList[value].isExternalLink) {
                  return (
                    <a href="https://docs.taptalk.io/sendtalk-api-documentation/messages-api" key={`sub-menu-${key}`} target="_blank" rel="noreferrer noopener">
                      <div className={`sub-menu-content ${this.props.sideMenu.activeSubMenu === this.props.sideMenu.submenuList[value].activeValue ? "active-sub-menu" : ""}`}>
                        <div className={`sub-menu-icon  ${value}`}></div>
                        <b>{this.props.sideMenu.submenuList[value].name}</b>
                        <FiExternalLink />
                      </div>
                    </a>
                  )
                } else {
                  return (
                    <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue)}>
                      <div className={`sub-menu-content ${this.props.sideMenu.activeSubMenu === this.props.sideMenu.submenuList[value].activeValue ? "active-sub-menu" : ""}`}>
                        <div className={`sub-menu-icon  ${value}`}></div>
                        <b>{this.props.sideMenu.submenuList[value].name}</b>
                      </div>
                    </NavLink>
                  )
                }
              }
              )}
            {/* </Scrollbars> */}

            {/* {(this.props.sideMenu.activeMenuBlock === "integration" && this.props.mediumData) &&
              <div className="your-channel-limit">
                <p>
                  <b>Your Channels</b>
                </p>

                <b className="channel-limit">{this.props.mediumData.numberOfItems.current}/{this.props.mediumData.numberOfItems.maximum !== -1 ? this.props.mediumData.numberOfItems.maximum : "Unlimited"} channels</b>
              </div>
            } */}
          </div>
        }

        {this.state.userData !== null &&
          <div className={`organization-popup-wrapper ${this.state.showPopupOrganization ? '' : 'deactive-organization-popup-wrapper'}`} ref={this.wrapperRef}>


            <div className={`organization-info-container ${this.state.isShowOrgList ? "open-org-list" : "close-org-list"}`}>
              <div className={`popup-organization-content-info popup-top-content`}>
                <p className="popup-organization-content-info-name">
                  <b>{this.state.userData !== null && this.state.userData.account.fullName}</b>
                </p>

                <p className="popup-organization-content-info-email">
                  {this.state.userData !== null && this.state.userData.account.email}
                </p>

                {/* <Link to={`/o/${this.props.activeOrgID}/account/security`}>
                    <FiSettings />
                  </Link> */}
              </div>

              <div className={`popup-oganization-list`}>
                <p className="title-info-organization">Organization</p>
                <ul>
                  {this.state.userData.memberships.filter(val => this.isActiveOrganizationCheck(val.organization.id)).map((value, index) => {
                    return (
                      // <li onClick={() => this.onClickOrganizationList(value.organization)}
                      <li key={`organization-list-${index}`}
                      // className={this.isActiveOrganizationCheck(value.organization.id) ? 'active-organization' : ''}
                      >
                        <div className="organization-link-wrapper" onClick={openOrgList} >
                          {value.organization.name}
                          <FiChevronRight />
                        </div>
                        {/* <a href={`/o/${value.organization.id}-${value.organization.createdTime}/home`} onClick={() => {
                            this.props.setParentMenuBlockActive({
                              activeMenuBlock: "home",
                              submenuList: null
                            })
                          }}></a> */}
                      </li>
                    )
                  })}
                </ul>
                <p className="footer-info-organization">Create or switch organization</p>
              </div>

              {/* {(this.state.userData.canCreateOrganization) &&
                <NavLink to="/organization/new">
                  <div className="popup-organization-content-wrapper new-organization-wrapper">
                    <b>New Organization</b>
                    <IoIosAdd />
                  </div>
                </NavLink>
              } */}

              <div
                onClick={() => {
                  this.setState({
                    showPopupOrganization: false
                  })
                  mainMenuClick(sideMenuList['account'].activeValue, sideMenuList['account'].subMenu);
                }}
                className={`popup-organization-content-wrapper account-wrapper`}>
                <FiSettings />
                <b>Account Settings</b>
              </div>

              <div onClick={() => {
                this.setState({
                  showPopupOrganization: false
                })
                this.runLogoutAction()
              }} className={`popup-organization-content-wrapper logout-wrapper`}>
                <FiLogOut />
                <b>Logout</b>
              </div>
            </div>

            <div className="organization-list-container">
              <div className={`back-container ${this.state.isShowOrgList ? "show-org-list" : "hide-org-list"}`} onClick={closeOrgList}>
                <FiChevronLeft />
                <b>Back</b>
              </div>
              <div className={`organization-list-items ${this.state.isShowOrgList ? "show-org-list" : "hide-org-list"}`}>
                <ul>
                  {this.state.userData.memberships.map((value, index) => {
                    return (
                      <li key={`organization-list-${index}`}
                        className={this.isActiveOrganizationCheck(value.organization.id) ? 'active-org' : ''}
                      >
                        <a href={`/o/${value.organization.id}-${value.organization.createdTime}/home`} onClick={() => {
                          this.props.setParentMenuBlockActive({
                            activeMenuBlock: "home",
                            submenuList: null
                          })
                        }}>

                          <IoIosCheckmark />
                          <p>{value.organization.name}</p>

                        </a>
                      </li>
                    )
                  })}
                  {/* <li>
                    <a href='/'>
                      <IoIosCheckmark />
                      <p>Indonesia Paradise Property, PT</p>
                    </a>
                  </li>
                  <li className='active-org'>
                    <a href='/'>
                      <IoIosCheckmark />
                      <p>Citra Marga Nusaphala Persada Marga Nusaphala Persada Marga Nusaphala Persada</p>
                    </a>
                  </li>
                  <li>
                    <a href='/'>
                      <IoIosCheckmark />
                      <p>TapTalk.io (PT. Tap Talk Teknologi)</p>
                    </a>
                  </li> */}
                </ul>
              </div>
              {/* <div className={`new-org-wrapper ${this.state.isShowOrgList ? "show-org-list" : "hide-org-list"}`} onClick={() => this.props.history.push(`/organization/new`)}>
                <FiPlus />
                <b>New Organization</b>
              </div> */}
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  sideMenu: state.sideMenu,
  user: state.user,
  activeOrgID: state.activeOrgID,
  billingWarning: state.billingWarning,
  allOrganizationDetail: state.allOrganizationDetail,
  mediumData: state.mediumData,
  billing: state.billing
});

const mapDispatchToProps = {
  setParentMenuBlockActive,
  setLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu)