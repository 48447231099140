import BaseService from "../BaseService";
import { API_KEY } from "../../config";

class ServiceReport {
    postGetCostReport(orgID, startDate, endDate, pageNumber, pageSize, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.GET_COST_REPORT, {
            startDate,
            endDate,
            pageNumber,
            pageSize
        }, headers, callback);
    }
    postExportCostReport(orgID, data, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.EXPORT_COST_REPORT, data, headers, callback, 'blob');
    }
}

const API = {
    GET_COST_REPORT: "/organization/report/daily_cost",
    EXPORT_COST_REPORT: "/organization/report/export_daily_cost",
}

export default new ServiceReport()