import React, { useState, useEffect } from "react";
import "./WhatsappVerificationConfigEdit.scss";
import { connect } from "react-redux";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import HelperInput from "../../../../helper/HelperInput";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ServiceWhatsapp from "../../../../services/whatsapp/ServiceWhatsapp";
import { FiInfo } from "react-icons/fi";

let WhatsappVerificationEdit = (props) => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [loadingWhatsapp, setLoadingWhatsapp] = useState(false);
    let [whatsappData, setWhatsappData] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [loadingSave, setLoadingSave] = useState(false);

    let doMount = async () => {
        let paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              let { id, createdTime } = responseData.memberships[0].organization;
              let urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/label`);
            } else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };

    let getConfig = () => {
        setLoadingWhatsapp(true);
        let _retriveDataError = {...retriveDataError};

        ServiceWhatsapp.postGetConfig(orgID, (response) => {
           let dataResult = response.dataResult;
      
            if(dataResult.error.message === "") {
                dataResult.data.configs.errorWebhook = "";
                dataResult.data.configs.errorSecretKey = "";
                setWhatsappData(dataResult.data.configs)
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setLoadingWhatsapp(false);
        });
    }

    useEffect(() => {
        if (props.history.location.state) {
          if (props.history.location.state.message) {
            doToast(props.history.location.state.message);
            window.history.pushState(null, '');
          }
        }
      }, [props.history])

    useEffect(() => {
        doMount();
    }, []);
    
    useEffect(() => {
        if (orgID) {
            getConfig();
        }
    }, [orgID]);

    let changeInput = (e) => {
        let _whatsapp = {...whatsappData};

        if(e.target.id === "secretKey") {
            _whatsapp.errorSecretKey = "";    
        }else {
            _whatsapp.errorWebhook = "";
        }

        _whatsapp[e.target.id] = e.target.value;
        setWhatsappData(_whatsapp);
    }

    let toggleEnable = () => {
        let _whatsapp = {...whatsappData};
        _whatsapp.isEnabled = !_whatsapp.isEnabled;
        _whatsapp.errorWebhook = "";
        _whatsapp.errorSecretKey = "";
        setWhatsappData(_whatsapp);
    }

    let actionSubmit = () => {
        let errorCount = 0;
        let errorClass = "";
        let _whatsapp = {...whatsappData};

        if(_whatsapp.isEnabled) {
            if(!HelperInput.validURL(_whatsapp.webhookURL)) {
                errorCount = errorCount + 1;
                errorClass = "webhook-input";
                _whatsapp.errorWebhook = "Invalid URL format";
            }

            if(_whatsapp.webhookURL === "") {
                errorCount = errorCount + 1;
                errorClass = "webhook-input";
                _whatsapp.errorWebhook = "This field is required";
            }
        }

        setWhatsappData(_whatsapp);

        if(errorCount !== 0) {
            scrollToClass(`.${errorClass}`);
        }else {
            setLoadingSave(true);
    
            let data = {
                webhookURL: whatsappData.webhookURL,
                isEnabled: whatsappData.isEnabled
            }
    
            ServiceWhatsapp.postSetConfig(orgID, data, (response) => {
               let dataResult = response.dataResult;
               
                if(dataResult.error.message === "") {
                    doToast(dataResult.data.message);
    
                    if(!dataResult.data.success) {
                        setLoadingSave(false);
                    }else {
                        setTimeout(() => {
                            props.history.push(`/o/${orgID}/whatsapp-verification/verification-config`);
                        }, 3000);
                    }
    
                }else {
                    doToast(dataResult.error.message, "fail");
                    setLoadingSave(false);
                }
            });
        }
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >    
            <BackButton 
                text="Back to Verification Config"
                onClick={() => props.history.push(`/o/${orgID}/whatsapp-verification/verification-config`)}
            />

            <div className="sectionWrap whatsapp-verification-container">
                <div className="topContent whatsapp-verification-top-content">
                    <div className="title title-whatsapp-verification">Set Verification Config</div>

                    {!loadingSave ?
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-40"
                            text="Save Changes"
                            onClickAction={actionSubmit}
                        />
                        :
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-40"
                            text="Save Changes"
                            position="left"
                            isLoading
                            loadingWhite
                        />
                    }

                </div>

                {loadingWhatsapp || retriveDataError.code ? 
                    <RetrieveDataLoading 
                        isLoading={loadingWhatsapp}
                        errorMessage={retriveDataError.message} 
                    />
                    :
                    <div className="whatsapp-verification-bottom-content">
                        <label className="custom-switch-wrapper">
                            <input type="checkbox" checked={whatsappData.isEnabled} onChange={toggleEnable} placeholder="0" />
                            <span className="custom-switch round"></span>
                        </label>

                        <p className="whatsapp-verification-title">
                            <b>Enable WhatsApp Verification</b>
                            <span>
                                For more details, please read the documentation <a href="https://docs.taptalk.io/sendtalk-api-documentation/whatsapp-verification" target="_blank">here.</a>
                            </span>
                        </p>

                        <>
                            <p className="whatsapp-verification-title">
                                <b>Webhook URL</b>
                            </p>
                            <input 
                                type="text"
                                placeholder="Insert webhook URL here"
                                onChange={(e) => changeInput(e)}
                                value={whatsappData.webhookURL}
                                className={`webhook-input ${whatsappData.errorWebhook !== "" ? "border-red" : ""}`}
                                id="webhookURL"
                            />
                            <ErrorOrInfoComp 
                                _className={`${whatsappData.errorWebhook !== "" ? "red-text" : ""}`}
                                icon={<FiInfo />}
                                text={whatsappData.errorWebhook === "" ?
                                    "Secret key will appear automatically after inputting webhook URL or after save changes"
                                    :
                                    whatsappData.errorWebhook
                                }
                            />

                            {/* <p className="whatsapp-verification-title">
                                <b>Secret Key</b>
                            </p>
                            <input 
                                type="text"
                                placeholder="Insert secret key here"
                                onChange={(e) => changeInput(e)}
                                value={whatsappData.secretKey}
                                className={``}
                                id="secretKey"
                            />
                            <ErrorOrInfoComp 
                                _className={``}
                                icon={<FiInfo />}
                                text={`Secret key will appear automatically after inputting webhook URL or after save changes`}
                            /> */}
                        </>
                    </div>
                }
            </div>
        </SectionWrap>
    )
}

let mapDispatchToProps = {
    // setParentMenuBlockActive,
};

let mapStateToProps = state => ({
    countryList: state.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatsappVerificationEdit);