import React, { useEffect, useState } from "react";
import "./CheckOptionButton.scss";

const CheckOptionButton = ({
  text,
  value,
  imgSrc,
  checked,
  onClickSelect = () => {},
}) => {
  let [selected, setSelected] = useState(checked)

  useEffect(() => {
    setSelected(checked)
  }, [checked])

  return (
    <div className={"check-option-button-wrapper" + (selected ? " selected" : "")} onClick={onClickSelect}>
      <div className="custom-checkbox">
        <input
          type="checkbox"
          name={value}
          value={value}
          checked={selected}
          readOnly
        />
        <label htmlFor={value}>
          {imgSrc && <img src={imgSrc} alt="" />}
          <p>{text}</p>
        </label>
      </div>
    </div>
  );
};

export default CheckOptionButton;
