import React, { useState } from "react";
import "./CalendarPicker.scss";
import DayPicker, { DateUtils } from "react-day-picker";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import "react-day-picker/lib/style.css";
import { FiCalendar } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

const CalendarPicker = (props) => {
  let { from, to } = props.filterDate;
  let [calendarOne, setCalendarOne] = useState(new Date(new Date().getFullYear(), new Date().getMonth()))
  let [calendarTwo, setCalendarTwo] = useState(new Date(new Date().getFullYear(), new Date().getMonth() +1))

  let modifiers = { start: from, end: to };

  let clearFilterDateValue = () => {
    // if (!props.isFilterDateActive) {
      props.handleDateFilterClick({
        from: undefined,
        to: undefined,
      });
    // }
    if (props.onClearSelection) {
      props.onClearSelection();
    }
  };

  let _handleDateFilterClick = (day) => {
    let range = DateUtils.addDayToRange(day, props.filterDate);

    props.handleDateFilterClick(range);
  };

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        // toggle={props.toggle}
        className="case-modal-calendar"
      >
        <ModalBody>
          <p className="modal-date-filter-title">
            <FiCalendar />
            <b>Date Range</b>
          </p>

          <div id="date-filter-wrapper" className="custom-date-picker">
            <DayPicker
              numberOfMonths={1}
              //    selected={from}
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              onDayClick={_handleDateFilterClick}
              className={
                "Selectable" +
                (String(props.filterDate.from) === String(props.filterDate.to)
                  ? " today-selected"
                  : "")
              }
              // toMonth={new Date(2018, 11)}
              month={calendarOne}
              onMonthChange={(date) => setCalendarOne(date)}
              toMonth={new Date(calendarTwo.getFullYear(), calendarTwo.getMonth() -1)}
            />
            <DayPicker
              numberOfMonths={1}
              //    selected={from}
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              onDayClick={_handleDateFilterClick}
              className={
                "Selectable" +
                (String(props.filterDate.from) === String(props.filterDate.to)
                  ? " today-selected"
                  : "")
              }
              month={calendarTwo}
              onMonthChange={(date) => setCalendarTwo(date)}
              fromMonth={new Date(calendarOne.getFullYear(), calendarOne.getMonth() +1)}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          {!props.filterDate.from && !props.filterDate.to ? (
            ""
          ) : (
            <button
              className="action-button"
              onClick={() => {
                clearFilterDateValue();
              }}
              style={{ marginRight: "auto" }}
            >
              <IoMdClose />
              Clear Selection
            </button>
          )}

          <button
            className="no-fill-button main-button-40"
            onClick={() => {
              props.handleDateFilterClick(props.filterDateBefore)
              props.toggle();
            }}
          >
            Cancel
          </button>

          <ButtonWithLoadingOrIcon
            className="orange-button main-button-40"
            text="Apply Dates"
            isDisabled={props.isDisabled}
            isLoading={props.applyDateLoading}
            loadingColor="gray"
            position="left"
            onClickAction={() => {
              props.submitDateFilter();
              // props.toggle();
            }}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CalendarPicker;
