import React, { useEffect } from 'react';
import Routes from "./routes";

import { BrowserRouter as Router } from "react-router-dom";

import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { setCountryList } from './redux/actions/reduxActionCountryList';
import HelperAuth from "./helper/HelperAuth";
import ServiceOrganization from "./services/organization/ServiceOrganization";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
};

TagManager.initialize(tagManagerArgs);

let customGtmEventListener = () => {
  window.addEventListener("click", function (e) {
    let el = e.target;
    let data = el.getAttribute("gtmevent");

    if (data !== null) {
      window.dataLayer.push(JSON.parse(data))
    }
  });
}

customGtmEventListener();

let App = (props) => {
  let getCountryList = () => {
    ServiceOrganization.postGetCountry((response) => {
      if (response.dataResult.error.message === "") {
        let data = response.dataResult.data;

        props.setCountryList(data.countries);
      } else {
        console.log(response.dataResult.error.message);
      }
    })
  }

  useEffect(() => {
    if (HelperAuth.userWasLoggedIn()) {
      getCountryList();
    }
  }, [])

  return (
    <Router>
      <div className="App">
        <Routes />
      </div>
    </Router>
  );
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  setCountryList,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
