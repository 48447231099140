import React from "react";
import "../../auth/Auth.css";
import "./ApiKeysAdd.scss";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import { FiInfo } from "react-icons/fi";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import HelperDate from "../../../helper/HelperDate";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceApiKey from "../../../services/apiKeys/ApiKeysServices";
import { SUCCESS_TOAST } from "../../../constants/general";

const ApiKeysAdd = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = React.useState(false);
  let [addApiKeyData, setAddApiKeyData] = React.useState({
    description: "",
    validityMonths: "",
    isEnabled: true
  });
  let [isWaitResponseAddApiKey, setIsWaitResponseAddApiKey] = React.useState(false);

  const back = () => history.push(`/o/${orgID}/api-keys`);

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/api-key/general`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  React.useEffect(() => {
    doMount();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const onChangeAddApiKeyInput = (e) => {
    let _addApiKeyData = { ...addApiKeyData };
    let regxNumber = /^[0-9]*$/;

    if (e.target.id === "validityMonths") {
      let setVal = true;

      if (e.target.value[0] === "0") {
        setVal = false;
      }

      if (!regxNumber.test(e.target.value)) {
        setVal = false;
      }

      if (setVal) {
        _addApiKeyData[e.target.id] = e.target.value;
      }
    } else {
      if (e.target.id === "description" && e.target.value.length < 251) {
        _addApiKeyData[e.target.id] = e.target.value;
      } else {
        _addApiKeyData[e.target.id] = e.target.value;
      }
    }

    setAddApiKeyData(_addApiKeyData);
  };

  const onSubmitCreateApiKey = (e) => {
    let _addApiKeyData = { ...addApiKeyData };

    e.preventDefault();
    setIsWaitResponseAddApiKey(true);

    let data = {}
    if (addApiKeyData.validityMonths === "") {
      data = {
        description: _addApiKeyData.description,
        isEnabled: true,
      }
    } else {
      data = {
        description: _addApiKeyData.description,
        isEnabled: true,
        expiryTime: getExpiryTime()
      }
    }

    ServiceApiKey.addApiKey(orgID, data, (response) => {
      let dataResult = response.dataResult;
      setIsWaitResponseAddApiKey(false);

      if (dataResult.status === 200) {
        if (dataResult.data.success) {
          setTimeout(() => {
            doToast(response.dataResult.data.message, SUCCESS_TOAST);
          }, 0);
          history.push(`/o/${orgID}/api-keys`)
        } else {
          doToast(response.dataResult.data.message, "fail");
        }
      } else {
        doToast(dataResult.error.message, "fail");
      }
    });
  }

  const isButtonSubmitDisabled = () => {
    let _addApiKeyData = { ...addApiKeyData };

    return (
      _addApiKeyData.description === ""
    )
  }

  const getExpiryTime = () => {
    let _addApiKeyData = { ...addApiKeyData };
    let _validityMonths = _addApiKeyData.validityMonths;
    let _today = new Date();
    let _date = _today.getDate();
    let _month = _today.getMonth();
    let _year = _today.getFullYear();

    for (let i = 0; i <= _validityMonths; i++) {
      _month = _month + 1;

      if (_month > 12) {
        _month = 1;
        _year = _year + 1;
      }
    }

    if (_date > HelperDate.getLastDayOfCurrentTimestamp(_year, _month)) {
      _date = HelperDate.getLastDayOfCurrentTimestamp(_year, _month);
    }

    return + new Date(_year, _month - 1, _date)
  }

  let generateExpiriy = () => {
    let _addApiKeyData = { ...addApiKeyData };
    let _validityMonths = _addApiKeyData.validityMonths;
    let _today = new Date();
    let _date = _today.getDate();
    let _month = _today.getMonth();
    let _year = _today.getFullYear();

    for (let i = 0; i <= _validityMonths; i++) {
      _month = _month + 1;

      if (_month > 12) {
        _month = 1;
        _year = _year + 1;
      }
    }

    if (_date > HelperDate.getLastDayOfCurrentTimestamp(_year, _month)) {
      _date = HelperDate.getLastDayOfCurrentTimestamp(_year, _month);
    }

    return HelperDate.formatToString(new Date(_year, _month - 1, _date), "dd MMM yyyy");
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <BackButton text="Back to API Keys" onClick={back} />

        <div className="sectionWrap form-add-api-key">
          <form onSubmit={(e) => {
            onSubmitCreateApiKey(e);
          }}>
            <div className="form-title-wrapper">
              <b>Create API Key</b>
            </div>

            <div className="form-content-wrapper">
              <label><b>Description</b></label>

              <div className="description-wrapper">
                <span>{addApiKeyData.description.length}/250</span>
                <textarea rows="6" type="text" maxLength="250" value={addApiKeyData.description} placeholder="Add description here" id="description" onChange={(e) => {
                  onChangeAddApiKeyInput(e);
                }} />
              </div>

              <label><b>Valid Duration</b> <span className="input-label-optional">(optional)</span></label>

              <input type="text" autoComplete={"off"} value={addApiKeyData.validityMonths} placeholder="e.g 12" id="validityMonths" maxLength="7" onChange={(e) => {
                onChangeAddApiKeyInput(e);
              }} />

              <p className="input-info-wrapper">
                <FiInfo />
                <b>Valid duration in months.</b>
              </p>

              <label><b>Expiry Date</b></label>
              <p className="input-label-optional no-margin">
                {addApiKeyData.validityMonths === "" ?
                  "No Expiry"
                  :
                  generateExpiriy()
                }
              </p>

              {!isWaitResponseAddApiKey &&
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40"
                  text="Create API Key"
                  isDisabled={isButtonSubmitDisabled()}
                />
              }
            </div>
          </form>

          {isWaitResponseAddApiKey &&
            <ButtonWithLoadingOrIcon
              isLoading
              loadingWhite
              className="orange-button main-button-40"
              text="Create API Key"
              position="left"
              style={{ width: "300px" }}
            />
          }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default ApiKeysAdd;