import React, { useState, useEffect } from "react";
import "./WhatsappVerificationConfig.scss";
import { connect } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CopyBox from "../../reuseableComponent/copyBox/CopyBox";
import IconDisable from "../../../assets/img/icon-disabled.svg";
import IconEnable from "../../../assets/img/icon-enabled.svg";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceWhatsapp from "../../../services/whatsapp/ServiceWhatsapp";

let WhatsappVerification = (props) => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [loadingOrganizationDetail, setLoadingOrganizationDetail] = useState(false);
    let [whatsappConfig, setWhatsappConfig] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
  
    let goToEdit = () => {
        history.push(`/o/${orgID}/whatsapp-verification/verification-config/edit`);
    }

    let doMount = async () => {
        let paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              let { id, createdTime } = responseData.memberships[0].organization;
              let urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/label`);
            } else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };

    let getConfig = () => {
        setLoadingOrganizationDetail(true);
        let _retriveDataError = {...retriveDataError};

        ServiceWhatsapp.postGetConfig(orgID, (response) => {
           let dataResult = response.dataResult;
      
            if(dataResult.error.message === "") {
                setWhatsappConfig(dataResult.data.configs)
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setLoadingOrganizationDetail(false);
          });
    }

    useEffect(() => {
        if (props.history.location.state) {
          if (props.history.location.state.message) {
            doToast(props.history.location.state.message);
            window.history.pushState(null, '');
          }
        }
      }, [props.history])

    useEffect(() => {
        doMount();
    }, []);
    
    useEffect(() => {
        if (orgID) {
            getConfig();
        }
    }, [orgID]);

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >    
            <div className="sectionWrap whatsapp-verification-container">
                <div className="topContent whatsapp-verification-top-content">
                    <div className="title title-whatsapp-verification">Verification Config</div>
                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-40"
                        text="Edit"
                        onClickAction={goToEdit}
                        icon={{
                            type: "svg",
                            src: FiEdit
                        }}
                        position="left"
                    />
                </div>

                {loadingOrganizationDetail || retriveDataError.code ? 
                    <RetrieveDataLoading 
                        isLoading={loadingOrganizationDetail}
                        errorMessage={retriveDataError.message} 
                    />
                    :
                    <div className="whatsapp-verification-bottom-content">
                        <p className="whatsapp-verification-title">
                            <b>Verification Config</b>
                        </p>

                        <p className="whatsapp-verification-value">
                            {whatsappConfig.isEnabled ?
                                <b className="green-text"> 
                                    <img src={IconEnable} alt="" /> 
                                    Enabled
                                </b>
                                :
                                <b className="red-text"> 
                                    <img src={IconDisable} alt="" /> 
                                    Disabled
                                </b>
                            }
                        </p>
                        
                        {whatsappConfig.isEnabled &&
                            <>
                                <p className="whatsapp-verification-title">
                                    <b>Webhook URL</b>
                                </p>
                                <CopyBox 
                                    className=""
                                    id="webhook-url"
                                    val={whatsappConfig.webhookURL === "" ? "-" : whatsappConfig.webhookURL}
                                    copyText="Webhook URL Copied"
                                />

                                <p className="whatsapp-verification-title">
                                    <b>Secret Key</b>
                                </p>
                                <CopyBox 
                                    className=""
                                    id="secret-key"
                                    val={whatsappConfig.secretKey === "" ? "-" : whatsappConfig.secretKey}
                                    copyText="Secret Key Copied"
                                />
                            </>
                        }
                    </div>
                }
            </div>
        </SectionWrap>
    )
}

let mapDispatchToProps = {
    // setParentMenuBlockActive,
};

let mapStateToProps = state => ({
    countryList: state.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatsappVerification);