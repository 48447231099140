import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import HelperAuth from "../../../helper/HelperAuth";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const MustLoginWithoutNavigation = ({ component: Component, ...rest }) => {
    useEffect(() => {
        if(HelperAuth.userWasLoggedIn()) {
            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
            mixpanel.init(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY);
            mixpanel.identify(myAgentData.account.id);
        }
    }, [])

    return (
        <Route 
            {...rest}
            render={props => 
                !HelperAuth.userWasLoggedIn() ?
                    (
                        <Redirect to="/" />               
                    ) 
                    : 
                    (   
                        <React.Fragment>
                            <Component {...props} />
                        </React.Fragment>
                    )
            }
        />
    );
}

export default MustLoginWithoutNavigation;