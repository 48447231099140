import React, { useEffect, useState } from 'react';
import './Home.scss';
import { Modal, ModalBody } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import SectionWrap from '../reuseableComponent/sectionWrap/SectionWrap';
// import CustomSelectOption from '../reuseableComponent/customSelectOption/CustomSelectOption';
// import OnBoarding from "./onBoarding/OnBoarding";
import ServiceProfile from '../../services/profile/ServiceProfile';
import ServiceOrganization from '../../services/organization/ServiceOrganization';
// import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import { checkID, doToast, rupiahFormat } from '../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
// import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import { setAuthActive } from '../../redux/actions/reduxActionAuth';
import iconGlobe from '../../assets/img/icon-globe-home.svg';
import iconAt from '../../assets/img/icon-at.svg';
import iconWallet from '../../assets/img/icon-wallet.svg'
import { FiPlus, FiInfo } from 'react-icons/fi'
import { connect } from 'react-redux';
import RetrieveDataLoading from '../reuseableComponent/retrieveDataLoading/RetrieveDataLoading';
import Loading from '../reuseableComponent/loading/Loading';
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import BillingServices from '../../services/billing/ServiceBilling';
import { SUCCESS_TOAST } from '../../constants/general';

const Home = (props) => {
  const { match, history, location } = props;
  const [orgID, setOrgID] = useState(false);
  const [userData, setUserData] = useState(props.user.data ? HelperCustomEncryptor.doDecrypt(props.user.data) : null);
  let [waitForResponseGetCurrentBalance, setWaitForResponseGetCurrentBalance] = useState(true);
  let [isShowModalAgreement, setIsShowModalAgreement] = useState(false);
  let [checkedModalAgreement, setCheckedModalAgreement] = useState(false);
  let [accCreatedTime, setAccCreatedTime] = useState();
  let [loadAcceptTOS, setLoadAcceptTOS] = useState(false);

  const doMount = async () => {
    setUserData(HelperCustomEncryptor.doDecrypt(props.user.data));

    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      runGetProfile();
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
            const { id, createdTime } = responseData.memberships[0].organization;
            const urlOrgID = `${id}-${createdTime}`;
            history.push(`/o/${urlOrgID}/home`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  let runGetProfile = () => {
    ServiceProfile.postGetProfile((response) => {
      if (response.dataResult.error.message === '') {
        let resultData = response.dataResult.data;
        setAccCreatedTime(resultData.account.createdTime);
        if (!resultData.tos.isAccepted) {
          setIsShowModalAgreement(true)
        }
        setUserData(resultData);
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  };

  let getActiveOrganization = () => {
    let getUrlOrgID = () => {
      let matchParam = props.match.params.orgID;

      return matchParam.split('-')[0];
    };

    let indexActiveOrganization = userData.memberships.findIndex(
      (value) => value.organization.id === parseInt(getUrlOrgID())
    );

    if (indexActiveOrganization === -1) {
      window.location.href = '/home';
    } else {
      return userData.memberships[indexActiveOrganization].organization;
    }
  };

  useEffect(() => {
    if (orgID) {
      getCurrentBalance();
    }
  }, [orgID]);

  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  const [currentBalance, setCurrentBalance] = useState(0)

  const getCurrentBalance = () => {
    let _retriveDataError = { ...retriveDataError };

    BillingServices.getCurrentBalance(orgID, (response) => {
      let dataResult = response.dataResult;

      if (dataResult.error.message === "") {
        if (dataResult.data.balances.length > 0) {
          setCurrentBalance(dataResult.data.balances[0].amount);
        }
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetCurrentBalance(false);
    })
  }

  let acceptTOS = () => {
    setLoadAcceptTOS(true);
    let data = {
      createdTime: accCreatedTime
    }
    ServiceOrganization.postAcceptTOS(orgID, data, (response) => {
      let dataResult = response.dataResult;
      if (dataResult.error.message !== '' || !dataResult.data.success) {
        doToast(dataResult.data.message, 'fail');
      } else {
        doToast(dataResult.data.message, SUCCESS_TOAST);
      }
      setIsShowModalAgreement(false);
      setLoadAcceptTOS(false);
    })
  }

  let modalAgreement = () => {
    let onCheckedBox = (e) => {
      setCheckedModalAgreement(e.target.checked)
    }

    return (
      <Modal className="modal-agreement" isOpen={isShowModalAgreement}>
        <ModalBody>
          <div className="modal-agreement-title">
            <FiInfo />
            <b>SendTalk Usage Agreement</b>
          </div>
          <p>
            As of right now, the SendTalk platform only supports sending OTP and Transactional Notification. 
            Sending promotional or marketing blast is currently not supported and will lead to account suspension. 
            We are currently working on a new update that will allow all our customers to send bulk messages. 
            We will let you know once it’s available.
          </p>

          <div className="custom-checkbox">
            <input
              type="checkbox"
              id={`checkbox-agree`}
              checked={checkedModalAgreement}
              onChange={(e) => onCheckedBox(e)}
            />

            <label htmlFor="checkbox-agree">
              I agree and understand to not send any promotional or marketing blast and by doing so, will result in account suspension.
            </label>
          </div>
          {
            loadAcceptTOS ?
            <ButtonWithLoadingOrIcon
              className="orange-button main-button-48 width-100"
              text="Continue"
              isDisabled
              isLoading
              position="left"
              loadingColor="gray"
            />
            :
            <ButtonWithLoadingOrIcon
              className="orange-button main-button-48 width-100"
              text="Continue"
              isDisabled={!checkedModalAgreement}
              onClickAction={acceptTOS}
            />
          }
        </ModalBody>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        {modalAgreement()}
        
        <div className="sectionWrap">
          {userData ?
            <React.Fragment>
              <p className="home-welcome-message">
                <b>
                  Hello, {userData !== null && userData.account.fullName}!
              </b>
              </p>

              <div className="home-info-wrapper">
                <div className="home-info-content">
                  <div className="home-info-image">
                    <img src={iconGlobe} alt="" />
                  </div>
                  <div className="home-info-detail">
                    <b className="home-info-detail-title">ORGANIZATION</b>
                    <br />
                    <p className="home-info-detail-value">
                      <b>{userData !== null && getActiveOrganization().name}</b>
                    </p>
                  </div>
                </div>

                <div className="home-info-content">
                  <div className="home-info-image">
                    <img src={iconAt} alt="" />
                  </div>
                  <div className="home-info-detail">
                    <b className="home-info-detail-title">ACCOUNT</b>
                    <br />
                    <p className="home-info-detail-value">
                      <b>{userData !== null && userData.account.email}</b>
                    </p>
                  </div>
                </div>

                <div className="home-info-content">
                  <div className="home-info-image">
                    <img src={iconWallet} alt="" />
                  </div>
                  <div className="home-info-detail">
                    <b className="home-info-detail-title">CURRENT BALANCE</b>
                    <br />
                    {
                      !waitForResponseGetCurrentBalance ?
                        <p className="home-info-detail-value">
                          <b>{rupiahFormat(currentBalance)}</b>
                        </p> :
                        <div className="loading-wrapper">
                          <div className="spinner-wrapper">
                            <Loading />
                          </div>
                          <b>Loading</b>
                        </div>
                    }
                    <ButtonWithLoadingOrIcon
                      position="left"
                      icon={{
                        type: "svg",
                        src: FiPlus
                      }}
                      className="no-fill-button home-topup-button"
                      text="Top Up"
                      style={{ borderRadius: '4px' }}
                      onClickAction={() => history.push(`/o/${orgID}/payment/topup-now`)}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
            :
            <RetrieveDataLoading
              isLoading={waitForResponseGetCurrentBalance}
              errorMessage={retriveDataError.message}
            />
          }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    activeOrgID: state.activeOrgID
  };
}


const mapDispatchToProps = {
  setAuthActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);