import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const ApiKeysServices = {
  getApiKey(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_API_KEY, {}, headers, callback);
  },

  addApiKey(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_API_KEY, data, headers, callback);
  },

  // editApiKey(idOrTime, data, callback) {
  //   let headers = {
  //     "API-Key": API_KEY(),
  //     "Device-Platform": "web",
  //     "Organization-ID": idOrTime
  //   };

  //   BaseService.doPostAccessToken(API.EDIT_API_KEY, data, headers, callback);  
  // },

  deleteApiKey(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_API_KEY, data, headers, callback);
  },
};

const API = {
  GET_API_KEY: "/organization/api_key/get_list",
  ADD_API_KEY: "/organization/api_key/create",
  // EDIT_API_KEY: "/organization/api_key/edit",
  DELETE_API_KEY: "/organization/api_key/delete",
}

export default ApiKeysServices;
