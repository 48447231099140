import IconText from "../assets/img/broadcast-message/icon_text.svg";
import IconImage from "../assets/img/broadcast-message/icon_image.svg";
import IconOTP from "../assets/img/icon-otp.svg";

export const MESSAGE_TYPE = {
    text: {
        val: "Text",
        price: 75,
        desc: "Send a plain text message to the recipient.",
        icon: IconText
    },
    image: {
        val: "Image",
        price: 250,
        desc: "Send an image message along with a caption to the recipient.",
        icon: IconImage
    },
    otp: {
        val: "OTP",
        price: 100,
        desc: "",
        icon: IconOTP
    }
}

export const REASON_SEND_MESSAGE_FAILED = {
    insufficientBalance: "insufficient_balance",
    invalidRecipient: "invalid_recipient"
}
