import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import WasLoggedIn from './components/reuseableComponent/privateRoute/WasLoggedIn';
import MustLoginWithoutNavigation from './components/reuseableComponent/privateRoute/MustLoginWithoutNavigation';
import MustLogin from './components/reuseableComponent/privateRoute/MustLogin';
import Login from './components/auth/login/Login';
import Register from './components/auth/register/Register';
import EmailVerification from './components/auth/emailVerification/EmailVerification';
import VerifyEmail from './components/auth/verifyEmail/VerifyEmail';
import ForgotPassword from './components/auth/forgotPassword/ForgotPassword';
import ResetPassword from './components/auth/resetPassword/ResetPassword';
import Error404 from './components/error/error404/Error404';
import AccountSettingMyProfileEdit from "./components/accountSetting/accountSettingMyProfile/AccountSettingMyProfileEdit/AccountSettingMyProfileEdit";
import AccountSettingMyProfile from "./components/accountSetting/accountSettingMyProfile/AccountSettingMyProfile";
import ChangePassword from './components/accountSetting/changePassword/ChangePassword'
import OrganizationDetails from './components/organization/organizationDetails/OrganizationDetails'
import OrganizationDetailsEdit from './components/organization/organizationDetails/OrganizationDetailsEdit/OrganizationDetailsEdit'
import NewOrganization from './components/organization/newOrganization/NewOrganization'
import Home from './components/home/Home'
import { connect } from 'react-redux';
import ApiKeys from './components/apiKeys/ApiKeys';
import ApiKeysAdd from './components/apiKeys/apiKeysAdd/ApiKeysAdd';
import TopUpNow from './components/payment/topUpNow/TopUpNow';
import TopUpHistory from './components/payment/topUpHistory/TopUpHistory';
import TopUpNowPay from './components/payment/topUpNow/TopUpNowPay/TopUpNowPay';
import LowBalance from './components/payment/lowBalance/LowBalance';
// import SendMessage from './components/message/sendMessage/SendMessage';
import PreviewMessage from './components/message/previewMessage/PreviewMessage';
import MessageHistory from './components/message/messageHistory/MessageHistory';
import CostReport from './components/report/costReport/CostReport';
import WhatsappVerificationConfig from './components/whatsappVerification/verificationConfig/WhatsappVerificationConfig';
import WhatsappVerificationConfigEdit from './components/whatsappVerification/verificationConfig/edit/WhatsappVerificationConfigEdit';
import WhatsappCreateVerification from './components/whatsappVerification/createVerification/WhatsappCreateVerification';
import WhatsappVerificationHistory from './components/whatsappVerification/verificationHistory/WhatsappVerificationHistory';
import WhatsappVerificationHistoryDetail from './components/whatsappVerification/verificationHistory/detail/WhatsappVerificationHistoryDetail';

const Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />

        <MustLogin path="/o/:orgID/message/message-history" component={MessageHistory} />
        {/* <MustLogin path="/o/:orgID/message/send-message/preview" component={PreviewMessage} /> */}
        {/* <MustLogin path="/o/:orgID/message/send-message" component={SendMessage} /> */}

        <MustLogin path="/o/:orgID/payment/topup-history" component={TopUpHistory} />
        <MustLogin path="/o/:orgID/payment/low-balance-alert" component={LowBalance} />
        
        <MustLogin path="/o/:orgID/payment/topup-now/pay" component={TopUpNowPay} />
        <MustLogin path="/o/:orgID/payment/topup-now" component={TopUpNow} />
        

        <MustLogin path="/o/:orgID/api-keys/create" component={ApiKeysAdd} />
        <MustLogin path="/o/:orgID/api-keys" component={ApiKeys} />

        <MustLogin path="/o/:orgID/report/cost-report" component={CostReport} />

        <MustLogin path="/o/:orgID/account/profile/edit" component={AccountSettingMyProfileEdit} />
        <MustLogin path="/o/:orgID/account/profile" component={AccountSettingMyProfile} />
        <MustLogin path="/profile" component={AccountSettingMyProfile} />
        <MustLogin path="/o/:orgID/account/change-password" component={ChangePassword} />

        <MustLogin path="/o/:orgID/organization/details/edit" component={OrganizationDetailsEdit} />
        <MustLogin path="/o/:orgID/organization/details" component={OrganizationDetails} />
        <MustLoginWithoutNavigation path="/organization/new" component={NewOrganization} />

        <WasLoggedIn path="/login" component={Login} />
        <WasLoggedIn path="/register" component={Register} />
        <WasLoggedIn path="/verify/register-account" component={EmailVerification} />
        <WasLoggedIn path="/verify/email" component={VerifyEmail} />
        <WasLoggedIn path="/forgot-password" component={ForgotPassword} />
        <WasLoggedIn path="/reset-password" component={ResetPassword} />

        <MustLogin path="/o/:orgID/home" component={Home} />
        <MustLogin path="/home" component={Home} />

        <MustLogin path="/o/:orgID/whatsapp-verification/verification-config/edit" component={WhatsappVerificationConfigEdit} />
        <MustLogin path="/o/:orgID/whatsapp-verification/verification-config" component={WhatsappVerificationConfig} />
        <MustLogin path="/o/:orgID/whatsapp-verification/create-verification" component={WhatsappCreateVerification} />
        <MustLogin path="/o/:orgID/whatsapp-verification/verification-history/details/:id" component={WhatsappVerificationHistoryDetail} />
        <MustLogin path="/o/:orgID/whatsapp-verification/verification-history" component={WhatsappVerificationHistory} />

        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
