import React, { Component } from "react";
import "./Error404.scss";
import HelperAuth from "../../../helper/HelperAuth";
import Page404 from "../../../assets/img/page-404.png";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi"; 

class Error404 extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
        <React.Fragment>          
          <div id="page-error404" className={HelperAuth.userWasLoggedIn() ? "page-404-after-login" : ""}>
              <div className={`page-404-content after-login`}>
                <img src={Page404} alt="" />
                
                <br />

                Oops. Page not found
                <p>
                  Looks like the page you were looking for does not exist
                </p>
                
                <br />
                
                {!HelperAuth.userWasLoggedIn() ?
                  <Link to="/">
                    <button className="orange-button">
                        Back to Login
                    </button>
                  </Link>
                  :
                  <Link to="/home">
                    <button className="orange-button">
                        Back to Home
                    </button>
                  </Link>
                }

                <a href="http://support.taptalk.io" target="_blank" rel="noopener noreferrer">
                  <button className="no-fill-button" 
                          style={{padding: "10px 30px 11px 50px", marginLeft: "20px"}}
                  >
                    <FiArrowUpRight /> Visit Support
                  </button>
                </a>
              </div>
          </div>
        </React.Fragment>
    );
  }
}

export default Error404;
