import React from "react";
import "./Loading.css";

const Loading = ({ color }) => (
  <div className="LoadingRoll_1234fed">
    <div
      className={`lds-ring ${
        color ? `${color}-lds-ring` : ""
      } centered-lds-ring`}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loading;
