import React, { useEffect } from 'react';
import './TopUpNowPay.scss'
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
// import PopupConfirmation from '../../reuseableComponent/popupConfirmation/PopupConfirmation';
// import { checkID } from "../../../../helper/HelperGeneral";
// import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
// import BillingServices from '../../../services/billing/ServiceBilling';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';

const TopUpNowPay = (props) => {
  let { match, location } = props;
  // let [orgID, setOrgID] = useState(false);
  
  const doMount = async () => {
    // const paramID = checkID(match);
    // if (paramID) {
      // setOrgID(paramID);
    // } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          // const { id, createdTime } = responseData.memberships[0].organization;
          // const urlOrgID = `${id}-${createdTime}`;
          // history.push(`/o/${urlOrgID}/api-key/general`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    // }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if(!props.location.state) {
      back();
    }
  }, [props.location])

  let back = () => {
    props.history.push(`/o/${match.params.orgID}/payment/topup-now`)
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        withHeader
        withSideMenu
      >
        <BackButton text="Back to Top Up Now" onClick={back} />
       
        <div className="sectionWrap topup-now-main-wrapper topup-now-pay-main-wrapper">
          <div className="topContent">
            <div className="topup-now-header-title">Top Up Payment</div>
          </div>
          
          <div className="topup-now-pay-bottom">
            {/* <div className="topup-now-pay-bottom-title">
              Top Up Payment
            </div> */}
            {props.location.state &&
              <iframe src={props.location.state.paymentLink} title="payment-frame" />
            }
          </div>
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default TopUpNowPay;