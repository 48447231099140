import React, { Component } from 'react';
import '../Auth.css';
import './EmailVerification.css';
import Logo from '../../../assets/img/taptalk-logo.png';
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosRefresh } from "react-icons/io";
import ServiceAuthRegister from '../../../services/auth/ServiceAuthRegister';
import CustomToastr from '../../reuseableComponent/customToastr/CustomToastr';
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

var runningCountdownTimer;

const style = {
    verificationSent : {
        color: "#7ec82a",
        fontSize: "24px",
        marginTop: "32px",
        fontWeight: "bold",
        height: "30px",
        lineHeight: "30px",
        position: "relative"
    },
    checkIcon: {
        position: "relative",
        top: "-1px",
        marginRight: "3px"
    },
    verificationDetail: {
        fontSize: "14px",
        margin: "12px 0 40px"
    }
};

class EmailVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isResendEmailShow: false,
        timerResendEmail: 10,
        toastrText: "",
        toastrClassName: "",
        toastrShow: false,
        emailText: ""
    };
  }

  componentDidMount() {
    try{
        this.setState({
            emailText: this.props.location.state.email
        })
    }
    catch(err) {
        this.props.history.push('');
    }

    var countdownFunction = () => {
        this.setState({
            timerResendEmail: this.state.timerResendEmail - 1
        })

        if(this.state.timerResendEmail === 0) {
            this.setState({
                isResendEmailShow: true
            })

            clearInterval(runningCountdownTimer);
        }
    };

    runningCountdownTimer = window.setInterval(countdownFunction, 1000);

    let targetClickTimer = document.querySelector(".resend-button");

    targetClickTimer.addEventListener("click", () => {
        this.setState({
            isResendEmailShow: false,
            timerResendEmail: 10
        });

        runningCountdownTimer = window.setInterval(countdownFunction, 1000);
    });
  }

  componentWillUnmount() {
    clearInterval(runningCountdownTimer);
  }

  render() {
    return (
        <div id="outer-container">
            <div id="verification-wrapper">
                <img src={Logo} alt="Tap Talk Logo" />

                <div style={style.verificationSent} className="verification-status">
                    <FaCheckCircle size={25} style={style.checkIcon} /> Verification Email Sent
                </div>

                <div style={style.verificationDetail}>
                    One last thing, please check your email inbox with the registered email 
                    address <b>{`(${this.state.emailText})`}</b> and <b>verify your account.</b>
                </div>
                
                <Link to="/">
                    <ButtonWithLoadingOrIcon
                        className="orange-button main-button-48 width-100"
                        text="Back to Login"
                    />
                </Link>

                <div className="resend-email-wrapper">
                    Didn’t receive an email?
                    
                    <span className="resend-button"
                        style={{
                            display: this.state.isResendEmailShow ? "block" : "none"
                        }}
                        onClick={() => this.onClickResendEmailVerification(this.state.emailText)}
                    >
                        Resend Email 
                        <IoIosRefresh />
                    </span>

                    <span className="timer-resend-button"
                        style={{
                            display: this.state.isResendEmailShow ? "none" : "block"
                        }}
                    >
                        Wait {this.state.timerResendEmail}s
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </span>
                </div>

            </div>
            
            <CustomToastr toastrText={this.state.toastrText} toastrClassName={this.state.toastrClassName} />
        </div>
    );
  }

  onClickResendEmailVerification = (paramEmail) => {
    this.setState({
        toastrShow: true,
        toastrText: "Please wait, while your request is being process",
        toastrClassName: "toast-process active-toast"
    });

    ServiceAuthRegister.postResendEmailVerification({email: paramEmail}).then(response => {
      if(response.dataResult.data.success) {
        this.setState({
            toastrText: "Resend email verification is success, please check your email",
            toastrClassName: "toast-success active-toast"
        })

        setTimeout(() => {
            this.setState({
                toastrClassName: "toast-success"
            })
        }, 4000);
      }else {
        this.setState({
            toastrText: response.dataResult.error.message,
            toastrClassName: "toast-fail active-toast"
        })

        setTimeout(() => {
            this.setState({
                toastrClassName: "toast-fail"
            })
        }, 4000);
      }
    })
  }
}

export default EmailVerification;
