import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from '../reducers';

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [
        'sideMenu',
        'countryList',
        'allOrganizationDetail',
        'previewImageOrVideo',
        'isTrial',
        'billing'
    ],
    stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};


const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(pReducer);
export const persistor = persistStore(store);

export default store;