import React from "react";
import './FilterBy.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiInfo, FiSliders, FiX } from 'react-icons/fi'
import CheckOptionButton from "../../../../reuseableComponent/checkOptionButton/CheckOptionButton";
import { MESSAGE_TYPE } from "../../../../../constants/message";
// import { useState } from "react";
// import { useEffect } from "react/cjs/react.production.min";


let FilterBy = (props) => {
    let onSelectSource = (value) => {
        props.onSelectSourceProps(value);
    }

    let onSelectStatus = (value) => {
        props.onSelectStatusProps(value);
    }

    const actionFilterMesage = () => {
        props.updateFilterProps()
    }

    const onChangePhoneNumber = (number) => {
        props.onChangePhoneNumberProps(number)
    }

    const onChangeMessageTypes = (type) => {
        let types = {...props.messageTypesProps};
        types[type] = !types[type]
        props.onChangeMessageTypesProps(types)
    }

    const checkMessageTypesFilterIsOn = () => {
        let result = false;
        let messageTypes = props.messageTypesProps;

        for (var i in messageTypes) {
            if (messageTypes[i] === true) {
                result = true;
                break;
            }
        }
        
        return result;
    }

    // useEffect(() => {
    //     props.OnChangeMessageTypesProps(messageTypes)
    // }, [messageTypes])

    return (
        <Modal 
            isOpen={props.isOpenProps} 
            toggle={() => {
                props.resetCurrentChanges(); 
                props.toggleProps();
            }} 
            className="case-modal-filter-option"
        >
            <ModalBody>
                <p className="modal-date-filter-title">
                    <FiSliders />
                    <b>Filters</b>
                </p>

                <div className="case-filter-option-wrapper">
                    <label><b>Recipient’s Phone Number</b></label>
                    <div className="input-container input-phone">
                        <p>+</p>
                        <input 
                            type="text" 
                            placeholder="628123456789" 
                            pattern="[0-9]*"
                            value={props.phoneNumberProps}
                            maxLength="15"
                            onChange={e => {
                                const number = (e.target.validity.valid) ? e.target.value : props.phoneNumberProps;
                                onChangePhoneNumber(number)
                            }}
                        />
                    </div>
                    <p className="input-info-wrapper">
                        <FiInfo />
                        <b>Add country code first e.g +62.</b>
                    </p>

                    <label><b>Message Type</b></label>
                    <div className="filter-message-type-wrapper">
                        <CheckOptionButton 
                            text={MESSAGE_TYPE.text.val} 
                            value={MESSAGE_TYPE.text.val} 
                            imgSrc={MESSAGE_TYPE.text.icon} 
                            checked={props.messageTypesProps[MESSAGE_TYPE.text.val]}
                            onClickSelect={() => onChangeMessageTypes(MESSAGE_TYPE.text.val)}
                        />
                        <CheckOptionButton 
                            text={MESSAGE_TYPE.image.val} 
                            value={MESSAGE_TYPE.image.val} 
                            imgSrc={MESSAGE_TYPE.image.icon} 
                            checked={props.messageTypesProps[MESSAGE_TYPE.image.val]}
                            onClickSelect={() => onChangeMessageTypes(MESSAGE_TYPE.image.val)}
                        />
                        <CheckOptionButton 
                            text={MESSAGE_TYPE.otp.val} 
                            value={MESSAGE_TYPE.otp.val} 
                            imgSrc={MESSAGE_TYPE.otp.icon} 
                            checked={props.messageTypesProps[MESSAGE_TYPE.otp.val]}
                            onClickSelect={() => onChangeMessageTypes(MESSAGE_TYPE.otp.val)}
                        />
                    </div>

                    <label><b>Source</b></label>
                    <CustomSelectOption
                        optionListProps={props.sourceListProps}
                        valueDropdownProps={props.filterSourceValueProps}
                        placeholderProps={"Please Select"}
                        onClickDropDownListOptionProps={onSelectSource}
                        specifiClassNameProps="custom-select-filter-option"
                    />


                    <label><b>Status</b></label>
                    <CustomSelectOption
                        optionListProps={props.statusListProps}
                        valueDropdownProps={props.filterStatusValueProps}
                        placeholderProps={"Please Select"}
                        onClickDropDownListOptionProps={onSelectStatus}
                        specifiClassNameProps="custom-select-filter-option"
                    />

                </div>
            </ModalBody>
            <ModalFooter>
                <div className="clear-wrapper">
                    {
                        props.isActiveFilterProps &&
                        <div onClick={() => props.onClearSelectionProps()}>
                            <FiX />
                            <b>Clear Selection</b>
                        </div>
                    }
                </div>
                <div className="button-container">
                    <ButtonWithLoadingOrIcon
                        onClickAction={() => {
                            props.resetCurrentChanges(); 
                            props.toggleProps()
                        }}
                        className="no-fill-button main-button-40 cancel-filter"
                        text="Cancel"
                    />


                    {props.filterSourceValueProps.value === "" && 
                    props.filterStatusValueProps.value === "" && 
                    props.phoneNumberProps === "" && 
                    !checkMessageTypesFilterIsOn() ?
                        <ButtonWithLoadingOrIcon
                            isDisabled={true}
                            className="orange-button main-button-40 apply-filter"
                            text="Apply Filter"
                        />
                        :
                        <ButtonWithLoadingOrIcon
                            onClickAction={actionFilterMesage}
                            className="orange-button main-button-40 apply-filter"
                            text="Apply Filter"
                        />
                    }
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default FilterBy;